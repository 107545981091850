import { ADD_ROUTE, DELETE_ROUTE } from "./types";

export const addRoute = (route) => ({
  type: ADD_ROUTE,
  payload: route,
});

export const deleteRoute = () => ({
  type: DELETE_ROUTE,
});
