import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { isLogin } from "./token-check";
import { addRoute } from "../actions/route";

export class PrivateRoute extends Component {

  isLoggedIn = (rest) => {
    const { route, addRoute } = this.props;
    if (
      rest.path !== null &&
      rest.path !== "/home" &&
      rest.path !== route &&
      !isLogin()
    ) {
      addRoute(rest.path);
    } 
    return isLogin();
  };

  render() {
    const { component: Component, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={(props) =>
          this.isLoggedIn(rest) ? (
            <Component {...props} />
          ) : (
            <Redirect to="/login" />
          )
        }
      />
    );
  }
}

const mapStateToProps = (state) => ({
  route: state.route,
});

const mapDispatchToProps = (dispatch, props) => ({
  addRoute: (route) => dispatch(addRoute(route)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);