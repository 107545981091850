import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./store";
import App from "./App";
import AppWidget from "./AppWidget";
import './i18n';
import "leaflet-control-geocoder/dist/Control.Geocoder.js";

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={null}>
        {window.location.pathname.includes("/widget/") ? (
          <AppWidget />
        ) : (
          <App />
        )}      
    </Suspense>
  </Provider>,
  document.getElementById("root")
);
