import React, { Component } from "react";
import { withTranslation } from 'react-i18next';

class PageNotFound extends Component {

  render() {
  
    const { t } = this.props;

    return (
      <>
        <br />
        <div className="flex align-items-center justify-content-center">
          <div className="surface-card p-4 shadow-2 border-round w-full lg:w-4">
            <div className="text-center text-900 text-xl font-medium">
              404
            </div>
            <div className="text-center mb-6">
              <span className="text-600 font-medium line-height-3">
              {t("404")}
              </span>
            </div>

            <div className="text-center mb-2">
              <a
                className="font-medium no-underline ml-2 text-blue-500 cursor-pointer"
                href="/home"
              >
                {t("backToHome")}
              </a>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withTranslation()(PageNotFound)
