import { MapLayer } from "react-leaflet";
import L from "leaflet";
import "leaflet-routing-machine";
import { withLeaflet } from "react-leaflet";

class Routing extends MapLayer {

  createLeafletElement() {
    const { map, waypoints } = this.props;

    console.log(waypoints);

    const icon = L.icon({
      iconUrl: require("../resources/pinIcon.png"),
      shadowUrl: require("../resources/shadow.png"),
      iconSize: [30, 30],
      shadowAnchor: [13, 42],
      iconAnchor: [13, 30],
      popupAnchor: [0, -29]
  });
    let leafletElement = L.Routing.control({

      lineOptions: {
        styles: [
          {
            color: "purple",
            opacity: 0.8,
            weight: 8
          }
        ]
      },

      show: true,

      waypoints,
      plan: L.Routing.plan(waypoints, {
        createMarker: function (i, wp) {
          return L.marker(wp.latLng, {
            draggable: false,
            addWaypoints: true,
            draggableWaypoints: false,
            fitSelectedRoutes: false,
            showAlternatives: true,
            routeWhileDragging: false,
            icon
          });
        },
      })
    }).addTo(map.leafletElement);
    return leafletElement.getPlan();
  }
}
export default withLeaflet(Routing);