import "bootstrap/dist/css/bootstrap.min.css";
import "./resources/main.css";

import React, { Component } from "react";
import { BrowserRouter, Router, Switch } from "react-router-dom";
import PublicRoute from "./common/PublicRoute";

import { history } from "./common/history";
import PublicProjectDashboard from "./components/publicProjectDashboard";

class AppWidget extends Component {

   constructor(props) {
    super(props);
  }

  componentDidMount() {

    console.log("Geldi");
    
    const path_ = window.location.pathname;
    if (path_.includes("/widget/")) {
      this.param = path_.split("/").pop();
    }    

    console.log(this.param);
    console.log(path_);

  }

  render() {
     return (
      <Router history={history}>
              <BrowserRouter>
                <Switch>
                
                  <PublicRoute
                    restricted={false}
                    component={PublicProjectDashboard}
                    path={"/widget/sdbs"}
                    exact
                  />
                 
                </Switch>
              </BrowserRouter>
      </Router >
    );
  }
}

export default (AppWidget);