import React, { Component } from "react";

import "primereact/resources/themes/lara-light-teal/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

import { Card } from "primereact/card";
import { Chip } from "primereact/chip";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import { Steps } from "primereact/steps";
import { InputText } from "primereact/inputtext";
import { ConfirmDialog } from "primereact/confirmdialog";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { TabView, TabPanel } from "primereact/tabview";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { BlockUI } from "primereact/blockui";
import { InputMask } from 'primereact/inputmask';
import { FilterMatchMode } from "primereact/api";
import { connect } from "react-redux";

import { Divider } from "primereact/divider";

import { isUser } from "../common/token-check";
import { isPlatformManager, isProjectManager } from "../common/token-check";

import WaitDialog from "../common/WaitDialog";
import WaitDialogDocument from "../common/WaitDialogDocument";
import SidebarComponent from "../common/sidebar";
import SidebarRight from "../common/sidebarRight";
import SysService from "../services/sys.service";

import { withTranslation, Trans } from "react-i18next";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../resources/customToast.css";
import { rowClass, handleDownloadDocument } from '../common/functions';
import { auto } from "@popperjs/core";
import iller from "../common/ccs_iller";


class PI extends Component {

  emptyNewDocument = {
    fileDescription: "",
    documentName: "",
    documentURL: "",
    fileName: "",
    documentDate: ""
  };

  constructor(props) {

    super(props);

    this.state = {
      project: "",

      pid: "",
      visibleCustomSidebar: false,
      visibleRight: false,

      nonEditAccess: true,
      activeIndex: 0,
      p12Documents: [],
      p12DocumentIndex: -1,
      p12SelectedDocument: null,

      newDocument: this.emptyNewDocument,
      addDocumentDialogVisible: false,
      addDocumentConfirmDialogVisible: false,

      loading: false,
      documentLoading: false,
      blockedPanel: false,

      s3Response: "",
      tbuFile: null,
      doc: "",

      filters1: null,
      globalFilterValue1: "",

      saveButtonStatus: false,

      mortgageStatusVisible: true

    }

  };

  /*JS_methods*/

  onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let project = { ...this.state.project };
    project[`${name}`] = val;

    this.setState({
      project,
      saveButtonStatus: true
    });
  }

  onInputChangeDoc = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let newDocument = { ...this.state.newDocument };
    newDocument[`${name}`] = val;

    this.setState({ newDocument });
  }

  actionTemplate = (data, props) => {
    return (
      <div>
        <Button
          icon="pi pi-download"
          href
          tooltip="Download"
          className="p-button-rounded p-button-text"
          onClick={(e) => {
            handleDownloadDocument(data, props);
          }}
        />
        {isProjectManager() && (
          <Button
            icon="pi pi-trash"
            tooltip="Delete"
            className="p-button-rounded p-button-text"
            onClick={(e) => {
              this.handleDeleteDocument(data, props);
            }}
          />
        )}
      </div>
    );
  }

  handleDeleteDocument = (rowData) => {

    if (this.state.activeIndex === 2) {
      const index = this.state.p12Documents.indexOf(rowData);
      if (index > -1) {
        this.state.p12Documents.splice(index, 1);
      }
    } else if (this.state.activeIndex === 3) {
      const index = this.state.p13Documents.indexOf(rowData);
      if (index > -1) {
        this.state.p13Documents.splice(index, 1);
      }
    } else if (this.state.activeIndex === 4) {
      const index = this.state.p14Documents.indexOf(rowData);
      if (index > -1) {
        this.state.p14Documents.splice(index, 1);
      }
    } else if (this.state.activeIndex === 5) {
      const index = this.state.p15Documents.indexOf(rowData);
      if (index > -1) {
        this.state.p15Documents.splice(index, 1);
      }
    }

    this.setState({
      newDocument: this.emptyNewDocument,
      addDocumentDialogVisible: false,
      saveButtonStatus: true
    });
  }

  handleAddDocument = () => {
    this.setState({
      newDocument: this.emptyNewDocument,
      submitted: false,
      addDocumentDialogVisible: true,
    });
  }

  hideAddDocumentDialog = () => {
    this.setState({
      submitted: false,
      addDocumentDialogVisible: false,
    });
  }

  acceptAddDocument = () => {

    const filesX = document.getElementById("fupload").files;
    const fileX = filesX[0];

    if (filesX == null || fileX == null) {
      this.setState({ loading: false });

      toast.warning(("pleaseSelectFile"));

      return;
    }

    if (
      this.state.newDocument.fileDescription == null ||
      this.state.newDocument.fileDescription === ""
    ) {
      this.setState({ loading: false });

      toast.warning(("pleaseEnterTheDescription"));

      return;
    }
    const maxAllowedSize = 5 * 1024 * 1024;

    if (fileX.size > maxAllowedSize) {
      this.setState({ loading: false });

      toast.warning(("maxFileSizeIs5Mb"));

      return;
    }

    this.setState({
      documentLoading: true,
    });

    SysService.getS3URL(
      fileX.name,
      "P1" + this.state.activeIndex,
      this.state.pid
    ).then(
      (response) => {
        this.setState({ s3Response: response.data, tbuFile: fileX, saveButtonStatus: true }, () => {
          this.state.newDocument.uploadURL = this.state.s3Response.uploadURL;
          this.state.newDocument.fileName = this.state.s3Response.fileName;
          this.state.newDocument.documentName = this.state.s3Response.documentName;
          this.state.newDocument.documentDate = this.state.s3Response.documentDate;
          this.state.newDocument.documentURL = this.state.s3Response.documentURL;
        });

        this.put2S3();

        let p12doc = [...this.state.p12Documents];
        let p13doc = [...this.state.p13Documents];
        let p14doc = [...this.state.p14Documents];
        let p15doc = [...this.state.p15Documents];

        if (this.state.activeIndex === 2) {
          p12doc.push(this.state.newDocument);
          this.setState({ p12Documents: p12doc, saveButtonStatus: true });
        } else if (this.state.activeIndex === 3) {
          p13doc.push(this.state.newDocument);
          this.setState({ p13Documents: p13doc, saveButtonStatus: true });
        } else if (this.state.activeIndex === 4) {
          p14doc.push(this.state.newDocument);
          this.setState({ p14Documents: p14doc, saveButtonStatus: true });
        } else if (this.state.activeIndex === 5) {
          p15doc.push(this.state.newDocument);
          this.setState({ p15Documents: p15doc, saveButtonStatus: true });
        }

      },
      (error) => {
        this.setState({
          loading: false,
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString(),
        });

        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;
            case 403:
              window.location.href = "/403";
              break;
            case 404:
              window.location.href = "/404";
              break;
            default:
              break;
          }
        }
      }
    );
  }

  rejectAddDocument = () => { }

  /*componentDidMount*/
  componentDidMount() {

    this.setState({
      loading: true,
      pid: this.props.location.state.id,
    });

    SysService.getProject(this.props.location.state.id).then(
      (response) => {

        let temp12Documents = [];
        let temp13Documents = [];
        let temp14Documents = [];
        let temp15Documents = [];


        if (response.data.documentList != null) {
          for (let i = 0; i < response.data.documentList.length; i++) {
            if (response.data.documentList[i].phase === "P12") {
              temp12Documents.push(response.data.documentList[i]);
            }
            if (response.data.documentList[i].phase === "P13") {
              temp13Documents.push(response.data.documentList[i]);
            }
            if (response.data.documentList[i].phase === "P14") {
              temp14Documents.push(response.data.documentList[i]);
            }
            if (response.data.documentList[i].phase === "P15") {
              temp15Documents.push(response.data.documentList[i]);
            }
          }
        }

        this.setState({
          project: response.data,
          p12Documents: temp12Documents,
          p13Documents: temp13Documents,
          p14Documents: temp14Documents,
          p15Documents: temp15Documents,
          loading: false,
          blockedPanel: false,
        });

      },
      (error) => {
        this.setState({
          loading: false,
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString(),
          blockedPanel: true,
        });

        toast.error(this.state.content);

        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;
            case 403:
              window.location.href = "/403";
              break;
            case 404:
              window.location.href = "/404";
              break;
            default:
              break;
          }
        }
      }
    );

    if (isProjectManager()) {
      this.setState({
        nonEditAccess: false,
      });
    }

    this.initFilters1();
    window.scrollTo(0, 0);
  }

  /**/
  getS3URL(e) {
    const filesX = document.getElementById("fupload").files;
    const fileX = filesX[0];
    const fileNameX = fileX.name;

    const maxAllowedSize = 5 * 1024 * 1024;

    if (fileX.size > maxAllowedSize) {
      this.setState({ loading: false });

      toast.warning("Max file size 5 Mb");

      return;
    }

    this.setState({
      documentLoading: true,
    });

    if (isProjectManager()) {
      SysService.getS3URL(
        fileNameX,
        this.state.doc.documentType,
        this.state.pid
      ).then(
        (response) => {
          this.setState({
            fileUrl: response.data,
            tbuFile: fileX,
          });
          this.put2S3();
        },
        (error) => {
          this.setState({
            loading: false,
            content:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString(),
          });

          if (
            error.response &&
            (error.response.status === 401 ||
              error.response.status === 403 ||
              error.response.status === 404)
          ) {
            switch (error.response.status) {
              case 401:
                window.location.href = "/401";
                break;
              case 403:
                window.location.href = "/403";
                break;
              case 404:
                window.location.href = "/404";
                break;
              default:
                break;
            }
          }
        }
      );
    }
  }

  put2S3() {
    this.setState({
      documentLoading: true,
    });

    if (isProjectManager()) {
      /////////////////////
      SysService.putFile2S3URL(
        this.state.s3Response.uploadURL,
        this.state.tbuFile
      ).then(
        (response) => {
          this.setState({
            documentLoading: false,
          });
        },
        (error) => {
          this.setState({
            loading: false,
            content:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString(),
          });
          if (
            error.response &&
            (error.response.status === 401 ||
              error.response.status === 403 ||
              error.response.status === 404)
          ) {
            switch (error.response.status) {
              case 401:
                window.location.href = "/401";
                break;
              case 403:
                window.location.href = "/403";
                break;
              case 404:
                window.location.href = "/404";
                break;
              default:
                break;
            }
          }
        }
      );

      this.setState({
        loading: false,
        addDocumentDialogVisible: false,
      });
    }
  }

  stepPrev() {
    let inx = this.state.activeIndex;
    if (inx <= 0) {
    } else {
      if (!this.state.saveButtonStatus) {
        inx = inx - 1;
        this.setState({
          activeIndex: inx,
        });

        window.scrollTo(0, 0);
      } else {
        toast.warning(<Trans i18nKey="pleaseSaveFirst" />);
      }
    }
  }

  stepNext() {
    let inx = this.state.activeIndex;
    if (inx >= this.stepItems.length - 1) {
    } else {

      if (!this.state.saveButtonStatus) {
        inx = inx + 1;
        this.setState({
          activeIndex: inx,
        });

        window.scrollTo(0, 0);
      } else {
        toast.warning(<Trans i18nKey="pleaseSaveFirst" />);
      }

    }
  }

  stepSaveAndNext() {
    let inx = this.state.activeIndex;
    if (inx > this.stepItems.length - 1) {
    } else {

      if (inx === 0) {
        if (
          this.state.project.projectName == null ||
          this.state.project.projectName.trim() === "" ||
          this.state.project.address == null ||
          this.state.project.address.trim() === "" ||
          this.state.project.city == null ||
          this.state.project.city.trim() === ""
        ) {
          this.setState({ loading: false });
          toast.warning(<Trans i18nKey="warnMandatory" />);
          return;
        }
      } else {
      }

      if (isProjectManager()) {
        this.setState({
          loading: true,
        });

        /////////////////////

        this.state.project.documents = [];

        if (inx === 2) {
          this.state.project.documents = this.state.p12Documents;
        } else if (inx === 3) {
          this.state.project.documents = this.state.p13Documents;
        } else if (inx === 4) {
          this.state.project.documents = this.state.p14Documents;
        }
        else if (inx === 5) {
          this.state.project.documents = this.state.p15Documents;
        }

        this.state.project.projectId = this.state.pid;
        this.state.project.phase = "P1" + inx;

        SysService.updateProject(this.state.project).then(
          (response) => {
            this.setState({
              project: response.data,
              loading: false,
              saveButtonStatus: false
            });

            toast.success(<Trans i18nKey="saved" />);
          },
          (error) => {
            this.setState({
              loading: false,
              content:
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString(),
            });

            toast.error(<Trans i18nKey={error.response} />);

            if (
              error.response &&
              (error.response.status === 401 ||
                error.response.status === 403 ||
                error.response.status === 404)
            ) {
              switch (error.response.status) {
                case 401:
                  window.location.href = "/401";
                  break;
                case 403:
                  window.location.href = "/403";
                  break;
                case 404:
                  window.location.href = "/404";
                  break;
                default:
                  break;
              }
            }
          }
        );
      }

      if (inx < this.stepItems.length - 1) {
        inx = inx + 1;
        this.setState({
          activeIndex: inx,
        });
      }
    }

    window.scrollTo(0, 0);
  }

  renderHeader(param) {
    return (
      <div className="flex justify-content-between">
        <span>{param}</span>
      </div>
    );
  }

  renderHeaderTableTop(t) {
    return (
      <div className="flex justify-content-between">
        <span>{t("documents")}</span>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            className="p-inputtext-sm block mb-2"
            value={this.state.globalFilterValue1}
            onChange={this.onGlobalFilterChange1}
            placeholder={t("search")}
          />
        </span>
      </div>
    );
  }

  onGlobalFilterChange1 = (e) => {
    const value = e.target.value;
    let filters1 = { ...this.state.filters1 };
    filters1["global"].value = value;

    this.setState({ filters1, globalFilterValue1: value });
  }

  initFilters1 = () => {
    this.setState({
      filters1: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      globalFilterValue1: "",
    });
  }

  /**/
  render() {

    const { t } = this.props;

    const headerTable = this.renderHeaderTableTop(t);

    this.stepItems = [
      {
        label: t("projectDetails"),
        command: (event) => { },
      }
    ];

    const addDocumentDialogFooter = (
      <React.Fragment>
        <Button
          label={t("cancel")}
          icon="pi pi-times"
          className="p-button-text"
          onClick={this.hideAddDocumentDialog}
        />
        <Button
          label={t("save")}
          icon="pi pi-save"
          className="p-button-mb"
          onClick={() =>
            this.setState({ addDocumentConfirmDialogVisible: true })
          }
        />

        <ConfirmDialog
          visible={this.state.addDocumentConfirmDialogVisible}
          onHide={() => {
            this.setState({ addDocumentConfirmDialogVisible: false });
          }}
          message={t("confirmMessage")}
          header={t("confirm")}
          icon="pi pi-exclamation-triangle"
          accept={this.acceptAddDocument}
          reject={this.rejectAddDocument}
          acceptLabel={t("yes")}
          rejectLabel={t("no")}
        />
      </React.Fragment>
    );

    return (
      <div>
        {!isUser() && (
          <>
            <BlockUI blocked={this.state.blockedPanel} fullscreen>
                <div className="flex align-items-center justify-content-center">
                  <Link
                    to={{ pathname: "/project", state: this.state.project }}
                    style={{ textDecoration: "none" }}
                  >
                    <Chip style={{ fontSize: "15px"}}
                      label={this.state.project.projectName
                        + " (ID: " + this.state.project.pruid + ")"}
                      className="mb-2 custom-chip-home"
                    />
                  </Link>
                </div>

              <br />
              {this.state.project.areWeProceeding === "2" && (
                <><Chip
                  label={<b>{t("stopped")}</b>}
                  icon="pi pi-stop-circle"
                  style={{ fontSize: "18px", position: "absolute", zIndex: "1", right: "10px" }}
                  className="awpNo mr-2 mb-2 custom-chip-constant"
                />
                  <br /><br /></>
              )}
              {this.state.project.areWeProceeding === "3" && (
                <><Chip
                  label={<b>{t("pending")}</b>}
                  icon="pi pi-bell"
                  style={{ fontSize: "18px", position: "absolute", zIndex: "1", right: "10px" }}
                  className="awpPending mr-2 mb-2 custom-chip-constant"
                />
                  <br /><br /></>
              )}
              <br />

              {/*
              <Button
                style={{ right: "0px", position: "fixed", fontSize: 5, top: "310px" }}
                icon="pi pi-angle-left text-3xl"
                iconPos="center"
                disabled={this.state.activeIndex === 4}
                className="p-button-sm p-button-mb"
                onClick={() =>
                  this.setState({ visibleRight: true })
                }
              />
               */}

              <div className={"col-12 " + rowClass(this.state.project)}>
                <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                  <div className="flex justify-content-between mb-3">
                    <Button
                      // icon="pi pi-arrows-h"                     
                      label={t("showPhases")}
                      className="p-button-sm p-button-mb"
                      onClick={() =>
                        this.setState({ visibleCustomSidebar: true })
                      }
                    />
                    <div>
                      <Chip
                        label={t("phase1Name")}
                        icon="pi pi-id-card"
                        className="mr-2 mb-2 custom-chip-constant"
                      />
                    </div>
                  </div>
                  <hr />
                  <div className="col-12 mb-0">
                    <Steps
                      model={this.stepItems}
                      activeIndex={this.state.activeIndex}
                      onSelect={(e) => {
                        if (!this.state.saveButtonStatus) {
                          this.setState({ activeIndex: e.index })
                        } else {
                          if (this.state.activeIndex != e.index) {
                            toast.warning(<Trans i18nKey="pleaseSaveFirst" />);
                          }
                        }
                      }}
                      readOnly={false}
                    />
                  </div>




                  <TabView
                    className="projectNav"
                    activeIndex={this.state.activeIndex}
                    onTabChange={(e) => this.setState({ activeIndex: e.index })}
                  >

                    {/*Project Details*/}
                    <TabPanel header={t("projectDetails")}>
                      <div className="mb-4" style={{ backgroundColor: "#FFFADD", padding: "10px" }}>
                        {t("p11Text")}
                      </div>

                      <div className="grid">

                        {/*projectName*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div className="mb-1">
                            <label htmlFor="projectName" className="platformColor">{t("projectName")} (*)</label>
                          </div>
                          <div>

                            <InputText
                              id="projectName"
                              readOnly={this.state.nonEditAccess}
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.projectName}
                              placeholder={t("pleaseEnter")}
                              onChange={(e) =>
                                this.onInputChange(e, "projectName")
                              }
                              maxLength={100}
                              className="w-full"
                            />
                          </div>
                        </div>

                        {/*description*/}
                        <div className="col-12 md:col-12 lg:col-12">
                          <div className="mb-1">
                            <label htmlFor="description" className="platformColor">{t("description")}</label>
                          </div>
                          <div>

                            <InputTextarea
                              readOnly={this.state.nonEditAccess}
                              disabled={this.state.nonEditAccess}
                              id="description"
                              value={this.state.project.description}
                              placeholder={t("pleaseEnter")}
                              onChange={(e) => this.onInputChange(e, "description")}
                              rows={3}
                              cols={20}
                              maxLength={500}
                              className="w-full"
                            />
                          </div>
                        </div>

                        {/*city*/}
                        <div className="col-12 md:col-6 lg:col-4">
                          <div className="mb-1">

                            <label htmlFor="city" className="platformColor">{t("city")} (*)</label>
                          </div>
                          <div>
                            <Dropdown
                              id="city"
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.city}
                              options={iller}
                              placeholder={t("pleaseSelect")}
                              onChange={(e) =>
                                this.onInputChange(e, "city")
                              }
                              optionLabel="il"
                              optionValue="il"
                              className="w-full"
                            />

                          </div>
                        </div>

                        {/*borough*/}
                        <div className="col-12 md:col-6 lg:col-4">
                          <div className="mb-1">
                            <label htmlFor="borough" className="platformColor">{t("borough")}</label>
                          </div>
                          <div>

                            <InputText
                              id="borough"
                              readOnly={this.state.nonEditAccess}
                              value={this.state.project.borough}
                              disabled={this.state.nonEditAccess}
                              placeholder={t("pleaseEnter")}
                              onChange={(e) =>
                                this.onInputChange(e, "borough")
                              }
                              maxLength={50}
                              className="w-full"
                            />

                          </div>

                        </div>

                        {/*postcode*/}
                        <div className="col-12 md:col-6 lg:col-4">
                          <div className="mb-1">
                            <label htmlFor="postcode" className="platformColor">{t("postcode")}</label>
                          </div>
                          <div>

                            <InputText
                              id="postcode"
                              readOnly={this.state.nonEditAccess}
                              value={this.state.project.postcode}
                              disabled={this.state.nonEditAccess}
                              placeholder={t("pleaseEnter")}
                              onChange={(e) =>
                                this.onInputChange(e, "postcode")
                              }
                              maxLength={10}
                              className="w-full"
                            />
                          </div>
                        </div>

                        {/*address*/}
                        <div className="col-12 md:col-12 lg:col-12">
                          <div className="mb-1">
                            <label htmlFor="address" className="platformColor">{t("address")} (*)</label>
                          </div>
                          <div>

                            <InputTextarea
                              readOnly={this.state.nonEditAccess}
                              disabled={this.state.nonEditAccess}
                              id="address"
                              value={this.state.project.address}
                              placeholder={t("pleaseEnter")}
                              onChange={(e) => this.onInputChange(e, "address")}
                              rows={3}
                              cols={20}
                              maxLength={500}
                              className="w-full"
                            />
                          </div>
                        </div>

                        {/*email*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div className="mb-1">
                            <label htmlFor="email" className="platformColor">{t("email")}</label>
                          </div>
                          <div>

                            <InputText
                              id="email"
                              readOnly={this.state.nonEditAccess}
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.email}
                              placeholder={t("pleaseEnter")}
                              onChange={(e) =>
                                this.onInputChange(e, "email")
                              }
                              maxLength={100}
                              className="w-full"
                            />
                          </div>
                        </div>

                        {/*phone*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div className="mb-1">
                            <label htmlFor="phoneNumber" className="platformColor">{t("phone")}</label>
                          </div>
                          <div>


                            <InputMask
                              id="phoneNumber"
                              mask="+(99) 9999-999999"
                              readOnly={this.state.nonEditAccess}
                              disabled={this.state.nonEditAccess}
                              value={this.state.project.phoneNumber}
                              placeholder="+(xx) xxxx-xxxxxx"
                              onChange={(e) => this.onInputChange(e, "phoneNumber")
                              }
                              maxLength={20}
                              className="w-full"
                            >
                            </InputMask>

                          </div>
                        </div>
                      </div>

                    </TabPanel>

                  </TabView>

                </div>
                <br />

                {isProjectManager() && (
                  <Button
                    disabled={!this.state.saveButtonStatus}
                    icon="pi pi-check-circle"
                    label={this.state.activeIndex < this.stepItems.length - 1 ? t("saveNext") : t("save")}
                    className="p-button-sm p-button-danger"
                    onClick={() => this.stepSaveAndNext()}
                  />
                )}
              </div>
              <br />


              <Dialog
                visible={this.state.addDocumentDialogVisible}
                style={{ width: "450px" }}
                header={t("document")}
                modal
                className="p-fluid"
                footer={addDocumentDialogFooter}
                onHide={this.hideAddDocumentDialog}
              >
                <div className="p-float-label">
                  <input
                    id="fupload"
                    type="file"
                    accept=".xlsx, .xls, .csv, .doc, .docx, .pdf, .jpg, .png, .jpeg, .gif|image/*"
                  ></input>
                </div>
                <div className="p-float-label">
                  <InputTextarea
                    id="ad2"
                    value={this.state.newDocument.fileDescription}
                    onChange={(e) => this.onInputChangeDoc(e, "fileDescription")}
                    rows={3}
                    cols={20}
                    maxLength={100}
                    className="w-full"
                  />
                  <label htmlFor="ad2">{t("fileDescription")} (*)</label>
                </div>
              </Dialog>


              <SidebarComponent
                visible={this.state.visibleCustomSidebar}
                project={this.state.project}
                setState={this.setState.bind(this)}
                lang={t}
              />

              {/* 
              <SidebarRight
                visible={this.state.visibleRight}
                projectId={this.props.location.state.id}
                setState={this.setState.bind(this)}
                index={"P1" + this.state.activeIndex}
                lang={t}
              />
              */}

              <WaitDialog
                loading={this.state.loading}
                lang={t}
              />
              <WaitDialogDocument loading={this.state.documentLoading} lang={t} />

              <ToastContainer
                autoClose={2200}
                position={toast.POSITION.TOP_CENTER}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick={true}
                rtl={false}
                theme="colored"
                pauseOnFocusLoss={true}
                draggable={false}
                pauseOnHover={true}
              />
            </BlockUI>
          </>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { message } = state.message;
  return {
    message,
  };
}

export default withTranslation()(connect(mapStateToProps)(PI));
