import React, { Component } from "react";

import "primereact/resources/themes/lara-light-teal/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

import { Card } from "primereact/card";
import { Chip } from "primereact/chip";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import { Steps } from "primereact/steps";
import { InputText } from "primereact/inputtext";
import { ConfirmDialog } from "primereact/confirmdialog";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { TabView, TabPanel } from "primereact/tabview";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { BlockUI } from "primereact/blockui";
import { FilterMatchMode } from "primereact/api";
import { connect } from "react-redux";

import { isUser } from "../common/token-check";
import { isPlatformManager, isProjectManager } from "../common/token-check";

import WaitDialog from "../common/WaitDialog";
import WaitDialogDocument from "../common/WaitDialogDocument";
import SidebarComponent from "../common/sidebar";
import SysService from "../services/sys.service";

import { withTranslation, Trans } from "react-i18next";
import { t } from "i18next";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../resources/customToast.css";
import iller from "../common/ccs_iller";
import numberOfFloor from "../common/ccs_numberOfFloor";
import { rowClass } from '../common/functions';

import L from "leaflet";
import { $CombinedState } from "redux";

import { ScaleControl } from "react-leaflet";
import { Map as LeafletMap, TileLayer, Marker, Popup } from "react-leaflet";
import MapMarkBuilding from "../common/mapMarkBuilding";


const mapPin = L.icon({
    iconUrl: require("../resources/pinIcon.png"),
    shadowUrl: require("../resources/shadow.png"),
    iconSize: [40, 40],
    shadowAnchor: [13, 42],
    iconAnchor: [13, 30],
    popupAnchor: [0, -29]
});
class AssetManagement extends Component {

    emptyZoneData = {
        name: "",
        floor: "",
        zoneSize: "",
        description: "",
    };

    emptySiteData = {
        name: "",
        numberOfFloor: "",
        description: "",
        latitude: 0,
        longitude: 0,
        city: ""
    };

    constructor(props) {
        super(props);

        this.state = {
            latitude2: this.props.lat,
            project: "",
            pid: "",
            visibleCustomSidebar: false,
            nonEditAccess: true,
            activeIndex: 0,

            sites: [],
            zones: [],
            newZoneData: this.emptyZoneData,
            newSiteData: this.emptySiteData,

            addZoneDialogVisible: false,
            addSiteDialogVisible: false,

            addZoneConfirmDialogVisible: false,
            addSiteConfirmDialogVisible: false,

            updateZoneDialogVisible: false,
            updateSiteDialogVisible: false,

            updateZoneConfirmDialogVisible: false,
            updateSiteConfirmDialogVisible: false,

            loading: false,
            documentLoading: false,
            blockedPanel: false,

            saveButtonStatus: false,

            filters1: null,
            globalFilterValue1: "",
            showSiteVisible: false,
            mapUpdateVisible: false
        };
    }

    /*JS_methods*/

    onInputChangeSite = (e, name) => {
        const val = (e.target && e.target.value) || "";
        let newSiteData = { ...this.state.newSiteData };
        newSiteData[`${name}`] = val;

        this.setState({
            newSiteData
        });
    };

    onInputChangeZone = (e, name) => {
        const val = (e.target && e.target.value) || "";
        let newZoneData = { ...this.state.newZoneData };
        newZoneData[`${name}`] = val;

        this.setState({
            newZoneData
        });
    };

    actionTemplateZone = (data, props) => {
        return (
            <div>
                {isProjectManager() && (
                    <>
                        <Button
                            icon="pi pi-pencil"
                            href
                            tooltip={t("update")}
                            className="p-button-rounded p-button-text"
                            onClick={(e) => {
                                this.handleUpdateZone(data, props);
                            }}
                        />
                        <Button
                            icon="pi pi-trash"
                            tooltip={t("delete")}
                            className="p-button-rounded p-button-text"
                            onClick={(e) => {
                                this.handleDeleteZone(data, props);
                            }}
                        />
                    </>
                )}
            </div>
        );
    };

    actionTemplateSite = (data, props) => {
        return (
            <div>
                {isProjectManager() && (
                    <>

                        <Button
                            icon="pi pi-pencil"
                            href
                            tooltip={t("update")}
                            className="p-button-rounded p-button-text"
                            onClick={(e) => {
                                this.handleUpdateSite(data, props);
                            }}
                        />

                        <Button
                            icon="pi pi-trash"
                            tooltip={t("delete")}
                            className="p-button-rounded p-button-text"
                            onClick={(e) => {
                                this.handleDeleteSite(data, props);
                            }}
                        />
                        <Button
                            icon="pi pi-arrow-up-right"
                            tooltip={t("showMap")}
                            className="p-button-rounded p-button-text"
                            onClick={(e) => {
                                this.handleShowSite(data, props);
                            }}

                        />
                    </>
                )}
            </div>
        );
    };

    handleDeleteZone = (rowData) => {

        if (rowData.id === undefined) {

            const index = this.state.zones.indexOf(rowData);
            if (index > -1) {
                this.state.zones.splice(index, 1);
            }

            this.setState({
                newZoneData: this.emptyZoneData,
                addZoneDialogVisible: false,
                saveButtonStatus: true,
            });

        } else {
            SysService.checkZoneDelete(rowData.id).then(
                (response) => {

                    const index = this.state.zones.indexOf(rowData);
                    if (index > -1) {
                        this.state.zones.splice(index, 1);
                    }

                    this.setState({
                        newZoneData: this.emptyZoneData,
                        addZoneDialogVisible: false,
                        saveButtonStatus: true,
                    });

                },
                (error) => {
                    this.setState({
                        loading: false,
                        content:
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.timeoutErrorMessage ||
                            error.toString(),
                        blockedPanel: false,
                    });

                    toast.error(<Trans i18nKey={this.state.content} />);

                }
            );
        }

    };

    handleDeleteSite = (rowData) => {

        if (rowData.id === undefined) {
            const index = this.state.sites.indexOf(rowData);
            if (index > -1) {
                this.state.sites.splice(index, 1);
            }

            this.setState({
                newSiteData: this.emptySiteData,
                addSiteDialogVisible: false,
                saveButtonStatus: true,
            });
        } else {
            SysService.checkSiteDelete(rowData.id).then(
                (response) => {

                    const index = this.state.sites.indexOf(rowData);
                    if (index > -1) {
                        this.state.sites.splice(index, 1);
                    }

                    this.setState({
                        newSiteData: this.emptySiteData,
                        addSiteDialogVisible: false,
                        saveButtonStatus: true,
                    });

                },
                (error) => {
                    this.setState({
                        loading: false,
                        content:
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.timeoutErrorMessage ||
                            error.toString(),
                        blockedPanel: false,
                    });

                    toast.error(<Trans i18nKey={this.state.content} />);

                }
            );
        }
    };


    /////////////////

    handleShowSite = (rowData) => {
        const index = this.state.sites.indexOf(rowData);

        this.setState({
            newSiteData: rowData,
            submitted: false,
            showSiteVisible: true,
            siteIndex: index,
        });


    };

    hideShowSite = () => {
        this.setState({
            submitted: false,
            showSiteVisible: false
        });
    };

    ///////////////////////
    handleAddZone = () => {
        this.setState({
            newZoneData: this.emptyZoneData,
            submitted: false,
            addZoneDialogVisible: true,
        });
    };

    handleAddSite = () => {
        this.setState({
            newSiteData: this.emptySiteData,
            submitted: false,
            addSiteDialogVisible: true,
        });
    };

    hideAddSiteDialog = () => {
        this.setState({
            submitted: false,
            addSiteDialogVisible: false
        });
    };

    hideAddZoneDialog = () => {
        this.setState({
            submitted: false,
            addZoneDialogVisible: false
        });
    };

    hideMapDialog = () => {

        this.setState({
            mapVisible: false
        });
    }
    hideMapUpdateDialog = () => {

        this.setState({
            mapUpdateVisible: false
        });
    }

    handleUpdateZone = (rowData) => {
        const index = this.state.zones.indexOf(rowData);

        this.setState({
            newZoneData: rowData,
            submitted: false,
            updateZoneDialogVisible: true,
            zoneIndex: index,
        });
    };

    handleUpdateSite = (rowData) => {
        const index = this.state.sites.indexOf(rowData);

        this.setState({
            newSiteData: rowData,
            submitted: false,
            updateSiteDialogVisible: true,
            siteIndex: index,
        });
    };

    hideUpdateZoneDialog = () => {
        this.setState({
            submitted: false,
            updateZoneDialogVisible: false
        });
    };

    hideUpdateSiteDialog = () => {
        this.setState({
            submitted: false,
            updateSiteDialogVisible: false
        });
    };


    /*Add Zone Accept Dialog*/
    acceptAddZone = () => {

        if (
            this.state.newZoneData.siteId == null ||
            this.state.newZoneData.siteId === ""
        ) {
            this.setState({ loading: false });

            toast.warning(<Trans i18nKey="pleaseEnterTheZoneName" />);

            return;
        }

        if (
            this.state.newZoneData.name == null ||
            this.state.newZoneData.name === ""
        ) {
            this.setState({ loading: false });

            toast.warning(<Trans i18nKey="pleaseEnterTheZoneName" />);

            return;
        }

        if (
            this.state.newZoneData.floor == null ||
            this.state.newZoneData.floor === ""
        ) {
            this.setState({ loading: false });

            toast.warning(<Trans i18nKey="pleaseEnterWhichFloor" />);
            return;

        }

        if (
            this.state.newZoneData.zoneSize == null ||
            this.state.newZoneData.zoneSize === ""
        ) {
            this.setState({ loading: false });
            toast.warning(<Trans i18nKey="pleaseEnterZoneSize" />);
            return;

        }

        this.setState({ saveButtonStatus: true, addZoneDialogVisible: false }, () => {
            this.state.zones.push(this.state.newZoneData);
        });

    };

    rejectAddZone = () => { };

    /*Add Site Accept Dialog*/
    acceptAddSite = () => {

        if (
            this.state.newSiteData.name == null ||
            this.state.newSiteData.name === ""
        ) {
            this.setState({ loading: false });

            toast.warning(<Trans i18nKey="pleaseEnterTheSiteName" />);

            return;
        }

        if (
            this.state.newSiteData.numberOfFloor == null ||
            this.state.newSiteData.numberOfFloor === ""
        ) {
            this.setState({ loading: false });
            toast.warning(<Trans i18nKey="pleaseEnterTheNumberOfFloor" />);

            return;
        }

        if (
            this.state.newSiteData.latitude === 0 ||
            this.state.newSiteData.longitude === 0
        ) {
            this.setState({ loading: false });
            toast.warning(<Trans i18nKey="pleaseEnterTheLocation" />);

            return;
        }

        if (
            this.state.newSiteData.city == null ||
            this.state.newSiteData.city === ""
        ) {
            this.setState({ loading: false });
            toast.warning(<Trans i18nKey="pleaseEnterTheCity" />);

            return;
        }

        this.setState({ saveButtonStatus: true, addSiteDialogVisible: false }, () => {
            this.state.sites.push(this.state.newSiteData);
        });
    };

    rejectAddSite = () => { };

    /*Update Zone Dialog*/
    acceptUpdateZone = () => {

        if (
            this.state.newZoneData.siteId == null ||
            this.state.newZoneData.siteId === ""
        ) {
            this.setState({ loading: false });

            toast.warning(<Trans i18nKey="pleaseEnterTheZoneName" />);

            return;
        }

        if (
            this.state.newZoneData.name == null ||
            this.state.newZoneData.name === ""
        ) {
            this.setState({ loading: false });

            toast.warning(<Trans i18nKey="pleaseEnterTheZoneName" />);

            return;
        }

        if (
            this.state.newZoneData.floor == null ||
            this.state.newZoneData.floor === ""
        ) {
            this.setState({ loading: false });

            toast.warning(<Trans i18nKey="pleaseEnterWhichFloor" />);
            return;

        }

        if (
            this.state.newZoneData.zoneSize == null ||
            this.state.newZoneData.zoneSize === ""
        ) {
            this.setState({ loading: false });
            toast.warning(<Trans i18nKey="pleaseEnterZoneSize" />);
            return;

        }

        this.setState({ saveButtonStatus: true, updateZoneDialogVisible: false }, () => {
            this.state.zones[this.state.zoneIndex] = this.state.newZoneData;
        });

    };

    rejectUpdateZone = () => { };

    /*Update Site Dialog*/
    acceptUpdateSite = () => {

        if (
            this.state.newSiteData.name == null ||
            this.state.newSiteData.name === ""
        ) {
            this.setState({ loading: false });

            toast.warning(<Trans i18nKey="pleaseEnterTheSiteName" />);

            return;
        }

        if (
            this.state.newSiteData.numberOfFloor == null ||
            this.state.newSiteData.numberOfFloor === ""
        ) {
            this.setState({ loading: false });
            toast.warning(<Trans i18nKey="pleaseEnterTheNumberOfFloor" />);

            return;
        }

        if (
            this.state.newSiteData.latitude === 0 ||
            this.state.newSiteData.longitude === 0
        ) {
            this.setState({ loading: false });
            toast.warning(<Trans i18nKey="pleaseEnterTheLocation" />);

            return;
        }

        if (
            this.state.newSiteData.city == null ||
            this.state.newSiteData.city === ""
        ) {
            this.setState({ loading: false });
            toast.warning(<Trans i18nKey="pleaseEnterTheCity" />);

            return;
        }

        this.setState({ saveButtonStatus: true, updateSiteDialogVisible: false }, () => {
            this.state.sites[this.state.siteIndex] = this.state.newSiteData;
        });

    };

    rejectUpdateSite = () => { };

    /*componentDidMount*/
    componentDidMount() {
        this.setState({
            loading: true,
            pid: this.props.location.state.id,
        });

        SysService.getProject(this.props.location.state.id).then(
            (response) => {

                this.setState({
                    project: response.data,
                    sites: response.data.siteList,
                    zones: response.data.zoneList,
                    loading: false,
                    blockedPanel: false,
                });
            },
            (error) => {
                this.setState({
                    loading: false,
                    content:
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.timeoutErrorMessage ||
                        error.toString(),
                    blockedPanel: true,
                });

                toast.error(this.state.content);

                if (
                    error.response &&
                    (error.response.status === 401 ||
                        error.response.status === 403 ||
                        error.response.status === 404)
                ) {
                    switch (error.response.status) {
                        case 401:
                            window.location.href = "/401";
                            break;
                        case 403:
                            window.location.href = "/403";
                            break;
                        case 404:
                            window.location.href = "/404";
                            break;
                        default:
                            break;
                    }
                }
            }
        );


        if (isProjectManager()) {
            this.setState({
                nonEditAccess: false,
            });
        }

        this.initFilters1();
        window.scrollTo(0, 0);


    }

    stepPrev() {
        let inx = this.state.activeIndex;
        if (inx <= 0) {
        } else {
            if (!this.state.saveButtonStatus) {
                inx = inx - 1;
                this.setState({
                    activeIndex: inx,
                });

                window.scrollTo(0, 0);
            } else {
                toast.warning(<Trans i18nKey="pleaseSaveFirst" />);
            }
        }
    }

    stepNext() {
        let inx = this.state.activeIndex;
        if (inx >= this.stepItems.length - 1) {
        } else {
            if (!this.state.saveButtonStatus) {
                inx = inx + 1;
                this.setState({
                    activeIndex: inx,
                });

                window.scrollTo(0, 0);
            } else {
                toast.warning(<Trans i18nKey="pleaseSaveFirst" />);
            }
        }
    }

    stepSaveAndNext() {
        let inx = this.state.activeIndex;
        if (inx > this.stepItems.length - 1) {
        } else {
            if (isProjectManager()) {
                this.setState({
                    loading: true,
                });

                /////////////////////
                this.state.project.sites = this.state.sites;
                this.state.project.zones = this.state.zones;
                this.state.project.projectId = this.state.pid;
                this.state.project.phase = "P2" + inx;

                SysService.updateProject(this.state.project).then(
                    (response) => {

                        SysService.getProject(this.props.location.state.id).then(
                            (response2) => {

                                this.setState({
                                    project: response2.data,
                                    sites: response2.data.siteList,
                                    zones: response2.data.zoneList,
                                    loading: false,
                                    blockedPanel: false,
                                    saveButtonStatus: false,
                                });
                            }
                        );

                        toast.success(<Trans i18nKey="saved" />);
                    },
                    (error) => {
                        this.setState({
                            loading: false,
                            content:
                                (error.response &&
                                    error.response.data &&
                                    error.response.data.message) ||
                                error.message ||
                                error.toString(),
                        });

                        toast.error(<Trans i18nKey={error.response} />);

                        if (
                            error.response &&
                            (error.response.status === 401 ||
                                error.response.status === 403 ||
                                error.response.status === 404)
                        ) {
                            switch (error.response.status) {
                                case 401:
                                    window.location.href = "/401";
                                    break;
                                case 403:
                                    window.location.href = "/403";
                                    break;
                                case 404:
                                    window.location.href = "/404";
                                    break;
                                default:
                                    break;
                            }
                        }
                    }
                );
            }

            if (inx < this.stepItems.length - 1) {
                inx = inx + 1;
                this.setState({
                    activeIndex: inx,
                });
            }
        }

        window.scrollTo(0, 0);
    }

    renderHeader(param) {
        return (
            <div className="flex justify-content-between">
                <span>{param}</span>
            </div>
        );
    }

    renderHeaderTableTop(t, title) {
        return (
            <div className="flex justify-content-between">
                <span>{t(title)}</span>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                        className="p-inputtext-sm block mb-2"
                        value={this.state.globalFilterValue1}
                        onChange={this.onGlobalFilterChange1}
                        placeholder={t("search")}
                    />
                </span>
            </div>
        );
    }

    onGlobalFilterChange1 = (e) => {
        const value = e.target.value;
        let filters1 = { ...this.state.filters1 };
        filters1["global"].value = value;

        this.setState({ filters1, globalFilterValue1: value });
    };

    initFilters1 = () => {
        this.setState({
            filters1: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
            globalFilterValue1: "",
        });
    };

    dropdownZoneTemplate = (data) => {
        return (
            <>
                {data.name}
            </>
        );
    };

    addMarker = (e) => {
        let newSiteData = { ...this.state.newSiteData };

        newSiteData.latitude = e.latlng.lat;
        newSiteData.longitude = e.latlng.lng;

        this.setState({
            newSiteData
        })

    }

    locationCallBack = (lat, lng, city, town) => {

        let newSiteData = { ...this.state.newSiteData };

        newSiteData.latitude = lat;
        newSiteData.longitude = lng;
        newSiteData.city = city;
        newSiteData.borough = town;

        this.setState({
            newSiteData
        })

    }

    /**/
    render() {

        const { t } = this.props;
        const headerTable = this.renderHeaderTableTop(t, "sites");
        const headerTableZone = this.renderHeaderTableTop(t, "zones");

        this.stepItems = [
            {
                label: t("siteDetails"),
                command: (event) => { },
            },
            {
                label: t("zoneIdentification"),
                command: (event) => { },
            },
        ];

        const addZoneDialogFooter = (
            <React.Fragment>
                <Button
                    label={t("cancel")}
                    icon="pi pi-times"
                    className="p-button-text"
                    onClick={this.hideAddZoneDialog}
                />
                <Button
                    label={t("save")}
                    icon="pi pi-save"
                    className="p-button-mb"
                    onClick={() => this.setState({ addZoneConfirmDialogVisible: true })}
                />

                <ConfirmDialog
                    visible={this.state.addZoneConfirmDialogVisible}
                    onHide={() => {
                        this.setState({ addZoneConfirmDialogVisible: false });
                    }}
                    message={t("confirmMessage")}
                    header={t("confirm")}
                    icon="pi pi-exclamation-triangle"
                    accept={this.acceptAddZone}
                    reject={this.rejectAddZone}
                    acceptLabel={t("yes")}
                    rejectLabel={t("no")}
                />
            </React.Fragment>
        );

        const addSiteDialogFooter = (
            <React.Fragment>
                <Button
                    label={t("cancel")}
                    icon="pi pi-times"
                    className="p-button-text"
                    onClick={this.hideAddSiteDialog}
                />
                <Button
                    label={t("save")}
                    icon="pi pi-save"
                    className="p-button-mb"
                    onClick={() => this.setState({ addSiteConfirmDialogVisible: true })}
                />

                <ConfirmDialog
                    visible={this.state.addSiteConfirmDialogVisible}
                    onHide={() => {
                        this.setState({ addSiteConfirmDialogVisible: false });
                    }}
                    message={t("confirmMessage")}
                    header={t("confirm")}
                    icon="pi pi-exclamation-triangle"
                    accept={this.acceptAddSite}
                    reject={this.rejectAddSite}
                    acceptLabel={t("yes")}
                    rejectLabel={t("no")}
                />
            </React.Fragment>
        );

        const updateZoneDialogFooter = (
            <React.Fragment>
                <Button
                    label={t("cancel")}
                    icon="pi pi-times"
                    className="p-button-text"
                    onClick={this.hideUpdateZoneDialog}
                />
                <Button
                    label={t("update")}
                    icon="pi pi-save"
                    className="p-button-mb"
                    onClick={() => this.setState({ updateZoneConfirmDialogVisible: true })}
                />

                <ConfirmDialog
                    visible={this.state.updateZoneConfirmDialogVisible}
                    onHide={() => {
                        this.setState({ updateZoneConfirmDialogVisible: false });
                    }}
                    message={t("confirmMessage")}
                    header={t("confirm")}
                    icon="pi pi-exclamation-triangle"
                    accept={this.acceptUpdateZone}
                    reject={this.rejectUpdateZone}
                    acceptLabel={t("yes")}
                    rejectLabel={t("no")}
                />
            </React.Fragment>
        );

        const updateSiteDialogFooter = (
            <React.Fragment>
                <Button
                    label={t("cancel")}
                    icon="pi pi-times"
                    className="p-button-text"
                    onClick={this.hideUpdateSiteDialog}
                />
                <Button
                    label={t("update")}
                    icon="pi pi-save"
                    className="p-button-mb"
                    onClick={() => this.setState({ updateSiteConfirmDialogVisible: true })}
                />

                <ConfirmDialog
                    visible={this.state.updateSiteConfirmDialogVisible}
                    onHide={() => {
                        this.setState({ updateSiteConfirmDialogVisible: false });
                    }}
                    message={t("confirmMessage")}
                    header={t("confirm")}
                    icon="pi pi-exclamation-triangle"
                    accept={this.acceptUpdateSite}
                    reject={this.rejectUpdateSite}
                    acceptLabel={t("yes")}
                    rejectLabel={t("no")}
                />
            </React.Fragment>
        );

        return (
            <div>
                {!isUser() && (
                    <>
                        <BlockUI blocked={this.state.blockedPanel} fullscreen>
                                <div className="flex align-items-center justify-content-center">
                                    <Link
                                        to={{ pathname: "/project", state: this.state.project }}
                                        style={{ textDecoration: "none" }}
                                    >
                                        <Chip
                                            style={{ fontSize: "15px" }}
                                            label={this.state.project.projectName
                                                + " (ID: " + this.state.project.pruid + ")"}
                                            className="mb-2 custom-chip-home"
                                        />
                                    </Link>
                                </div>

                            <br />
                            {/*{this.state.project.areWeProceeding === "2" && (
                                <><Chip
                                    label={<b>{t("stopped")}</b>}
                                    icon="pi pi-stop-circle"
                                    style={{ fontSize: "18px", position: "absolute", zIndex: "1", right: "10px" }}
                                    className="awpNo mr-2 mb-2 custom-chip-constant"
                                />
                                    <br /><br /></>
                            )}
                            {this.state.project.areWeProceeding === "3" && (
                                <><Chip
                                    label={<b>{t("pending")}</b>}
                                    icon="pi pi-bell"
                                    style={{ fontSize: "18px", position: "absolute", zIndex: "1", right: "10px" }}
                                    className="awpPending mr-2 mb-2 custom-chip-constant"
                                />
                                    <br /><br /></>
                            )}
                            <br />
                            */}

                            {/**   <Button
                                style={{ right: "0px", position: "fixed", fontSize: 5, top: "310px" }}
                                icon="pi pi-angle-left text-3xl"
                                iconPos="center"
                                //disabled={this.state.activeIndex === 4}
                                className="p-button-sm p-button-mb"
                                onClick={() =>
                                    this.setState({ visibleRight: true })
                                }
                            />
                         */}

                            <div className={"col-12 " + rowClass(this.state.project)}>
                                <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                                    <div className="flex justify-content-between mb-3">
                                        <Button
                                            // icon="pi pi-arrows-h"                     
                                            label={t("showPhases")}
                                            className="p-button-sm p-button-mb"
                                            onClick={() =>
                                                this.setState({ visibleCustomSidebar: true })
                                            }
                                        />
                                        <div>
                                            <Chip
                                                label={t("assetManagement")}
                                                icon="pi pi-building"
                                                className="mr-2 mb-2 custom-chip-constant"
                                            />
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="col-12 mb-0">
                                        <Steps
                                            model={this.stepItems}
                                            activeIndex={this.state.activeIndex}
                                            onSelect={(e) => {
                                                if (!this.state.saveButtonStatus) {
                                                    this.setState({ activeIndex: e.index })
                                                } else {
                                                    if (this.state.activeIndex != e.index) {
                                                        toast.warning(<Trans i18nKey="pleaseSaveFirst" />);
                                                    }
                                                }
                                            }}
                                            readOnly={false}
                                        />
                                    </div>




                                    <TabView
                                        className="projectNav"
                                        activeIndex={this.state.activeIndex}
                                        onTabChange={(e) => this.setState({ activeIndex: e.index })}
                                    >

                                        {/*assetManagement*/}
                                        <TabPanel header={t("assetManagement")}>
                                            <div className="mb-4" style={{ backgroundColor: "#FFFADD", padding: "10px" }}>
                                                {t("p12Text")}
                                            </div>

                                            {/*add Site Button*/}
                                            <div className="field col-12 mb-0">
                                                {isProjectManager() && (
                                                    <Button
                                                        label={t("btnAddSite")}
                                                        className="p-button p-component p-button-outlined p-button-sm"
                                                        onClick={this.handleAddSite}
                                                    />
                                                )}
                                            </div>

                                            {/*Site DataTable */}
                                            <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                                                <DataTable
                                                    name="dt"
                                                    size="small"
                                                    showGridlines
                                                    value={this.state.sites}
                                                    dataKey="id"
                                                    stripedRows
                                                    responsiveLayout="scroll"
                                                    emptyMessage={t("noSiteFound")}
                                                    header={headerTable}
                                                    paginator
                                                    rows={10}
                                                    filters={this.state.filters1}
                                                    filterDisplay="menu"
                                                    loading={this.state.loading}
                                                    globalFilterFields={[
                                                        "name",
                                                        "city",
                                                        "numberOfFloor"
                                                    ]}
                                                >


                                                    <Column
                                                        field="name"
                                                        header={t("siteName")}
                                                        sortable
                                                    ></Column>
                                                    <Column
                                                        field="numberOfFloor"
                                                        header={t("numberOfFloor")}
                                                        sortable
                                                    ></Column>
                                                    {isProjectManager() && (
                                                        <Column
                                                            header={t("action")}
                                                            style={{ textAlign: "center", width: "12em" }}
                                                            body={this.actionTemplateSite}
                                                        />
                                                    )}
                                                </DataTable>
                                            </div>



                                            <div className="grid">

                                                {/*Add Site Dialog */}
                                                <Dialog
                                                    visible={this.state.addSiteDialogVisible}
                                                    style={{ width: "600px" }}
                                                    header={t("addYourSite")}
                                                    className="p-fluid"
                                                    footer={addSiteDialogFooter}
                                                    onHide={this.hideAddSiteDialog}

                                                >
                                                    {/*siteName*/}
                                                    <div className="col-12 md:col-12 lg:col-12  ">
                                                        <div className="mb-1" >
                                                            <label htmlFor="name" className="platformColor">{t("siteName")} (*)</label>
                                                        </div>
                                                        <div>

                                                            <InputText
                                                                id="name"
                                                                readOnly={this.state.nonEditAccess}
                                                                value={this.state.newSiteData.name}
                                                                disabled={this.state.nonEditAccess}
                                                                placeholder={t("pleaseEnter")}
                                                                onChange={(e) =>
                                                                    this.onInputChangeSite(e, "name")
                                                                }
                                                                style={{ width: "100%" }}
                                                                maxLength={100}
                                                            />


                                                        </div>
                                                    </div>

                                                    {/*description*/}
                                                    <div className="col-12 md:col-12 lg:col-12">
                                                        <div className="mb-1">
                                                            <label htmlFor="description" className="platformColor">{t("description")}</label>
                                                        </div>
                                                        <div>

                                                            <InputTextarea
                                                                readOnly={this.state.nonEditAccess}
                                                                disabled={this.state.nonEditAccess}
                                                                id="description"
                                                                value={this.state.newSiteData.description}
                                                                placeholder={t("pleaseEnter")}
                                                                onChange={(e) => this.onInputChangeSite(e, "description")}
                                                                rows={3}
                                                                cols={20}
                                                                maxLength={500}
                                                                className="w-full"
                                                            />
                                                        </div>
                                                    </div>

                                                    {/*numberOfFloor*/}
                                                    <div className="col-12 md:col-12 lg:col-12 mb-4">
                                                        <div className="mb-1">
                                                            <label htmlFor="numberOfFloor" className="platformColor">{t("numberOfFloor")} (*)</label>
                                                        </div>
                                                        <div>

                                                            <Dropdown
                                                                id="numberOfFloor"
                                                                disabled={this.state.nonEditAccess}
                                                                value={this.state.newSiteData.numberOfFloor}
                                                                options={numberOfFloor}
                                                                placeholder={t("pleaseSelect")}
                                                                onChange={(e) =>
                                                                    this.onInputChangeSite(e, "numberOfFloor")
                                                                }
                                                                optionLabel="name"
                                                                optionValue="code"
                                                                className="w-full"
                                                            />
                                                        </div>
                                                    </div>

                                                    {/*Map button*/}

                                                    <div className="w-full col-6 md:col-6 lg:col-6 mb-4">
                                                        <Button
                                                            icon="pi pi-map"
                                                            label={t("chooseSite")}
                                                            onClick={(e) => {
                                                                this.setState({ mapVisible: true });
                                                            }}
                                                        />
                                                    </div>

                                                </Dialog>

                                                {/*Update Site Dialog */}
                                                <Dialog
                                                    visible={this.state.updateSiteDialogVisible}
                                                    style={{ width: "600px" }}
                                                    header={t("updateSite")}
                                                    className="p-fluid"
                                                    footer={updateSiteDialogFooter}
                                                    onHide={this.hideUpdateSiteDialog}

                                                >
                                                    {/*siteName*/}
                                                    <div className="col-12 md:col-12 lg:col-12  ">
                                                        <div className="mb-1" >
                                                            <label htmlFor="name" className="platformColor">{t("siteName")} (*)</label>
                                                        </div>
                                                        <div>

                                                            <InputText
                                                                id="name"
                                                                readOnly={this.state.nonEditAccess}
                                                                value={this.state.newSiteData.name}
                                                                disabled={this.state.nonEditAccess}
                                                                placeholder={t("pleaseEnter")}
                                                                onChange={(e) =>
                                                                    this.onInputChangeSite(e, "name")
                                                                }
                                                                style={{ width: "100%" }}
                                                                maxLength={100}
                                                            />


                                                        </div>
                                                    </div>


                                                    {/*description*/}
                                                    <div className="col-12 md:col-12 lg:col-12">
                                                        <div className="mb-1">
                                                            <label htmlFor="description" className="platformColor">{t("description")}</label>
                                                        </div>
                                                        <div>

                                                            <InputTextarea
                                                                readOnly={this.state.nonEditAccess}
                                                                disabled={this.state.nonEditAccess}
                                                                id="description"
                                                                value={this.state.newSiteData.description}
                                                                placeholder={t("pleaseEnter")}
                                                                onChange={(e) => this.onInputChangeSite(e, "description")}
                                                                rows={3}
                                                                cols={20}
                                                                maxLength={500}
                                                                className="w-full"
                                                            />
                                                        </div>
                                                    </div>


                                                    {/*numberOfFloor*/}
                                                    <div className="col-12 md:col-12 lg:col-12  ">
                                                        <div className="mb-1">
                                                            <label htmlFor="numberOfFloor" className="platformColor">{t("numberOfFloor")} (*)</label>
                                                        </div>
                                                        <div>

                                                            <Dropdown
                                                                id="numberOfFloor"
                                                                disabled={this.state.nonEditAccess}
                                                                value={this.state.newSiteData.numberOfFloor}
                                                                options={numberOfFloor}
                                                                placeholder={t("pleaseSelect")}
                                                                onChange={(e) =>
                                                                    this.onInputChangeSite(e, "numberOfFloor")
                                                                }
                                                                optionLabel="name"
                                                                optionValue="code"
                                                                className="w-full"
                                                            />
                                                        </div>
                                                    </div>

                                                    {/*Map button*/}
                                                    <div className="w-full col-6 md:col-6 lg:col-6 mb-4">
                                                        <Button
                                                            icon="pi pi-map"
                                                            label={t("chooseSite")}
                                                            onClick={(e) => {
                                                                this.setState({ mapUpdateVisible: true });
                                                            }}
                                                        />
                                                    </div>

                                                </Dialog>

                                                {/*Show Site Dialog */}
                                                <Dialog
                                                    visible={this.state.showSiteVisible}
                                                    style={{ width: "600px" }}
                                                    header={t("showSiteMap")}
                                                    className="p-fluid"
                                                    onHide={this.hideShowSite}
                                                >

                                                    {/*Show Map Butonu Action Template*/}
                                                    <div className="w-full col-6 md:col-6 lg:col-6 mb-4">

                                                        <LeafletMap
                                                            center={[this.state.newSiteData.latitude, this.state.newSiteData.longitude]}
                                                            zoom={13}
                                                            maxZoom={17}
                                                            minZoom={2}
                                                            scrollWheelZoom={true}
                                                            style={{ height: "600px" }}
                                                            attributionControl={true}
                                                            zoomControl={true}
                                                            doubleClickZoom={true}
                                                            dragging={true}
                                                            animate={true}
                                                            easeLinearity={0.35}
                                                        >
                                                            <TileLayer url="http://{s}.tile.osm.org/{z}/{x}/{y}.png" />

                                                            <ScaleControl position="bottomright" />



                                                            <Marker
                                                                position={[this.state.newSiteData.latitude, this.state.newSiteData.longitude]}
                                                                //draggable
                                                                icon={mapPin}
                                                                ondragend={this.handleDragEnd}
                                                            >
                                                            </Marker>

                                                        </LeafletMap>
                                                    </div>




                                                </Dialog>
                                            </div>

                                            {/*Map Edit Dialog */}
                                            <Dialog
                                                visible={this.state.mapVisible}
                                                style={{ width: "1400px" }}
                                                header={t("chooseSite")}
                                                modal
                                                className="p-fluid"
                                                onHide={this.hideMapDialog}
                                            >
                                                <div>

                                                    <MapMarkBuilding                                                       
                                                        data={this.state.newSiteData}
                                                        locationCallBack={this.locationCallBack}

                                                    />

                                                </div>
                                                <br />

                                                {/*latitude*/} {/*longitude*/}
                                                <div class="container">
                                                    <div class="row row-cols-2">
                                                        <div class="col">
                                                            <div className="col-12 md:col-6 lg:col-6">
                                                                <label htmlFor="latitude" className="platformColor">{t("latitude")} (*)</label>
                                                            </div>
                                                            <InputText

                                                                id="latitude"
                                                                readOnly={this.state.nonEditAccess}
                                                                value={this.state.newSiteData.latitude}
                                                                //disabled={this.state.nonEditAccess}
                                                                // placeholder={t("pleaseEnter")}
                                                                onChange={(e) =>
                                                                    this.onInputChangeSite(e, "latitude")
                                                                }
                                                                style={{ width: "100%" }}
                                                                maxLength={20}
                                                                disabled
                                                            />
                                                        </div>

                                                        <div class="col">
                                                            <div className="col-12 md:col-6 lg:col-6">
                                                                <label htmlFor="longitude" className="platformColor">{t("longitude")} (*)</label>
                                                            </div>
                                                            <InputText
                                                                id="longitude"
                                                                readOnly={this.state.nonEditAccess}
                                                                value={this.state.newSiteData.longitude}
                                                                // disabled={this.state.nonEditAccess}
                                                                //placeholder={t("pleaseEnter")}
                                                                onChange={(e) =>
                                                                    this.onInputChangeSite(e, "longitude")
                                                                }
                                                                style={{ width: "100%" }}
                                                                maxLength={20}
                                                                disabled
                                                            />

                                                        </div>
                                                    </div>
                                                </div>

                                                {/*city*/} {/*borough*/}
                                                <div class="container">
                                                    <div class="row row-cols-2">
                                                        <div class="col">
                                                            <div className="col-12 md:col-6 lg:col-6">
                                                                <label htmlFor="city" className="platformColor">{t("city")} (*)</label>
                                                            </div>
                                                            <InputText

                                                                id="city"
                                                                readOnly={this.state.nonEditAccess}
                                                                value={this.state.newSiteData.city}
                                                                //disabled={this.state.nonEditAccess}
                                                                // placeholder={t("pleaseEnter")}
                                                                onChange={(e) =>
                                                                    this.onInputChangeSite(e, "city")
                                                                }
                                                                style={{ width: "100%" }}
                                                                maxLength={20}

                                                            />
                                                        </div>

                                                        <div class="col">
                                                            <div className="col-12 md:col-6 lg:col-6">
                                                                <label htmlFor="borough" className="platformColor">{t("borough")} (*)</label>
                                                            </div>
                                                            <InputText
                                                                id="borough"
                                                                readOnly={this.state.nonEditAccess}
                                                                value={this.state.newSiteData.borough}
                                                                // disabled={this.state.nonEditAccess}
                                                                //placeholder={t("pleaseEnter")}
                                                                onChange={(e) =>
                                                                    this.onInputChangeSite(e, "borough")
                                                                }
                                                                style={{ width: "100%" }}
                                                                maxLength={20}
                                                            />

                                                        </div>
                                                    </div>
                                                </div>


                                                <Button
                                                    style={{ width: "120px", left: "18px" }}
                                                    disabled={this.state.newSiteData.latitude === 0 || this.state.newSiteData.longitude === 0}
                                                    icon="pi pi-check"
                                                    label={t("done")}
                                                    onClick={(e) => {
                                                        this.setState({ mapVisible: false });
                                                    }}
                                                />

                                            </Dialog>

                                            {/*Map Update Dialog */}
                                            <Dialog
                                                visible={this.state.mapUpdateVisible}
                                                style={{ width: "1400px" }}
                                                header={t("chooseSite")}
                                                modal
                                                className="p-fluid"
                                                onHide={this.hideMapUpdateDialog}>

                                                <div>
                                                </div>
                                                

                                                <MapMarkBuilding locationCallBack={this.locationCallBack}
                                                    data={this.state.newSiteData}
                                                />
                                                {/*latitude*/} {/*longitude*/}
                                                <div class="container">
                                                    <div class="row row-cols-2">
                                                        <div class="col">
                                                            <div className="col-12 md:col-6 lg:col-6">
                                                                <label htmlFor="latitude" className="platformColor">{t("latitude")} (*)</label>
                                                            </div>
                                                            <InputText

                                                                id="latitude"
                                                                readOnly={this.state.nonEditAccess}
                                                                value={this.state.newSiteData.latitude}
                                                                //disabled={this.state.nonEditAccess}
                                                                // placeholder={t("pleaseEnter")}
                                                                onChange={(e) =>
                                                                    this.onInputChangeSite(e, "latitude")
                                                                }
                                                                style={{ width: "100%" }}
                                                                maxLength={20}
                                                                disabled
                                                            />
                                                        </div>

                                                        <div class="col">
                                                            <div className="col-12 md:col-6 lg:col-6">
                                                                <label htmlFor="longitude" className="platformColor">{t("longitude")} (*)</label>
                                                            </div>
                                                            <InputText
                                                                id="longitude"
                                                                readOnly={this.state.nonEditAccess}
                                                                value={this.state.newSiteData.longitude}
                                                                // disabled={this.state.nonEditAccess}
                                                                //placeholder={t("pleaseEnter")}
                                                                onChange={(e) =>
                                                                    this.onInputChangeSite(e, "longitude")
                                                                }
                                                                style={{ width: "100%" }}
                                                                maxLength={20}
                                                                disabled
                                                            />

                                                        </div>
                                                    </div>
                                                </div>

                                                {/*city*/} {/*borough*/}
                                                <div class="container">
                                                    <div class="row row-cols-2">
                                                        <div class="col">
                                                            <div className="col-12 md:col-6 lg:col-6">
                                                                <label htmlFor="city" className="platformColor">{t("city")} (*)</label>
                                                            </div>
                                                            <InputText

                                                                id="city"
                                                                readOnly={this.state.nonEditAccess}
                                                                value={this.state.newSiteData.city}
                                                                //disabled={this.state.nonEditAccess}
                                                                // placeholder={t("pleaseEnter")}
                                                                onChange={(e) =>
                                                                    this.onInputChangeSite(e, "city")
                                                                }
                                                                style={{ width: "100%" }}
                                                                maxLength={20}
                                                                disabled
                                                            />
                                                        </div>

                                                        <div class="col">
                                                            <div className="col-12 md:col-6 lg:col-6">
                                                                <label htmlFor="borough" className="platformColor">{t("borough")} (*)</label>
                                                            </div>
                                                            <InputText
                                                                id="borough"
                                                                readOnly={this.state.nonEditAccess}
                                                                value={this.state.newSiteData.borough}
                                                                // disabled={this.state.nonEditAccess}
                                                                //placeholder={t("pleaseEnter")}
                                                                onChange={(e) =>
                                                                    this.onInputChangeSite(e, "borough")
                                                                }
                                                                style={{ width: "100%" }}
                                                                maxLength={20}
                                                                disabled
                                                            />

                                                        </div>
                                                    </div>
                                                </div>


                                                <Button
                                                    style={{ width: "120px", left: "18px" }}
                                                    disabled={this.state.newSiteData.latitude === 0 || this.state.newSiteData.longitude === 0}
                                                    icon="pi pi-check"
                                                    label={t("done")}
                                                    onClick={(e) => {
                                                        this.setState({ mapUpdateVisible: false });
                                                    }}
                                                />

                                            </Dialog>
                                        </TabPanel>




                                        {/*Zone Identification */}
                                        <TabPanel
                                            header={t("zoneIdentification")}>
                                            <div className="mb-4" style={{ backgroundColor: "#FFFADD", padding: "10px" }}>
                                                <div>{t("p12_2Text")}</div>
                                            </div>

                                            {/*add Zone Button*/}
                                            <div className="field col-12 mb-0">
                                                {isProjectManager() && (
                                                    <Button
                                                        label={t("btnAddZone")}
                                                        className="p-button p-component p-button-outlined p-button-sm"
                                                        onClick={this.handleAddZone}
                                                    />
                                                )}
                                            </div>

                                            {/*Zone Data Table*/}
                                            <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                                                <DataTable
                                                    name="dt"
                                                    size="small"
                                                    showGridlines
                                                    value={this.state.zones}
                                                    dataKey="id"
                                                    stripedRows
                                                    responsiveLayout="scroll"
                                                    emptyMessage={t("noZoneFound")}
                                                    header={headerTableZone}
                                                    paginator
                                                    rows={10}
                                                    filters={this.state.filters1}
                                                    filterDisplay="menu"
                                                    loading={this.state.loading}
                                                    globalFilterFields={[
                                                        "siteName",
                                                        "name",
                                                        "floor",
                                                        "zoneSize",
                                                    ]}
                                                >
                                                    <Column
                                                        field="siteName"
                                                        header={t("siteName")}
                                                        sortable
                                                    ></Column>
                                                    <Column
                                                        field="name"
                                                        header={t("zoneName")}
                                                        sortable
                                                    ></Column>
                                                    <Column
                                                        field="floor"
                                                        header={t("floor")}
                                                        sortable
                                                    ></Column>
                                                    <Column
                                                        field="zoneSize"
                                                        header={t("zoneSize")}
                                                        sortable
                                                    ></Column>
                                                    {isProjectManager() && (
                                                        <Column
                                                            header={t("action")}
                                                            style={{ textAlign: "center", width: "12em" }}
                                                            body={this.actionTemplateZone}
                                                        />
                                                    )}
                                                </DataTable>
                                            </div>

                                        </TabPanel>
                                    </TabView>

                                </div>
                                <br />
                                <Button
                                    icon="pi pi-chevron-circle-left"
                                    disabled={this.state.activeIndex <= 0}
                                    label={t("previous")}
                                    className="p-button-sm p-button-mb"
                                    onClick={() => this.stepPrev()}
                                />
                                &nbsp;
                                <Button
                                    icon="pi pi-chevron-circle-right"
                                    disabled={this.state.activeIndex >= this.stepItems.length - 1}
                                    label={t("next")}
                                    className="p-button-sm p-button-mb"
                                    onClick={() => this.stepNext()}
                                />
                                &nbsp;
                                {isProjectManager() && (
                                    <Button
                                        disabled={!this.state.saveButtonStatus}
                                        icon="pi pi-check-circle"
                                        label={this.state.activeIndex < this.stepItems.length - 1 ? t("saveNext") : t("save")}
                                        className="p-button-sm p-button-danger"
                                        onClick={() => this.stepSaveAndNext()}
                                    />
                                )}
                            </div>
                            <br />

                            {/*Zone Ekleme Dialog */}
                            <Dialog
                                visible={this.state.addZoneDialogVisible}
                                style={{ width: "450px" }}
                                header={t("addYourZones")}
                                className="p-fluid"
                                footer={addZoneDialogFooter}
                                onHide={this.hideAddZoneDialog}
                            >

                                <div className="p-float-label">

                                    <Dropdown
                                        id="name"
                                        disabled={this.state.nonEditAccess}
                                        value={this.state.newZoneData.siteId}
                                        options={this.state.sites}
                                        onChange={(e) => this.onInputChangeZone(e, "siteId")}
                                        optionLabel={this.dropdownZoneTemplate}
                                        optionValue="id"
                                        className="w-full"
                                    />
                                    <label htmlFor="name">{t("siteName")} (*)</label>

                                </div>

                                <div className="p-float-label">
                                    <InputText
                                        id="name"
                                        value={this.state.newZoneData.name}
                                        onChange={(e) => this.onInputChangeZone(e, "name")}
                                        maxLength={50}
                                        className="w-full"
                                    />
                                    <label htmlFor="name">{t("zoneName")} (*)</label>
                                </div>

                                <div className="p-float-label">
                                    <InputText
                                        id="floor"
                                        value={this.state.newZoneData.floor}
                                        onChange={(e) => this.onInputChangeZone(e, "floor")}
                                        maxLength={10}
                                        className="w-full"
                                    />
                                    <label htmlFor="floor">{t("floor")} (*)</label>
                                </div>

                                <div className="p-float-label">
                                    <InputText
                                        id="zoneSize"
                                        value={this.state.newZoneData.zoneSize}
                                        onChange={(e) => this.onInputChangeZone(e, "zoneSize")}
                                        maxLength={10}
                                        className="w-full"
                                    />
                                    <label htmlFor="zoneSize">{t("zoneSize")} (*)</label>
                                </div>

                                <div className="p-float-label">
                                    <InputText
                                        id="description"
                                        value={this.state.newZoneData.description}
                                        onChange={(e) =>
                                            this.onInputChangeZone(e, "description")
                                        }
                                        maxLength={500}
                                        className="w-full"
                                    />
                                    <label htmlFor="description">{t("description")}</label>
                                </div>
                            </Dialog>

                            {/*Zone Update Dialog */}
                            <Dialog
                                visible={this.state.updateZoneDialogVisible}
                                style={{ width: "450px" }}
                                header={t("updateZone")}
                                className="p-fluid"
                                footer={updateZoneDialogFooter}
                                onHide={this.hideUpdateZoneDialog}
                            >
                                <div className="p-float-label">

                                    <Dropdown
                                        id="name"
                                        disabled={this.state.nonEditAccess}
                                        value={this.state.newZoneData.siteId}
                                        options={this.state.sites}
                                        onChange={(e) => this.onInputChangeZone(e, "siteId")}
                                        optionLabel={this.dropdownZoneTemplate}
                                        optionValue="id"
                                        className="w-full"
                                    />
                                    <label htmlFor="name">{t("siteName")} (*)</label>

                                </div>

                                <div className="p-float-label">
                                    <InputText
                                        id="name"
                                        value={this.state.newZoneData.name}
                                        onChange={(e) => this.onInputChangeZone(e, "name")}
                                        maxLength={50}
                                        className="w-full"
                                    />
                                    <label htmlFor="name">{t("zoneName")} (*)</label>
                                </div>

                                <div className="p-float-label">
                                    <InputText
                                        id="floor"
                                        value={this.state.newZoneData.floor}
                                        onChange={(e) => this.onInputChangeZone(e, "floor")}
                                        maxLength={10}
                                        className="w-full"
                                    />
                                    <label htmlFor="floor">{t("floor")} (*)</label>
                                </div>

                                <div className="p-float-label">
                                    <InputText
                                        id="zoneSize"
                                        value={this.state.newZoneData.zoneSize}
                                        onChange={(e) => this.onInputChangeZone(e, "zoneSize")}
                                        maxLength={10}
                                        className="w-full"
                                    />
                                    <label htmlFor="zoneSize">{t("zoneSize")}</label>
                                </div>

                                <div className="p-float-label">
                                    <InputText
                                        id="description"
                                        value={this.state.newZoneData.description}
                                        onChange={(e) =>
                                            this.onInputChangeZone(e, "description")
                                        }
                                        maxLength={500}
                                        className="w-full"
                                    />
                                    <label htmlFor="description">{t("description")}</label>
                                </div>
                            </Dialog>



                            <SidebarComponent
                                visible={this.state.visibleCustomSidebar}
                                project={this.state.project}
                                setState={this.setState.bind(this)}
                                lang={t}
                            />
                            {/*
                            <SidebarRight
                                visible={this.state.visibleRight}
                                projectId={this.props.location.state.id}
                                setState={this.setState.bind(this)}
                                index={"P1" + this.state.activeIndex}
                                lang={t}
                            />
                                    */}

                            <WaitDialog
                                loading={this.state.loading}
                                lang={t}
                            />
                            <WaitDialogDocument loading={this.state.documentLoading} lang={t} />

                            <ToastContainer
                                autoClose={2200}
                                position={toast.POSITION.TOP_CENTER}
                                hideProgressBar={true}
                                newestOnTop={false}
                                closeOnClick={true}
                                rtl={false}
                                theme="colored"
                                pauseOnFocusLoss={true}
                                draggable={false}
                                pauseOnHover={true}
                            />
                        </BlockUI>
                    </>
                )
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { message } = state.message;
    return {
        message,
    };
}

export default withTranslation()(connect(mapStateToProps)(AssetManagement));
