import React, { Component } from "react";
import { Map, TileLayer } from "react-leaflet";
import L, { latLng } from "leaflet";
import "leaflet-routing-machine";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import { t } from "i18next";
import SysService from "../services/sys.service";
import { ToastContainer, toast } from "react-toastify";
import { Marker } from "leaflet";


const height = { height: "53vh" };

const mapPin = L.icon({
    iconUrl: require("../resources/pinIcon.png"),
    shadowUrl: require("../resources/shadow.png"),
    iconSize: [38, 38],
    shadowAnchor: [13, 42],
    iconAnchor: [13, 30],
    popupAnchor: [0, -29]
});


class MapMarkBuilding extends Component {

    constructor(props) {
        super(props);
        this.state = {
            latitude: 0,
            longitude: 0,

        };
    }


    componentDidMount() {
        const { locationCallBack } = this.props;

        const map = this.leafletMap.leafletElement;



        var marker =
            L.marker([this.state.latitude, this.state.longitude], { icon: mapPin })
                .bindPopup()
                .setPopupContent(map.closePopup)
                .addTo(map);




        if (this.state.latitude !== 0 && this.state.longitude !== 0) {
            map.flyTo([this.state.latitude, this.state.longitude], 10)
        }


        const geocoder = L.Control.Geocoder.nominatim();

        map.on("click", e => {

            geocoder.reverse(
                e.latlng,
                map.options.crs.scale(map.getZoom()),

                results => {
                    var r = results[0];
                    if (r) {
                        if (marker) {
                            marker
                                .setLatLng(r.center)
                                .setPopupContent(r.name)
                                .openPopup();
                        } else {
                            marker = L.marker(r.center)
                                .bindPopup(r.name)
                                .setIcon(mapPin)
                                .addTo(map)
                                .openPopup();
                        }
                    }


                    let city_ = "";
                    let town_ = "";

                    if (r.properties.address.province !== null) {
                        city_ = r.properties.address.province;
                    } else {
                        city_ = r.properties.address.state;
                    }

                    if (r.properties.address.town !== null) {
                        if (r.properties.address.town !== undefined) {
                            town_ = r.properties.address.town;
                        } else {
                            town_ = r.properties.address.county;
                        }

                    } else {
                        town_ = r.properties.address.county;
                    }

                    locationCallBack(r.center.lat, r.center.lng, city_, town_);
                }
            );
        });

    }

    render() {

        const { data } = this.props;

        this.state.latitude = data.latitude
        this.state.longitude = data.longitude


        return (

            <Map
                center={[43.50731233910749, 20.12774676732048648]}
                style={height}
                zoom={5}
                maxZoom={18}
                minZoom={4}
                scrollWheelZoom={true}
                ref={m => {
                    this.leafletMap = m;
                }}

            >
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                />

            </Map>


        );
    }
}

function mapStateToProps(state) {
    const { message } = state.message;
    return {
        message,
    };
}

export default withTranslation()(connect(mapStateToProps)(MapMarkBuilding));
