import React from "react";
import { Link } from "react-router-dom";
import { Chip } from "primereact/chip";
import { Sidebar } from "primereact/sidebar";
import { withTranslation } from "react-i18next";
import { isDevelopment } from "./functions";

const SidebarComponent = ({ visible, project, setState, t }) => {
  return (
    <Sidebar
      visible={visible}
      onHide={() => {
        setState({ visibleCustomSidebar: false });
      }}
    >
      <>
        <h3>{t("projectPhases")}</h3>
        <br />
        <Link to={{ pathname: "/pi", state: project }}
          style={{ textDecoration: "none" }}
        >
          <Chip
            label={t("phase1Name")}
            icon="pi pi-id-card"
            className="mr-2 mb-2 custom-chip w-full"
          />
        </Link>

        <Link to={{ pathname: "/assetManagement", state: project }}
          style={{ textDecoration: "none" }}
        >
          <Chip
            label={t("assetManagement")}
            icon="pi pi-building"
            className="mr-2 mb-2 custom-chip w-full"
          />
        </Link>

        <Link to={{ pathname: "/sensorData", state: project }}
          style={{ textDecoration: "none" }}        >

          <Chip
            label={t("sensorData")}
            icon="pi pi-globe"
            className="mr-2 mb-2 custom-chip w-full"
          />
        </Link>

        <Link to={{ pathname: "/projectDashboard", state: project }}
          style={{ textDecoration: "none" }}        >

          <Chip
            label={t("dashboard")}
            icon="pi pi-chart-bar"
            className="mr-2 mb-2 custom-chip w-full"
          />
        </Link>        


      </>
    </Sidebar>
  );
};

export default withTranslation()(SidebarComponent);
