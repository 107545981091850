import React, { Component } from "react";
import { Map, TileLayer, Marker, Popup, Polyline, ScaleControl, FeatureGroup } from "react-leaflet";
import L from "leaflet";
import 'leaflet/dist/leaflet.css';
import { Link } from "react-router-dom";
import { Chip } from "primereact/chip";
import { connect } from "react-redux";
import SysService from "../services/sys.service";
import { withTranslation, Trans } from "react-i18next";
import { toast } from "react-toastify";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import Routing from "./RoutingMachine";
import Search from "react-leaflet-search";
import { LatLng } from "leaflet";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip as TooltipJS,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import zoomPlugin from "chartjs-plugin-zoom";
import { Chart } from "chart.js";
import { t } from "i18next";


Chart.register(zoomPlugin);

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    TooltipJS,
    Title,
    Legend
);

const mapPin = L.icon({
    iconUrl: require("../resources/pinIcon.png"),
    shadowUrl: require("../resources/shadow.png"),
    iconSize: [30, 30],
    shadowAnchor: [13, 42],
    iconAnchor: [13, 30],
    popupAnchor: [0, -29]
});

{/*Finished Project Marker*/ }
const greenHouseMarker = new L.icon({
    iconUrl: require("../resources/houseGreen2.png"),
    iconSize: [40, 40],
    iconAnchor: [30, 20],
    popupAnchor: [-10, -20],
    popupHeight: [5, 5]
});


class MapComponent extends Component {

    emptyParam = {
        id: ""
    };

    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            data: [],
            heightVal: "70vh",
            param: { id: "" },
        };
    }

    componentDidMount() {
        const { data, heightVal } = this.props;

        this.setState({ data, heightVal });
    }

    setIdParam(data) {
        let param = {
            ...this.emptyParam,
            id: data.pojoProjectId
        };

        return param;
    }

    render() {

        const { data, heightVal } = this.state;

        const pos = [
            [36.460353, 126.440674],
            [34.789594, 135.438084], //to jpn

        ];

        return (
            <>
                <Map center={[39.50731233910749, 32.12774676732048648]} zoom={6} maxZoom={17} minZoom={4} scrollWheelZoom={true} style={{ height: heightVal }}
                    ref={this.saveMap} icon={greenHouseMarker}>
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>' />

                    {

                        data.map((ai) => (
                            <>

                                <Marker key={ai.id} position={[ai.latitude, ai.longitude]}
                                    icon={mapPin}>

                                    <Popup>
                                        <h5><b>{ai.pojoProjectName}</b></h5>
                                        <h6><b>{t("siteName") + ": "}</b>{ai.name}</h6>
                                        <h6><b>{t("city") + ": "}</b>{ai.city}</h6>

                                        <div >
                                            <Link to={{ pathname: "/project", state: this.setIdParam(ai) }} style={{ textDecoration: "none" }}>
                                                <Chip
                                                    style={{ background: "#1dc19b", color: "black" }}
                                                    label={t("projectDetails")}
                                                    icon="pi pi-list"
                                                />
                                            </Link>
                                            {" "}
                                            <Link to={{ pathname: "/projectDashboard", state: this.setIdParam(ai) }} style={{ textDecoration: "none" }}>
                                                <Chip
                                                    style={{ background: "#1dc19b", color: "black" }}
                                                    label={t("dashboard")}
                                                    icon="pi pi-chart-line"
                                                />
                                            </Link>
                                        </div>
                                    </Popup>
                                </Marker>
                            </>
                        ))
                    }


                    <ScaleControl position="bottomright" />

                    <FeatureGroup>
                        {pos?.map((mark, i) => (
                            <Marker key={i} position={mark} icon={mapPin} />
                        ))}

                        <Polyline positions={pos} color="red" />
                    </FeatureGroup>

                </Map>

            </>
        );
    }
}

function mapStateToProps(state) {
    const { message } = state.message;
    return {
        message,
    };
}

export default withTranslation()(connect(mapStateToProps)(MapComponent));
