import React, { Component } from "react";
import { history } from "./history";
import jwt_decode from "jwt-decode";

class AuthVerify extends Component {
  
  constructor(props) {
    super(props);

    history.listen(() => {
      const user = JSON.parse(localStorage.getItem("user"));

      if (user) {
        const decodedJwt = jwt_decode(user.token);

        if (decodedJwt.exp * 1000 < Date.now()) {
          props.logOut();
        }
      } else {
        props.logOut();
      }
    });
  }

  render() {
    return <div></div>;
  }
}

export default AuthVerify;
