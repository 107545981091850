import axios from "axios";
import apios from "../common/apios";
import authHeader from "./auth-header";

class SysService {

  getUserInfo() {
    return apios.get("sys/userInfo", { headers: authHeader() });
  }

  changePassword(username, oldPassword, newPassword) {
    return apios.post(
      "sys/changeUserPassword",
      {
        username,
        oldPassword,
        newPassword,
      },
      { headers: authHeader() }
    );
  }  

  updateUser(
    username,
    firstName,
    lastName,
    role = null
  ) {
    if (role === null) {
      return apios.post(
        "sys/updateUser",
        {
          username,
          firstName,
          lastName
        },
        { headers: authHeader() }
      );
    } else {
      return apios.post(
        "sys/updatePlatformUser",
        {
          username,
          firstName,
          lastName,
          role
        },
        { headers: authHeader() }
      );
    }
  }

  getAdminsAndPlatformManagers() {
    return apios.get("sys/adminsAndPlatformManagers", { headers: authHeader() });
  }  

  addNewUser(
    username,
    firstName,
    lastName,
    role = null
  ) {
    if (role === null) {
      return apios.post(
        "sys/addNewUser",
        {
          username,
          firstName,
          lastName,
        },
        { headers: authHeader() }
      );
    } else {
      return apios.post(
        "sys/addNewPlatformUser",
        {
          username,
          firstName,
          lastName,
          role
        },
        { headers: authHeader() }
      );
    }
  }
  
  getUsers() {
    return apios.get("sys/users", { headers: authHeader() });
  }

  getProjectsByPlatformManager() {
    return apios.get("sys/projectsByPlatformManager", { headers: authHeader() });
  }

  changeRole(username, rolename) {
    return apios.post(
      "sys/changeUserRole",
      {
        username,
        rolename,
      },
      { headers: authHeader() }
    );
  }

  changeProject(username, id) {
    return apios.post(
      "sys/changeUserProject",
      {
        username,
        id,
      },
      { headers: authHeader() }
    );
  }

  /////////////////////


  getUsersWithRole(roleName) {
    return apios.get("sys/usersWithRole", {
      params: {
        roleName: roleName,
      },
      headers: authHeader(),
    });
  }

  addNewProject(newProject) {
    return apios.post(
      "sys/addNewProject",
      {
        newProject,
      },
      { headers: authHeader() }
    );
  }

  updateProject(newProject) {
    return apios.post(
      "sys/updateProject",
      {
        newProject,
      },
      { headers: authHeader() }
    );
  }

  updateProjectRS(newProject) {
    return apios.post(
      "sys/updateProjectRS",
      {
        newProject,
      },
      { headers: authHeader() }
    );
  }

  getProjects() {
    return apios.get("sys/projects", { headers: authHeader() });
  }

  getSites() {
    return apios.get("sys/sites", { headers: authHeader() });
  }


  checkSiteDelete(id) {
    return apios.get("sys/checkSiteDelete", {
      params: {
        id: id,
      },
      headers: authHeader(),
    });
  }


  checkZoneDelete(id) {
    return apios.get("sys/checkZoneDelete", {
      params: {
        id: id,
      },
      headers: authHeader(),
    });
  }


  getSummary() {
    return apios.get("sys/summary", { headers: authHeader() });
  }

  getProject(pid) {
    return apios.get("sys/project", {
      params: {
        pid: pid,
      },
      headers: authHeader(),
    });
  }

  deleteProject(id) {
    return apios.get("sys/deleteProject", {
      params: {
        id: id,
      },
      headers: authHeader(),
    });
  }

  getS3URL(fileName, phase, pid, folder = null) {
    return apios.get("sys/fileUploadURL", {
      params: {
        fileName: fileName,
        phase: phase,
        pid: pid,
        folder: folder,
      },
      headers: authHeader(),
    });
  }

  putFile2S3URL(fileUploadURL, file) {
    return axios.put(fileUploadURL, file, {
      headers: {
        "Content-Type": "txt/pdf",
      },
    });
  }

  getSensorData(eui) {
    return apios.get("sys/sensorData", {
      params: {
        eui: eui,
      },
      headers: authHeader(),
    });
  }

  getSensorDataBySiteId(id) {
    return apios.get("sys/sensorDataBySiteId", {
      params: {
        id: id,
      },
      headers: authHeader(),
    });
  }  

  getPublicSensorDataBySiteId(id) {
    return apios.get("pub/publicSensorDataBySiteId", {
      params: {
        id: id,
      },
    });
  }    

  getSensorDataByZoneId(id) {
    return apios.get("sys/sensorDataByZoneId", {
      params: {
        id: id,
      },
      headers: authHeader(),
    });
  }    
  ///////////////////////////

  addNewApiKey(newApiKey) {
    return apios.post(
      "sys/addNewApiKey",
      {
        newApiKey,
      },
      { headers: authHeader() }
    );
  }

  deleteApiKey(id) {
    return apios.get("sys/deleteApiKey", {
      params: {
        id: id,
      },
      headers: authHeader(),
    });
  }

  getApiKeys() {
    return apios.get("sys/apiKeys", { headers: authHeader() });
  }

}
export default new SysService();
