import React, { Component } from "react";

import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import AuthService from "../services/auth.service";

class VerifyEmail extends Component {

  constructor(props) {

    super(props);

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let seccode = params.get("secCode");
    let username = params.get("email");

    this.state = {
      username: username,
      seccode: seccode,
      loading: false,
      successful: false,
      message: "",
    };
  }

  componentDidMount() {

      let username_ = "";
      if (this.state.username != null) {
        username_ = this.state.username;
      } 

      let seccode_ = "";
      if (this.state.seccode != null) {
        seccode_ = this.state.seccode;
      }       

      AuthService.verifyEmail(username_, seccode_).then(
        (response) => {
          this.setState({
            loading: false,
            successful: true,
            message: response.data.message,
          });
        },
        (error) => {
          this.setState({
            loading: false,
            successful: false,
            message: error.response.data.message,
            content:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString(),
          });

          if (
            error.response &&
            (error.response.status === 401 ||
              error.response.status === 403 ||
              error.response.status === 404)
          ) {
            switch (error.response.status) {
              case 401:
                window.location.href = "/401";
                break;
              case 403:
                window.location.href = "/403";
                break;
              case 404:
                window.location.href = "/404";
                break;
              default:
                break;
            }
          }
        }
      );
    
  }

  render() {
    
    const { t } = this.props;

    return (
      <>
        <div className="flex align-items-center justify-content-center">
          <div className="surface-card p-4 shadow-2 border-round w-full lg:w-4">

            <div className="text-center text-900 text-xl font-medium mb-4">
              {t("txtVerifyEmail1")}
            </div>

            <div className="form-group mb-6">
              <div
                className={
                  this.state.successful
                    ? "alert alert-success"
                    : "alert alert-danger"
                }
                role="alert"
              >
                <center>{t(this.state.message)}</center>
              </div>
            </div>

            <div className="text-center mb-2">
              <a
                className="font-medium no-underline ml-2 text-blue-500 cursor-pointer"
                href="/login"
              >
                {t("backToLogin")}
              </a>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { message } = state.message;
  return {
    message,
  };
}

export default withTranslation()(connect(mapStateToProps)(VerifyEmail));
