import React, { Component } from "react";

import { connect } from "react-redux";
import { resendVerifyEmail } from "../actions/auth";

import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Captcha } from "primereact/captcha";
import { withTranslation, Trans } from "react-i18next";
import WaitDialog from "../common/WaitDialog";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../resources/customToast.css";

class ResendMail extends Component {

  constructor(props) {

    super(props);

    this.state = {
      username: "",
      response: "",
      loading: false,
      successful: false,
    };
    this.recaptcha = null;
  }

  handleSubmit = (e) => {
    e.preventDefault();
  };

  handleRM = () => {
    this.setState({ loading: true });

    if (this.state.username == null || this.state.username === "") {
      this.setState({ loading: false, successful: false });
      toast.warning(<Trans i18nKey="warnResendEmail1"/>);
      this.recaptcha.reset();
      return;
    }

    if (this.state.response === "" || this.state.response === null) {
      this.setState({ loading: false });
      toast.warning(<Trans i18nKey="warnCaptcha"/>);
      this.recaptcha.reset();
      return;
    }

    if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.username)
    ) {
      this.setState({ loading: false, successful: false });
      toast.warning(<Trans i18nKey="warnEmailFormat"/>);
      this.recaptcha.reset();
      return;
    }

    const { dispatch } = this.props;

    dispatch(resendVerifyEmail(this.state.username, this.state.response))
      .then(() => {
        this.setState({
          successful: true,
          loading: false,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
          successful: false,
        });
        toast.error(<Trans i18nKey={this.props.message}/>);
        this.recaptcha.reset();
      });
  };

  showResponse = (recaptcha) => {
    const response = recaptcha.response;
    this.setState({ response });
  };

  render() {

    const { t, message } = this.props;


    return (
      <>
        <div className="flex align-items-center justify-content-center">
          <div className="surface-card p-4 shadow-2 border-round w-full lg:w-4">
            <div className="text-center text-900 text-xl font-medium">
              {t("txtResendEmail1")}
            </div>

            {!this.state.successful && (
              <>
                <div className="text-center mb-4">
                  <span className="text-600 font-medium line-height-3">
                  {t("txtResendEmail2")}
                  </span>
                </div>
                <div className="mb-4">
                  <form onSubmit={this.handleSubmit}>
                    <label
                      htmlFor="email"
                      className="block text-900 font-medium mb-2"
                    >
                      {t("email")}
                    </label>
                    <InputText
                      id="email"
                      type="text"
                      className="w-full p-inputtext block mb-5"
                      value={this.state.username}
                      onChange={(e) =>
                        this.setState({ username: e.target.value })
                      }
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}
                    >
                      <Captcha
                        ref={(recaptcha) => (this.recaptcha = recaptcha)}
                        siteKey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                        onResponse={this.showResponse}
                      />
                    </div>

                    <br />

                    <Button
                      label={t("resendVerification")}
                      icon="pi pi-lock"
                      className="w-full p-button-mb mb-4"
                      onClick={this.handleRM}
                    />
                  </form>
                </div>

                <div className="text-center mb-2">
                  <a
                    className="font-medium no-underline ml-2 text-blue-500 cursor-pointer"
                    href="/login"
                  >
                    {t("backToLogin")}
                  </a>
                </div>

                <WaitDialog loading={this.state.loading} lang={t} />

                <ToastContainer
                  autoClose={2200}
                  position={toast.POSITION.TOP_CENTER}
                  hideProgressBar={true}
                  newestOnTop={false}
                  closeOnClick={true}
                  rtl={false}
                  theme="colored"
                  pauseOnFocusLoss={true}
                  draggable={false}
                  pauseOnHover={true}
                />
              </>
            )}

            {message && this.state.successful && (
              <>
                <div className="form-group text-center mb-4">
                  <br />
                  <div
                    className={
                      this.state.successful
                        ? "alert alert-success"
                        : "alert alert-danger"
                    }
                    role="alert"
                  >
                    <center>{t(message)}</center>
                  </div>
                </div>
                <div className="text-center mb-2">
                  <a
                    className="font-medium no-underline ml-2 text-blue-500 cursor-pointer"
                    href="/login"
                  >
                    {t("backToLogin")}
                  </a>
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { message } = state.message;
  return {
    message,
  };
}

export default withTranslation()(connect(mapStateToProps)(ResendMail));
