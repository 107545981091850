import React, { Component } from "react";
import { TabView, TabPanel } from 'primereact/tabview';
import { DataTable } from 'primereact/datatable';
import { Column } from "primereact/column";
import { Button } from "primereact/button";

import "primereact/resources/themes/lara-light-teal/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

import { Link } from "react-router-dom";
import { Divider } from "primereact/divider";
import { Card } from "primereact/card";
import { Chip } from "primereact/chip";
import { BlockUI } from "primereact/blockui";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import WaitDialog from "../common/WaitDialog";

import SysService from "../services/sys.service";
import { withTranslation, Trans } from "react-i18next";

import { isUser } from "../common/token-check";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../resources/customToast.css";

import { rowClass } from '../common/functions';

import { isDevelopment } from "../common/functions";


class Project extends Component {



  constructor(props) {

    super(props);



    this.state = {

      project: "",

      pid: "",

      loading: false,

      blockedPanel: false,



      documents: [],



      filters1: null,

      globalFilterValue1: ""



    }



  }



  actionTemplate = (data, props) => {

    return (

      <div>

        <Button

          icon="pi pi-download"

          href

          tooltip="Download"

          className="p-button-rounded p-button-text"



          onClick={(e) => {

            this.handleDownloadDoc(data, props);

          }}

        />



      </div>

    );

  }

  handleDownloadDoc = (rowData) => {

    var a = document.createElement("a");

    a.target = "_blank";

    a.href = rowData.documentURL;

    a.setAttribute("download", rowData.documentName);

    a.click();

  }



  tabHeaderITemplate(options) {

    return (

      <button type="button" onClick={options.onClick} className={options.className}
      >
        <i className="pi pi-prime mr-2" />
        {options.titleElement}
      </button>
    );
  }


  componentDidMount() {
    this.setState({
      loading: true,
      pid: this.props.location.state.id,

    });



    if (!isUser()) {

      SysService.getProject(this.props.location.state.id).then(
        (response) => {
          this.setState({
            project: response.data,
            documents: response.data.documentList,
            loading: false,
            blockedPanel: false,
          });

        },

        (error) => {

          this.setState({
            loading: false,
            content:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.timeoutErrorMessage ||
              error.toString(),
            blockedPanel: true,
          });


          toast.error(<Trans i18nKey={this.state.content} />);

          if (

            error.response &&
            (error.response.status === 401 ||
              error.response.status === 403 ||
              error.response.status === 404)
          ) {

            switch (error.response.status) {
              case 401:
                window.location.href = "/401";
                break;

              case 403:
                window.location.href = "/403";
                break;

              case 404:
                window.location.href = "/404";
                break;

              default:
                break;
            }
          }
        }
      );

    }

    this.initFilters1();
    window.scrollTo(0, 0);

  }



  renderHeaderTableTop(t) {

    return (

      <div className="flex justify-content-between">
        <span>{t("documents")}</span>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />

          <InputText
            className="p-inputtext-sm block mb-2"
            value={this.state.globalFilterValue1}
            onChange={this.onGlobalFilterChange1}
            placeholder={t("search")}
          />

        </span>
      </div>
    );

  }



  onGlobalFilterChange1 = (e) => {

    const value = e.target.value;
    let filters1 = { ...this.state.filters1 };
    filters1["global"].value = value;

    this.setState({ filters1, globalFilterValue1: value });

  }



  initFilters1 = () => {
    this.setState({
      filters1: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },

      globalFilterValue1: "",

    });
  }



  render() {

    const { t } = this.props;

    return (

      <div>

        {!isUser() && (
          <>
            <BlockUI blocked={this.state.blockedPanel} fullscreen>
              <div className={"col-12 " + rowClass(this.state.project)}>
                <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">

                    <div className="flex align-items-center justify-content-center">
                      <Link
                        to={{ pathname: "/project", state: this.state.project }}
                        style={{ textDecoration: "none" }}
                      >

                        <Chip style={{ fontSize: "15px"}}
                          label={this.state.project.projectName
                            + " (ID: " + this.state.project.pruid + ")"}
                          className="mb-2 custom-chip-home"

                        />

                      </Link>
                    </div>
                  <br />

                  {this.state.project.areWeProceeding === "2" && (
                    <Chip
                      label={<b>{t("stopped")}</b>}
                      icon="pi pi-stop-circle"
                      style={{  position: "absolute", zIndex: "3", right: "10px" }}
                      className="awpNo mr-2 mb-2 custom-chip-constant"
                    />

                  )}

                  {this.state.project.areWeProceeding === "3" && (
                    <Chip
                      label={<b>{t("pending")}</b>}
                      icon="pi pi-bell"
                      style={{ position: "absolute", zIndex: "3", right: "10px" }}
                      className="awpPending mr-2 mb-2 custom-chip-constant"
                    />

                  )}



                  <TabView>

                    <TabPanel header={t("phases")}  style={{ outerWidth: "100%", innerWidth: "84px" }}>

                      {/*Project Information */}
                      <div className="flex ">
                        <span className="col-6">
                          <Link
                            to={{ pathname: "/pi", state: this.state.project }}
                            style={{ textDecoration: "none" }}
                          >

                            <Chip 
                              label={t("phase1Name")}
                              icon="pi pi-id-card"
                              className="mr-2 mb-2 custom-chip"
                            />
                          </Link>
                        </span>
                        <Divider layout="vertical" />
                      </div>
                      <hr />

                      {/*Asset Management */}
                      <div className="flex ">
                        <span className="col-6">
                          <Link
                            to={{ pathname: "/assetManagement", state: this.state.project }}
                            style={{ textDecoration: "none" }}
                          >

                            <Chip 
                              label={t("assetManagement")}
                              icon="pi pi-building"
                              className="mr-2 mb-2 custom-chip"
                            />
                          </Link>
                        </span>
                        <Divider layout="vertical" />
                      </div>
                      <hr />

                      {/*SensorData */}
                      <div className="flex ">
                        <span className="col-6">
                          <Link
                            to={{ pathname: "/sensorData", state: this.state.project }}
                            style={{ textDecoration: "none" }}
                          >

                            <Chip 
                              label={t("sensorData")}
                              icon="pi pi-globe"
                              className="mr-2 mb-2 custom-chip"
                            />

                          </Link>
                        </span>
                        <Divider layout="vertical" />
                      </div>
                      <hr />

                      {/*SensorData */}
                      <div className="flex ">
                        <span className="col-6">
                          <Link
                            to={{ pathname: "/projectDashboard", state: this.state.project }}
                            style={{ textDecoration: "none" }}
                          >

                            <Chip 
                              label={t("dashboard")}
                              icon="pi pi-chart-bar"
                              className="mr-2 mb-2 custom-chip"
                            />

                          </Link>
                        </span>
                        <Divider layout="vertical" />
                      </div>
                      <hr />                      

                      {/*Notes */}
                      {/*areWeProceeding: NO */}
                      {this.state.project.areWeProceeding === '2' && (
                        <>
                          <div className="d-flex justify-content-end " >
                            <div class="card awpNo mr-2 mb-2 custom-chip-constant " style={{ maxWidth: "38%", borderRadius: "30px" }}
                              icon="pi pi-comments"
                            >
                              <div class="card-body">
                                <b>
                                  <p class="card-text" 
                                  >{"Reason: "}
                                  </p>
                                </b>

                                <p class="card-text" 
                                >{this.state.project.notes}
                                </p>

                              </div>
                            </div>
                          </div>

                        </>
                      )
                      }

                      {/*areWeProceeding: PENDING */}
                      {this.state.project.areWeProceeding === '3' && (
                        <>
                          <div className="d-flex justify-content-end " >
                            <div class="card awpPending mr-2 mb-2 custom-chip-constant" style={{ maxWidth: "38%", borderRadius: "30px" }}
                              icon="pi pi-comments"
                            >
                              <div class="card-body">
                                <b>
                                  <p class="card-text" 
                                  >{"Reason: "}
                                  </p>
                                </b>

                                <p class="card-text" 
                                >{this.state.project.notes}
                                </p>

                              </div>
                            </div>
                          </div>

                        </>
                      )
                      }
                    </TabPanel  >


                    {/*DOCUMENTS TABLE*/}
                    <TabPanel header={t("documents")}>
                      <div className="col-12">
                        <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                          <DataTable
                            name="dt"
                            size="small"
                            showGridlines
                            selectionMode="single"
                            value={this.state.documents}
                            dataKey="id"
                            stripedRows
                            responsiveLayout="scroll"
                            emptyMessage={t("noDocumentFound")}
                            header={this.renderHeaderTableTop(t)}
                            paginator
                            rows={10}
                            filters={this.state.filters1}
                            filterDisplay="menu"
                            loading={this.state.loading}
                            globalFilterFields={[
                              "fileName",
                              "phase",
                              "documentDate"
                            ]}
                          >
                            <Column field="fileName" header={t("documentName")} sortable></Column>
                            <Column field="phase" header={t("phase")} sortable></Column>
                            <Column field="documentDate" header={t("date")} sortable></Column>
                            <Column header={t("action")} style={{ textAlign: "center", width: "8em" }}
                              body={this.actionTemplate}

                            />
                          </DataTable>

                        </div>
                      </div>

                      <hr />

                    </TabPanel>
                    <br />

                  </TabView>
                </div>

              </div>


              <WaitDialog loading={this.state.loading} lang={t} />

              <ToastContainer
                autoClose={2200}
                position={toast.POSITION.TOP_CENTER}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick={true}
                rtl={false}
                theme="colored"
                pauseOnFocusLoss={true}
                draggable={false}
                pauseOnHover={true}
              />

            </BlockUI >

          </>

        )
        }

      </div>

    );

  }

}

export default withTranslation()(Project);