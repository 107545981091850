import React, { Component } from "react";
import { Dropdown } from "primereact/dropdown";
import "primereact/resources/themes/lara-light-teal/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

import { Link } from "react-router-dom";
import { Card } from "primereact/card";
import { Chip } from "primereact/chip";
import { BlockUI } from "primereact/blockui";
import { diffMinutes, roundToTwo } from "../common/functions";
import WaitDialog from "../common/WaitDialog";

import SysService from "../services/sys.service";
import { withTranslation, Trans } from "react-i18next";

import { isUser, isLimitedViewer } from "../common/token-check";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../resources/customToast.css";

import { rowClass } from "../common/functions";
import Graphic from "../common/graphic";
import { Line } from "react-chartjs-2";
import { changeLanguage, t } from "i18next";

class ProjectDashboard extends Component {
  emptyZone = {
    zoneName: "",
    eqArr: [],
  };

  emptyEq = {
    eqName: "",
    eqType: "",
    eqEui: "",
    ts: "",
    co2: "",
    lig: "",
    hum: "",
    tem: "",
    bat: "",
    gw: "",
    tsArr: [],
    ligArr: [],
    humArr: [],
    temArr: [],
    co2Arr: [],
    data: "",
    data2: "",
  };

  constructor(props) {
    super(props);

    this.state = {
      project: "",
      pid: "",
      eqList: [],
      zoneList: [],
      dataOpt: "",
      optDate: "",
      loading: false,
      blockedPanel: false,

      zoneId: "",
      siteId: "",
      filterTxt: "",
    };
  }

  showDataBySite = (e, name) => {

    console.log(e);

    const val = (e.target && e.target.value) || "";

    this.setState({
      loading: true,
      zoneList: [],
      eqList: [],
      optDate: "",
      dataOpt: "",
      filterTxt: "",
    });

    if (!isUser()) {
      SysService.getSensorDataBySiteId(val).then(
        (response) => {

          for (let i = 0; i < response.data.zoneDataResponseList.length; i++) {
            let zone = {
              ...this.emptyZone,
              zoneName: response.data.zoneDataResponseList[i].name,
            };

            const optValData = JSON.parse(response.data.optVal);
            const optDate = response.data.optDate;

            const tsArrOpt = [];
            const temArrOpt = [];
            const optArrOpt = [];

            for (let k = 0; k < optValData.length; k++) {
              tsArrOpt.push(optValData[k].ts);
              optArrOpt.push(optValData[k].opt);
              temArrOpt.push(roundToTwo(optValData[k].tem));
            }      
            
            const labels = tsArrOpt;
            const dataOptX = {
              labels,
              datasets: [
                {
                  label: t("optimizationResult"),
                  data: optArrOpt,
                  borderColor: "rgba(255, 0, 0, 0.5)",
                  borderCapStyle: "butt",
                  backgroundColor: "rgba(255, 0, 0, 0.5)",
                  yAxisID: "y",
                  pointStyle: 'circle',
                  pointRadius: 2,
                  pointHoverRadius: 2                    
                },
                {
                  label: t("outdoorTemperature"),
                  data: temArrOpt,
                  borderColor: "rgba(0, 0, 255, 0.5)",
                  borderCapStyle: "butt",
                  backgroundColor: "rgba(0, 0, 255, 0.5)",
                  yAxisID: "y1",
                  pointStyle: 'circle',
                  pointRadius: 2,
                  pointHoverRadius: 2                    
                },
              ],
            };  
            
            let sensorDataList = response.data.zoneDataResponseList[i].sdrList;

            this.setState({
              dataOpt: dataOptX,
              optDate: optDate,
              eqList: [],
            });

            for (let j = 0; j < sensorDataList.length; j++) {
              let eq = {
                ...this.emptyEq,
                eqEui: sensorDataList[j].eui,
                eqName: sensorDataList[j].deviceName,
                eqType: sensorDataList[j].deviceType,
              };

              const sensorData = JSON.parse(sensorDataList[j].jsonVal);

              if (sensorData.length > 0) {
                eq = {
                  ...eq,
                  ts: sensorData[sensorData.length - 1].ts,
                  co2: sensorData[sensorData.length - 1].co2,
                  lig: sensorData[sensorData.length - 1].lig,
                  hum: roundToTwo(sensorData[sensorData.length - 1].hum),
                  tem: roundToTwo(sensorData[sensorData.length - 1].tem),
                  bat: sensorData[sensorData.length - 1].bat,
                  gw: sensorData[sensorData.length - 1].gw,
                  diff: sensorData[sensorData.length - 1].ts == "" ? "": diffMinutes(sensorData[sensorData.length - 1].ts),
                };

                const tsArr = [];
                const ligArr = [];
                const humArr = [];
                const temArr = [];
                const co2Arr = [];

                for (let k = 0; k < sensorData.length; k++) {
                  tsArr.push(sensorData[k].ts);
                  ligArr.push(sensorData[k].lig);
                  humArr.push(roundToTwo(sensorData[k].hum));
                  temArr.push(roundToTwo(sensorData[k].tem));
                  co2Arr.push(sensorData[k].co2);
                }

                eq = {
                  ...eq,
                  tsArr: tsArr,
                  ligArr: ligArr,
                  humArr: humArr,
                  temArr: temArr,
                  co2Arr: co2Arr,
                };
              }

              const labels = eq.tsArr;
              const data = {
                labels,
                datasets: [
                  {
                    label: t("humidity"),
                    data: eq.humArr,
                    borderColor: "rgba(0, 0, 255, 0.5)",
                    borderCapStyle: "butt",
                    backgroundColor: "rgba(0, 0, 255, 0.5)",
                    yAxisID: "y",
                    pointStyle: 'circle',
                    pointRadius: 0,
                    pointHoverRadius: 0                    
                  },
                ],
              };

              const data2 = {
                labels,
                datasets: [
                  {
                    label: t("temperature"),
                    data: eq.temArr,
                    borderColor: "rgb(255, 0, 0)",
                    borderCapStyle: "butt",
                    backgroundColor: "rgba(255, 0, 0, 0.5)",
                    yAxisID: "y",
                    pointStyle: 'circle',
                    pointRadius: 0,
                    pointHoverRadius: 0                     
                  },
                ],
              };

              const data3 = {
                labels,
                datasets: [
                  {
                    label: "CO₂",
                    data: eq.co2Arr,
                    borderColor: "rgb(255, 165, 0)",
                    borderCapStyle: "butt",
                    backgroundColor: "rgba(255, 165, 0, 0.5)",
                    yAxisID: "y",
                    pointStyle: 'circle',
                    pointRadius: 0,
                    pointHoverRadius: 0                     
                  },
                ],
              };

              eq = {
                ...eq,
                data: data,
                data2: data2,
                data3: data3
              };

              this.setState((prevState) => ({
                eqList: [...prevState.eqList, eq],
              }));
            }

            zone = {
              ...zone,
              eqArr: this.state.eqList,
            };

            this.setState((prevState) => ({
              zoneList: [...prevState.zoneList, zone],
            }));
          }

          this.setState({
            project: response.data.project,
            filterTxt: t("filteredBySite2")+ ": " +response.data.siteName,
            loading: false,
            blockedPanel: false,
          });
        },

        (error) => {
          this.setState({
            loading: false,
            content:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.timeoutErrorMessage ||
              error.toString(),
            blockedPanel: false,
          });

          toast.error(<Trans i18nKey={this.state.content} />);
        }
      );
    }
  };

  showDataByZone = (e, name) => {
    const val = (e.target && e.target.value) || "";

    this.setState({
      loading: true,
      zoneList: [],
      eqList: [],
      optDate: "",
      dataOpt: "",
      filterTxt: "",
    });

    if (!isUser()) {
      SysService.getSensorDataByZoneId(val).then(
        (response) => {

          for (let i = 0; i < response.data.zoneDataResponseList.length; i++) {
            let zone = {
              ...this.emptyZone,
              zoneName: response.data.zoneDataResponseList[i].name,
            };

            const optValData = JSON.parse(response.data.optVal);
            const optDate = response.data.optDate;

            const tsArrOpt = [];
            const temArrOpt = [];
            const optArrOpt = [];

            for (let k = 0; k < optValData.length; k++) {
              tsArrOpt.push(optValData[k].ts);
              optArrOpt.push(optValData[k].opt);
              temArrOpt.push(roundToTwo(optValData[k].tem));
            }      
            
            const labels = tsArrOpt;
            const dataOptX = {
              labels,
              datasets: [
                {
                  label: t("optimizationResult"),
                  data: optArrOpt,
                  borderColor: "rgba(0, 0, 255, 0.5)",
                  borderCapStyle: "butt",
                  backgroundColor: "rgba(0, 0, 255, 0.5)",
                  yAxisID: "y",
                  pointStyle: 'circle',
                  pointRadius: 2,
                  pointHoverRadius: 2                    
                },
                {
                  label:t("outdoorTemperature") ,
                  data: temArrOpt,
                  borderColor: "rgba(255, 0, 0, 0.5)",
                  borderCapStyle: "butt",
                  backgroundColor: "rgba(255, 0, 0, 0.5)",
                  yAxisID: "y1",
                  pointStyle: 'circle',
                  pointRadius: 2,
                  pointHoverRadius: 2                    
                },
              ],
            };  
                      
            let sensorDataList = response.data.zoneDataResponseList[i].sdrList;

            this.setState({
              dataOpt: dataOptX,
              optDate: optDate,              
              eqList: [],
            });

            for (let j = 0; j < sensorDataList.length; j++) {
              let eq = {
                ...this.emptyEq,
                eqEui: sensorDataList[j].eui,
                eqName: sensorDataList[j].deviceName,
                eqType: sensorDataList[j].deviceType,                
              };

              const sensorData = JSON.parse(sensorDataList[j].jsonVal);

              if (sensorData.length > 0) {
                eq = {
                  ...eq,
                  ts: sensorData[sensorData.length - 1].ts,
                  co2: sensorData[sensorData.length - 1].co2,
                  lig: sensorData[sensorData.length - 1].lig,
                  hum: roundToTwo(sensorData[sensorData.length - 1].hum),
                  tem: roundToTwo(sensorData[sensorData.length - 1].tem),
                  bat: sensorData[sensorData.length - 1].bat,
                  gw: sensorData[sensorData.length - 1].gw,
                  diff: sensorData[sensorData.length - 1].ts == "" ? "": diffMinutes(sensorData[sensorData.length - 1].ts),
                };

                const tsArr = [];
                const ligArr = [];
                const humArr = [];
                const temArr = [];
                const co2Arr = [];

                for (let k = 0; k < sensorData.length; k++) {
                  tsArr.push(sensorData[k].ts);
                  ligArr.push(sensorData[k].lig);
                  humArr.push(roundToTwo(sensorData[k].hum));
                  temArr.push(roundToTwo(sensorData[k].tem));
                  co2Arr.push(sensorData[k].co2);
                }

                eq = {
                  ...eq,
                  tsArr: tsArr,
                  ligArr: ligArr,
                  humArr: humArr,
                  temArr: temArr,
                  co2Arr: co2Arr,
                };
              }

              const labels = eq.tsArr;
              const data = {
                labels,
                datasets: [
                  {
                    label: t("humidity"),
                    data: eq.humArr,
                    borderColor: "rgba(0, 0, 255, 0.5)",
                    borderCapStyle: "butt",
                    backgroundColor: "rgba(0, 0, 255, 0.5)",
                    yAxisID: "y",
                    pointStyle: 'circle',
                    pointRadius: 2,
                    pointHoverRadius: 2                     
                  },
                ],
              };

              const data2 = {
                labels,
                datasets: [
                  {
                    label: t("temperature"),
                    data: eq.temArr,
                    borderColor: "rgb(255, 0, 0)",
                    borderCapStyle: "butt",
                    backgroundColor: "rgba(255, 0, 0, 0.5)",
                    yAxisID: "y",
                    pointStyle: 'circle',
                    pointRadius: 2,
                    pointHoverRadius: 2                     
                  },
                ],
              };
 
              const data3 = {
                labels,
                datasets: [
                  {
                    label: "CO₂",
                    data: eq.co2Arr,
                    borderColor: "rgb(255, 165, 0)",
                    borderCapStyle: "butt",
                    backgroundColor: "rgba(255, 165, 0, 0.5)",
                    yAxisID: "y",
                    pointStyle: 'circle',
                    pointRadius: 2,
                    pointHoverRadius: 2                     
                  },
                ],
              };

              eq = {
                ...eq,
                data: data,
                data2: data2,
                data3: data3,
              };

              this.setState((prevState) => ({
                eqList: [...prevState.eqList, eq],
              }));
            }

            zone = {
              ...zone,
              eqArr: this.state.eqList,
            };

            this.setState((prevState) => ({
              zoneList: [...prevState.zoneList, zone],
            }));
          }

          this.setState({
            project: response.data.project,
            filterTxt:
            t("filteredByZone2") +": "+
              this.state.zoneList[0].zoneName +
               "("+t("site")+": "  +
              response.data.siteName +
              ")",
            loading: false,
            blockedPanel: false,
          });
        },

        (error) => {
          this.setState({
            loading: false,
            content:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.timeoutErrorMessage ||
              error.toString(),
            blockedPanel: false,
          });

          toast.error(<Trans i18nKey={this.state.content} />);
        }
      );
    }
  };

  componentDidMount() {
    this.setState({
      loading: true,
      pid: this.props.location.state.id,
    });

    if (!isUser()) {
      SysService.getProject(this.props.location.state.id).then(
        (response) => {
          console.log(response.data);
          console.log(response.data.siteList[0].id);
          this.setState({
            project: response.data,
            sites: response.data.siteList,
            zones: response.data.zoneList,
            loading: false,
            blockedPanel: false,
          });

          if (response.data.siteList.length > 0 && !isLimitedViewer()) {
            let target = {
              value: response.data.siteList[0].id
            }
            let e = {
              target
            };
            this.showDataBySite(e, "siteId");
          }

          if (response.data.zoneList.length > 0 && isLimitedViewer()) {
            let target = {
              value: response.data.zoneList[0].id
            }
            let e = {
              target
            };
            this.showDataByZone(e, "zoneId");
          }          
        },
        (error) => {
          this.setState({
            loading: false,
            content:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.timeoutErrorMessage ||
              error.toString(),
            blockedPanel: true,
          });

          toast.error(this.state.content);

          if (
            error.response &&
            (error.response.status === 401 ||
              error.response.status === 403 ||
              error.response.status === 404)
          ) {
            switch (error.response.status) {
              case 401:
                window.location.href = "/401";
                break;
              case 403:
                window.location.href = "/403";
                break;
              case 404:
                window.location.href = "/404";
                break;
              default:
                break;
            }
          }
        }
      );
    }

    window.scrollTo(0, 0);
  }

  render() {
    const { t } = this.props;

    {
      /*Chart options*/
    }
    const options = {
      responsive: true,
      stacked: false,
      interaction: {
        mode: "index",
        intersect: false,
      },
      plugins: {
        legend: {
          position: "top",
          labels: {
            usePointStyle: true,
            pointStyle: "circle",
            padding: 10,
          },
        },
        zoom: {
          pan: {
            enabled: true,
            mode: "x",
          },
          zoom: {
            wheel: {
              enabled: true,
            },
            pinch: {
              enabled: false,
            },
            mode: "x",
          },
        },
      },
      scales: {
        x: {
          ticks: {
            font: {
              size: 8,
            },
          },
        },
        y: {
          ticks: {
            font: {
              size: 10,
            },
          },
          type: "linear",
          display: true,
          position: "left",
          //beginAtZero: true,
          //min: 20,
          //max: 100,
        },
      },
    };

    const options2 = {
      responsive: true,
      stacked: false,
      interaction: {
        mode: "index",
        intersect: false,
      },

      plugins: {
        legend: {
          position: "top",
          labels: {
            usePointStyle: true,
            pointStyle: "circle",
            padding: 10,
          },
        },
        zoom: {
          pan: {
            enabled: true,
            mode: "x",
          },
          zoom: {
            wheel: {
              enabled: true,
            },
            pinch: {
              enabled: false,
            },
            mode: "x",
          },
        },
      },
      scales: {
        x: {
          ticks: {
            font: {
              size: 8,
            },
          },
        },
        y: {
          ticks: {
            font: {
              size: 10,
            },
          },
          type: "linear",
          display: true,
          position: "left",
          //beginAtZero: true,
        },
      },
    };

    const optionsMUAX = {
      responsive: true,
      stacked: false,
      interaction: {
        mode: "index",
        intersect: false,
      },
      plugins: {
        legend: {
          position: "top",
          labels: {
            usePointStyle: true,
            pointStyle: "circle",
            padding: 10,
          },
        },
        zoom: {
          pan: {
            enabled: true,
            mode: "x",
          },
          zoom: {
            wheel: {
              enabled: true,
            },
            pinch: {
              enabled: false,
            },
            mode: "x",
          },
        },
      },
      scales: {
        x: {
          ticks: {
            font: {
              size: 10,
            },
          },
        },
        y: {
          ticks: {
            font: {
              size: 10,
            },
          },
          title: {
            display: true,
            text: t("optimizationResult"),
            color: "rgba(255, 0, 0, 0.5)",
          },
          type: "linear",
          display: true,
          position: "left",
          //beginAtZero: true,
          //min: 20,
          //max: 100,
        },
        y1: {
          ticks: {
            font: {
              size: 10,
            },
          },
          title: {
            display: true,
            text: t("outdoorTemperature"),
            color: "rgba(0, 0, 255, 0.5)",
          },          
          type: "linear",
          display: true,
          position: "right",
          grid: {
            drawOnChartArea: false,
          },          
          //beginAtZero: true,
          //min: 20,
          //max: 100,
        },        
      },
    };    

    return (
      <div>
        {!isUser() && (
          <>
            <BlockUI blocked={this.state.blockedPanel} fullscreen>
              <div className={"col-12 " + rowClass(this.state.project)}>
                <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">

                    <div className="flex align-items-center justify-content-center">
                      <Link
                        to={{ pathname: "/project", state: this.state.project }}
                        style={{ textDecoration: "none" }}
                      >
                        <Chip
                          style={{ fontSize: "15px", fontStyle: "Manrope" }}
                          label={
                            this.state.project.projectName +
                            " (ID: " +
                            this.state.project.pruid +
                            ")"
                          }
                          className="mb-2 custom-chip-home"
                        />
                      </Link>
                    </div>

                  {this.state.project.areWeProceeding === "2" && (
                    <Chip
                      label={<b>{t("stopped")}</b>}
                      icon="pi pi-stop-circle"
                      style={{
                        fontSize: "15px",
                        position: "absolute",
                        zIndex: "3",
                        right: "10px",
                      }}
                      className="awpNo mr-2 mb-2 custom-chip-constant"
                    />
                  )}

                  {this.state.project.areWeProceeding === "3" && (
                    <Chip
                      label={<b>{t("pending")}</b>}
                      icon="pi pi-bell"
                      style={{
                        fontSize: "15px",
                        position: "absolute",
                        zIndex: "3",
                        right: "10px",
                      }}
                      className="awpPending mr-2 mb-2 custom-chip-constant"
                    />
                  )}

                  <div
                    className="col-12 mb-4"
                  >
                    <div className="grid">
                    {!isLimitedViewer() && (
                      <div className="col-12 md:col-6 lg:col-6">
                        <div className="p-float-label">
                          <Dropdown
                            id="eqBuildingName"
                            value={this.state.siteId}
                            options={this.state.sites}
                            onChange={(e) => this.showDataBySite(e, "siteId")}
                            optionLabel="name"
                            optionValue="id"
                            className="w-full"
                          />
                          <label htmlFor="eqBuildingName">
                            {t("filteredBySite")}
                          </label>
                        </div>
                      </div>
                    )}
                      <div className="col-12 md:col-6 lg:col-6">
                        <div className="p-float-label">
                          <Dropdown
                            id="eqBuildingZoneName"
                            value={this.state.zoneId}
                            options={this.state.zones}
                            onChange={(e) => this.showDataByZone(e, "zoneId")}
                            optionLabel="name"
                            optionValue="id"
                            className="w-full"
                          />
                          <label htmlFor="eqBuildingZoneName">
                            {t("filteredByZone")}
                          </label>
                        </div>
                      </div>

                      <div className="col-12 md:col-12 lg:col-12">
                        <center>
                          <strong>{this.state.filterTxt}</strong>
                        </center>
                      </div>
                    </div>
                  </div>

                  {!isLimitedViewer() && (
                  <div className="col-12 md:col-12 lg:col-12">
                    <center>
                      <div className="col-12 md:col-6 lg:col-6">
                        {this.state.dataOpt !== "" && this.state.dataOpt.labels.length > 0 && (
                          <>
                            <div className="mb-2" style={{ fontSize: "15px" }}>
                              <strong>{this.state.optDate}</strong>
                            </div>
                            <div className="mb-2">
                              <Line
                                options={optionsMUAX}
                                data={this.state.dataOpt}
                              />
                            </div>
                          </>
                        )}

                        {this.state.dataOpt === "" && this.state.filterTxt !== "" && (
                          <>
                            <div className="mb-2" style={{ fontSize: "15px" }}>
                            <strong>{this.state.optDate}</strong>
                            </div>
                            <div className="mb-2">
                            {t("hotWaterNotFound")}
                            </div>
                          </>
                        )}                        
                      </div>
                    </center>
                  </div>
                  )}

                  {this.state.zoneList &&
                    this.state.zoneList.map((z) => (
                      <>
                      
                        <div className="col-12 mb-6">
                          <div className="surface-0 shadow-1 p-3 border-1 border-50 border-round">
                          <div className="grid">
                            <div className="col-12 md:col-12 lg:col-12">
                              <div
                                style={{ fontSize: "18px", color: "#6e7073" }}
                              >
                                <strong>{z.zoneName}</strong>
                              </div>
                            </div>

                            {z.eqArr &&
                              z.eqArr.length > 0 &&
                              z.eqArr.map((eq) => (
                                <>
                                  <div className="col-12 md:col-6 lg:col-6">
                                    <Graphic
                                      eq={eq}
                                      options={options}
                                      options2={options2}
                                    />
                                  </div>
                                </>
                              ))}

                            {z.eqArr && z.eqArr.length < 1 && (
                              <>
                                <div className="col-12 md:col-6 lg:col-6">
                                <Trans i18nKey={"sensorNotFound"} />:

                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        </div>
                      </>
                    ))}
                </div>
              </div>

              <WaitDialog loading={this.state.loading} lang={t} />

              <ToastContainer
                autoClose={2200}
                position={toast.POSITION.TOP_CENTER}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick={true}
                rtl={false}
                theme="colored"
                pauseOnFocusLoss={true}
                draggable={false}
                pauseOnHover={true}
              />
            </BlockUI>
          </>
        )}
      </div>
    );
  }
}

export default withTranslation()(ProjectDashboard);
