import React, { useState } from 'react';
import SockJsClient from 'react-stomp';

const SOCKET_URL = 'https://heatics-staging.eco-wise.co.uk/ws-sensor';

const StompTest = () => {
    const [message, setMessage] = useState('You server message here.');
  
    let onConnected = () => {
      console.log("Connected!!")
    }
  
    let onMessageReceived = (msg) => {
        console.log(msg);
      setMessage(msg.co2+" -- "+msg.tsDate);
    }
  
    return (
      <div>
        <SockJsClient
          url={SOCKET_URL}
          topics={['/topic/message/A81758FFFE052AE8']}
          onConnect={onConnected}
          onDisconnect={console.log("Disconnected!")}
          onMessage={msg => onMessageReceived(msg)}
          debug={false}
        />
        <div>{message}</div>
      </div>
    );
  }

export default StompTest;