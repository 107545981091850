import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const DETECTION_OPTIONS = {
  order: ['localStorage', 'navigator'],
  caches: ['localStorage'],
  lookupQuerystring: 'lng'
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    //lng: 'en', --> Kapalı olması önemli.
    fallbackLng: 'en',
    debug: false,
    detection: DETECTION_OPTIONS,

    interpolation: {
      escapeValue: false,
    },

    backend: {
        loadPath: '/locales/{{lng}}/{{ns}}.json',
    },    
  });


export default i18n;