import React, { Component } from "react";

import { connect } from "react-redux";
import { setNewPassword } from "../actions/auth";

import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { Captcha } from "primereact/captcha";
import { withTranslation, Trans } from "react-i18next";
import WaitDialog from "../common/WaitDialog";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../resources/customToast.css";

class SetNewPassword extends Component {

  constructor(props) {
  
    super(props);

    this.handleNP = this.handleNP.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let seccode = params.get("secCode");
    let username = params.get("email");

    this.state = {
      response: "",
      username: username,
      seccode: seccode,
      newPassword: "",
      newPassword2: "",
      loading: false,
      successful: false,
    };

    this.recaptcha = null;
  }

  handleSubmit(e) {
    e.preventDefault();
  }

  handleNP() {
    this.setState({ loading: true });

    if (
      this.state.newPassword == null ||
      this.state.newPassword === "" ||
      this.state.newPassword2 == null ||
      this.state.newPassword2 === ""
    ) {
      this.setState({ loading: false, successful: false });
      toast.warning(<Trans i18nKey="warnSetNewPassword1"/>);
      this.recaptcha.reset();
      return;
    }

    if (this.state.newPassword.length < 8) {
      this.setState({ loading: false, successful: false });
      toast.warning(<Trans i18nKey="warnSetNewPassword2"/>);
      this.recaptcha.reset();
      return;
    }

    if (this.state.newPassword !== this.state.newPassword2) {
      this.setState({ loading: false, successful: false });
      toast.warning(<Trans i18nKey="warnSetNewPassword3"/>);
      this.recaptcha.reset();
      return;
    }

    if (this.state.response === "" || this.state.response === null) {
      this.setState({ loading: false });
      toast.warning(<Trans i18nKey="warnCaptcha"/>);
      return;
    }    

    const { dispatch } = this.props;

    dispatch(
      setNewPassword(
        this.state.username,
        this.state.seccode,
        this.state.newPassword,
        this.state.response
      )
    )
      .then(() => {
        this.setState({
          successful: true,
          loading: false,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
          successful: false,
        });
        toast.error(<Trans i18nKey={this.props.message}/>);
        this.recaptcha.reset();
      });
  }

  showResponse = (recaptcha) => {
    const response = recaptcha.response;
    this.setState({ response });
  };

  componentDidMount() {

    const { history } = this.props;

    if (
      this.state.seccode == null ||
      this.state.seccode === "" ||
      this.state.username == null ||
      this.state.username === ""
    ) {
      history.push("/forgotPassword");
      window.location.reload();
    }
  }

  render() {
    
    const { t, message } = this.props;

    return (
      <>
        <div className="flex align-items-center justify-content-center">
          <div className="surface-card p-4 shadow-2 border-round w-full lg:w-4">

            <div className="text-center text-900 text-xl font-medium">
              {t("txtSetNewPassword1")}
            </div>

            {!this.state.successful && (
              <>
                <div className="text-center mb-5">
                  <span className="text-600 font-medium line-height-3">
                    {t("txtSetNewPassword2")}
                  </span>
                </div>
                <div className="mb-4">
                  <form onSubmit={this.handleSubmit}>
                    <label
                      htmlFor="newPassword"
                      className="block text-900 font-medium mb-2"
                    >
                      {t("newPassword")}
                    </label>
                    <Password
                      className="w-full p-inputtext-sm block mb-2"
                      id="newPassword"
                      value={this.state.newPassword}
                      onChange={(e) =>
                        this.setState({ newPassword: e.target.value })
                      }
                      toggleMask
                    />

                    <label
                      htmlFor="newPassword2"
                      className="block text-900 font-medium mb-2"
                    >
                      {t("newPassword")} ({t("again")})
                    </label>
                    <Password
                      className="w-full p-inputtext-sm block mb-5"
                      id="newPassword2"
                      feedback={false}
                      value={this.state.newPassword2}
                      onChange={(e) =>
                        this.setState({ newPassword2: e.target.value })
                      }
                      toggleMask
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}
                    >
                      <Captcha
                        ref={(recaptcha) => (this.recaptcha = recaptcha)}
                        siteKey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                        onResponse={this.showResponse}
                      />
                    </div>
                    <br/>                    
                    <Button
                      label={t("changePassword")}
                      icon="pi pi-lock"
                      className="w-full p-button-mb mb-4"
                      onClick={() => this.handleNP()}
                    />
                  </form>
                </div>

                <div className="text-center mb-2">
                  <a
                    className="font-medium no-underline ml-2 text-blue-500 cursor-pointer"
                    href="/login"
                  >
                    {t("backToLogin")}
                  </a>
                </div>

                <WaitDialog loading={this.state.loading} lang={t} />

                <ToastContainer
                  autoClose={2200}
                  position={toast.POSITION.TOP_CENTER}
                  hideProgressBar={true}
                  newestOnTop={false}
                  closeOnClick={true}
                  rtl={false}
                  theme="colored"
                  pauseOnFocusLoss={true}
                  draggable={false}
                  pauseOnHover={true}
                />
              </>
            )}

            {message && this.state.successful && (
              <>
                <div className="form-group text-center mb-6">
                  <div
                    className={
                      this.state.successful
                        ? "alert alert-success"
                        : "alert alert-danger"
                    }
                    role="alert"
                  >
                    <center>{t(message)}</center>
                  </div>
                </div>
                <div className="text-center mb-2">
                  <a
                    className="font-medium no-underline ml-2 text-blue-500 cursor-pointer"
                    href="/login"
                  >
                    {t("backToLogin")}
                  </a>
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { message } = state.message;
  return {
    message,
  };
}

export default withTranslation()(connect(mapStateToProps)(SetNewPassword));
