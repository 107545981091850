import React, { Component } from "react";
import { Button } from "primereact/button";
import { Chip } from 'primereact/chip';
import { Line } from "react-chartjs-2";
import { withTranslation, Trans } from "react-i18next";
import { connect } from "react-redux";
import { t } from "i18next";
import SockJsClient from 'react-stomp';
import { diffMinutes, roundToTwo } from "../common/functions";

const SOCKET_URL = 'https://heatics-staging.eco-wise.co.uk/ws-sensor';

class Graphic extends Component {

  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      eq: "",
      onDataReceived: "0"
    };
  }

  componentDidMount() {
    const { eq, options, options2 } = this.props;
    this.setState({ eq });
  }

  componentDidUpdate(prevProps, prevState) {
    const { eq } = this.props;
    if (prevProps.eq !== this.props.eq) {
      this.setState({ eq });
    }
  }

  onConnected = () => {
    
  }

  onDisconnected = () => {
    
  }  

  onMessageReceived = (msg) => {

      this.setState(prevState => ({
        eq: {                   
            ...prevState.eq,    
            co2: msg.co2,
            ts: msg.receivedAt,
            tem: roundToTwo(msg.temperature),
            hum: roundToTwo(msg.humidity),
            diff: diffMinutes(msg.receivedAt),
            //data: [...prevState.eq.data[0].data, roundToTwo(msg.temperature)],
            //data2: [...prevState.eq.data2[0].data, roundToTwo(msg.humidity)],
            //data3: [...prevState.eq.data3[0].data, msg.co2],
            //labels: [...prevState.eq.data.labels, msg.receivedAt],
        },
        onDataReceived: "1"
    }))      
    
    this.change = setTimeout(() => {
      this.setState({onDataReceived: "0"})
    }, 900)    
  }

  render() {
    const { options, options2, t } = this.props;
    const { eq, visible } = this.state;

    console.log(eq);
    return (
      <>
        <SockJsClient
          url={SOCKET_URL}
          topics={['/topic/message/'+eq.eqEui]}
          onConnect={this.onConnected()}
          onDisconnect={this.onDisconnected()}
          onMessage={msg => this.onMessageReceived(msg)}
          debug={false}
        />      
        <div
          className="surface-0 shadow-2 p-3 border-1 border-50 border-round"
          style={{ minHeight: 310 }}
        >
        <div
          style={{ 
            backgroundColor: this.state.onDataReceived == 0 ? "" : "yellow"
          }}
        >      
          <div class="d-flex justify-content-between mb-0">
            <div style={{ fontSize: "11px" }}>
              {t("sensorName")}: <strong>{eq.eqName}</strong>
            </div>
            <div style={{ fontSize: "11px" }}>
              {t("sensorId")}: <strong>{eq.eqEui}</strong>
            </div>            
          </div>
          <div class="d-flex justify-content-between mb-6">
          <div style={{ fontSize: "11px" }}>
              {t("sensorType")}: <strong>{eq.eqType}</strong>
            </div>
            <div style={{ fontSize: "11px" }}>
              {(eq?.diff === "" || eq?.diff == null || eq?.diff >= 360) && (
              <>
              <font color="red">
              {t("lastDataTime")}: <strong>
                {eq.ts}       
              </strong>
              </font>     
              </>
              )}
              {(eq?.diff < 360) && (
              <>
              {t("lastDataTime")}: <strong>
                {eq.ts}    
              </strong>
              </>
              )}              
            </div>
          </div>          

          <div class="d-flex justify-content-around mb-4">
            {(eq.tem != null && eq.tem !== "") && (
            <div>
              <div style={{ fontSize: "12px" }}><center>{t("temperature")} (°C)</center></div>
              <div style={{ fontSize: "36px" }}><center>{eq.tem}</center></div>
              <div>
                <center>
                {(eq.tem < 15) && (<Chip label={t("cold")} style={{color: "#ffffff", background: "#2E67C8"}} />)}
                {(eq.tem >= 15) && (eq.tem < 18) && (<Chip label={t("cool")} style={{color: "#ffffff", background: "#89A3D0"}} />)}
                {(eq.tem >= 18) && (eq.tem < 26) && (<Chip label={t("fine")} style={{color: "#ffffff", background: "#68C29F"}} />)}
                {(eq.tem >= 26) && (eq.tem < 28) && (<Chip label={t("warm")} style={{color: "#ffffff", background: "#ED7070"}} />)}
                {(eq.tem >= 28) && (<Chip label={t("hot")} style={{color: "#ffffff", background: "#E53232"}} />)}
                </center>
              </div>
            </div>
            )}
            {(eq.hum != null && eq.hum !== "") && (
            <div>
              <div style={{ fontSize: "12px" }}><center>{t("humidity")} (%)</center></div>
              <div style={{ fontSize: "36px" }}><center>{eq.hum}</center></div>
              <div>
                <center>
                {(eq.hum < 20) && (<Chip label={t("tooDry")} style={{color: "#ffffff", background: "#ED7070"}} />)}
                {(eq.hum >= 20) && (eq.hum < 30) && (<Chip label={t("dry")} style={{color: "#ffffff", background: "#F6C67C"}} />)}
                {(eq.hum >= 30) && (eq.hum < 60) && (<Chip label={t("fine")} style={{color: "#ffffff", background: "#68C29F"}} />)}
                {(eq.hum >= 60) && (<Chip label={t("humid")} style={{color: "#ffffff", background: "#89A3D0"}} />)}
                </center>
              </div>              
            </div>
            )}
            {(eq.co2 != null && eq.co2 !== "") && (
            <div>
              <div style={{ fontSize: "12px" }}><center>CO₂ (ppm)</center></div>
              <div style={{ fontSize: "36px" }}><center>{eq.co2}</center></div>
              <div>
                <center>
                {(eq.co2 < 1000) && (<Chip label={t("fine")} style={{color: "#ffffff", background: "#68C29F"}} />)}
                {(eq.co2 >= 1000) && (eq.co2 < 1200) && (<Chip label={t("fair")} style={{color: "#ffffff", background: "#89A3D0"}} />)}
                {(eq.co2 >= 1200) && (eq.co2 < 1400) && (<Chip label={t("moderate")} style={{color: "#ffffff", background: "#F5E852"}} />)}
                {(eq.co2 >= 1400) && (eq.co2 < 1600) && (<Chip label={t("poor")} style={{color: "#ffffff", background: "#F6C67C"}} />)}
                {(eq.co2 >= 1600) && (<Chip label={t("bad")} style={{color: "#ffffff", background: "#ED7070"}} />)}
                </center>
              </div>                 
            </div> 
            )}           
          </div>

          <div style={{ fontSize: "11px"}}>
          {eq?.diff != null && (
            
            <>
            <br/>
            <center><i>
            {(eq.tem < 15) && (<div>* {t("dashboardGraphTextTemp1")}</div>)}
            {(eq.tem >= 15) && (eq.tem < 18) && (<div>* {t("dashboardGraphTextTemp2")}</div>)}
            {(eq.tem >= 26) && (eq.tem < 28) && (<div>* {t("dashboardGraphTextTemp3")}</div>)}
            {(eq.tem >= 28) && (<div>* {t("dashboardGraphTextTemp4")}</div>)}   
            {(eq.hum < 20) && (<div>* {t("dashboardGraphTextHum1")}</div>)}
            {(eq.hum >= 20) && (eq.hum < 30) && (<div>* {t("dashboardGraphTextHum2")}</div>)}
            {(eq.hum >= 60) && (<div>* {t("dashboardGraphTextHum3")}</div>)}
            </i></center>         
            </>
          )}
          </div>
          
          <div class="d-flex justify-content-around">
            <Button
              tooltip={this.state.visible === false ? t("showMore") : t("showLess")}
              icon={
                this.state.visible === false
                  ? "pi pi-chevron-down"
                  : "pi pi-chevron-up"
              }
              className="p-button-rounded p-button-text p-button-sm"
              onClick={(e) => {
                this.setState({ visible: !visible });
              }}
            />
          </div>

          {visible && (
            <>
              <br />

              {/*Temp Graph*/}
              {eq.tem != null && (
                <>              
                  <div className="mb-2">
                    <Line options={options2} data={eq.data2} />
                  </div>
                  
                  <hr
                    style={{
                      width: "100%",
                      height: 5,
                      color: "black",
                    }}
                  />
              </>
              )}

              {/*Humidity Graph*/}
              {eq.hum != null && (
                <>   
                <div className="mb-2">
                  <Line options={options} data={eq.data} />
                </div>

                <hr
                style={{
                  width: "100%",
                  height: 5,
                  color: "black",
                }}
                />   
              </>
              )}

              {/*CO2 Graph*/}
              {eq.co2 != null && (
                <>   
                <div className="mb-2">
                  <Line options={options} data={eq.data3} />
                </div> 
              </>
              )}              

            </>
          )}
          </div>    
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  const { message } = state.message;
  return {
      message,
  };
}

export default withTranslation()(connect(mapStateToProps)(Graphic));
