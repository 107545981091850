import axios from "axios";
import apios from "../common/apios";

const API_URL = process.env.REACT_APP_SERVER_ADDRESS+"/api/auth/";

class AuthService {
  login(username, password, responseCaptcha) {
    return apios
      .post("auth/signin", { username, password, responseCaptcha })
      .then((response) => {
        if (response.data.token) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  forgotPassword(username, responseCaptcha) {
    return apios.post("auth/passwordResetRequest", {
      username, responseCaptcha
    });
  }

  //alttaki setNewPassword ve verify Email tekrar düzenlenecek.
  setNewPassword(username, seccode, password, responseCaptcha) {
    return axios.post(API_URL + "setNewPassword", {
      username,
      password,
      seccode,
      responseCaptcha
    });
  }

  verifyEmail(email, secCode) {
    return axios.get(API_URL + "verifyEmail", {
      params: {
        email: email,
        secCode: secCode,
      },
    });
  }

  resendVerifyEmail(username, responseCaptcha) {
    return apios.post("auth/mailResendRequest", {
      username, responseCaptcha
    });
  }

  logout() {
    localStorage.removeItem("user");
  }

  register(username, password, firstName, lastName, newsletter, responseCaptcha) {
    return apios.post("auth/register", {
      username,
      password,
      firstName,
      lastName,
      newsletter,
      responseCaptcha
    });
  }
}

export default new AuthService();
