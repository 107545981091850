import React from "react";
import { withTranslation } from "react-i18next";
import { Dialog } from "primereact/dialog";

const WaitDialogDocument = ({ loading, t }) => {

  return (
    <Dialog
      header={t("loading")}
      modal={true}
      resizable={false}
      draggable={false}
      closeOnEscape={false}
      closeable={false}
      visible={loading}
      breakpoints={{ "960px": "75vw" }}
      style={{ width: "30vw" }}
    >
      <center>
        {t("pleaseDocumentWait")}...
        <br />
        <img src="/assets/img/loading.gif" alt={t("pleaseDocumentWait")} />
      </center>
    </Dialog>
  );
};

export default withTranslation()(WaitDialogDocument);