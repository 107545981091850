import React, { Component } from "react";

import { ScrollTop } from "primereact/scrolltop";
import { Button } from "primereact/button";

import WaitDialog from "../common/WaitDialog";
import { withTranslation, Trans } from "react-i18next";
import { connect } from "react-redux";

import "primereact/resources/themes/md-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

import SysService from "../services/sys.service";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../resources/customToast.css";

import { isAdmin, isLimitedViewer, isPlatformManager, isProjectManager, isUser, isViewer } from "../common/token-check";
import MapComponent from "../common/map";

import { Redirect, Link } from "react-router-dom";
import { t } from "i18next";



class Home extends Component {
  emptyParam = {
    id: 3
  };

  constructor(props) {

    super(props);

    this.state = {
      user: "",
      loading: false,
      blockedPanel: false,
      sites: [],
      projects: [],

      data: { id: 3 },


    };

  }


  componentDidMount() {

    SysService.getProject(3).then(
      (response) => {
        this.setState({
          project: response.data,
          loading: false,
          blockedPanel: false,
        });

      },
      (error) => {
        this.setState({
          loading: false,
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString(),
          blockedPanel: true,
        });

        toast.error(this.state.content);

        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;
            case 403:
              window.location.href = "/403";
              break;
            case 404:
              window.location.href = "/404";
              break;
            default:
              break;
          }
        }
      }
    );



    if (isProjectManager() || isPlatformManager()) {

      SysService.getSites().then(
        (response) => {
          this.setState({
            sites: response.data,
            loading: false,
            blockedPanel: false,
          });

        },

        (error) => {

          this.setState({
            loading: false,
            content:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.timeoutErrorMessage ||
              error.toString(),
            blockedPanel: true,
          });

          toast.error(<Trans i18nKey={this.state.content} />);
        }
      );


    } else if (isUser()) {

    }
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }
  projectDashboard = (data, props) => {
    return (
      <div>

        <Link to={{ pathname: "/projectDashboard", state: data }} style={{ textDecoration: "none" }}>
          <Button
            icon="pi pi-chart-bar"
            tooltip={t("dashboard")}
            className="p-button-rounded p-button-text"

          />
        </Link>

      </div>
    );
  }


  setIdParam(data) {
    let param = {
      ...this.emptyParam,
      id: data.pojoProjectId
    };

    return param;
  }


  render() {
    const { t } = this.props;

    return (
      <div>
        <ScrollTop />

        {isAdmin() && (
          <>
            <div className="grid">
              <div className="col-12 md:col-6 lg:col-3">
                <div
                  className="surface-0 shadow-2 p-3 border-1 border-50 border-round"
                  style={{ minHeight: 250 }}
                >


                </div>
              </div>

              <div className="col-12 md:col-6 lg:col-3">
                <div
                  className="surface-0 shadow-2 p-3 border-1 border-50 border-round"
                  style={{ minHeight: 250 }}
                >

                </div>
              </div>

              <div className="col-12 md:col-6 lg:col-3">
                <div
                  className="surface-0 shadow-2 p-3 border-1 border-50 border-round"
                  style={{ minHeight: 250 }}
                >

                </div>
              </div>

              <div className="col-12 md:col-6 lg:col-3">
                <div
                  className="surface-0 shadow-2 p-3 border-1 border-50 border-round"
                  style={{ minHeight: 250 }}
                >

                </div>
              </div>

            </div>
            <br />
          </>
        )}

        {(isPlatformManager() || isProjectManager()) && (


          <>

            <div className="grid">
              <div className="col-12 md:col-6 lg:col-12">
                <div
                  className="surface-0 shadow-2 p-3 border-1 border-50 border-round"
                  style={{ minHeight: 250 , zIndex: 0 ,position:"relative"}}
                >
                  {this.state?.sites.length > 0 && (
                    <MapComponent data={this.state.sites} heightVal="70vh"
                    />
                  )}

                </div>
              </div>
            </div>
            <br />

          </>


        )}

        {(isViewer() || isLimitedViewer()) && (
          <>

            <Redirect to={{ pathname: "/projectDashboard", state: this.state.data }} />

          </>
        )
        }

        <WaitDialog loading={this.state.loading} lang={t} />

        <ToastContainer
          autoClose={2200}
          position={toast.POSITION.TOP_CENTER}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick={true}
          rtl={false}
          theme="colored"
          pauseOnFocusLoss={true}
          draggable={false}
          pauseOnHover={true}
        />


        <br />
      </div >
    );
  }
}
function mapStateToProps(state) {
  const { message } = state.message;
  return {
    message,
  };
}

export default withTranslation()(connect(mapStateToProps)(Home));