import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { withTranslation } from "react-i18next";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { Trans } from "react-i18next";
//import provideReason from "../common/ccs_provideReason";
import areWeProceeding from "../common/ccs_areWeProceeding";
import phaseRS from "../common/ccs_phases";
import SysService from "../services/sys.service";
import { isProjectManager } from "../common/token-check";

class SidebarRight extends Component {

  emptyProjectRS = {
    projectId: "",
    areWeProceeding: "",
    provideReason: "",
    notes: "",
    phaseRS: ""
  };

  constructor(props) {
    super(props);

    this.state = {

      projectRS: this.emptyProjectRS,

      saveButtonStatus: false,

    };

  }

  saveButton() {

    if (isProjectManager()) {
      this.setState({
        loading: true,

      });

      /////////////////////

      this.state.projectRS.projectId = this.props.projectId;

      SysService.updateProjectRS(this.state.projectRS).then(
        (response) => {
          this.setState({
            projectRS: response.data,
            loading: false,
            saveButtonStatus: false
          });

          toast.success(<Trans i18nKey="saved" />);
        },
        (error) => {
          this.setState({
            loading: false,
            content:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString(),
          });

          toast.error(<Trans i18nKey={error.response} />);

          if (
            error.response &&
            (error.response.status === 401 ||
              error.response.status === 403 ||
              error.response.status === 404)
          ) {
            switch (error.response.status) {
              case 401:
                window.location.href = "/401";
                break;
              case 403:
                window.location.href = "/403";
                break;
              case 404:
                window.location.href = "/404";
                break;
              default:
                break;
            }
          }
        }
      );

    }

    window.scrollTo(0, 0);
  }

  onInputChangeRightSidebar = (e, name) => {

    const val = (e.target && e.target.value) || "";
    let projectRS = { ...this.state.projectRS };
    projectRS[`${name}`] = val;

    this.setState({
      projectRS,
      saveButtonStatus: true,
    });

  };

  componentDidMount() {

    SysService.getProject(this.props.projectId).then(
      (response) => {
        this.setState({
          projectRS: response.data,
          loading: false,
          blockedPanel: false,
        });

      },
      (error) => {
        this.setState({
          loading: false,
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString(),
          blockedPanel: true,
        });

        toast.error(this.state.content);        

      })

  }

  render() {

    const { visible, setState, t} = this.props;

    return (
      <Sidebar
        style={{ width: 290 }}
        visible={visible}
        position="right"
        onHide={() => {
          setState({ visibleRight: false });
        }}
      >
        <>
          <Button
            style={{
              right: "290px",
              position: "fixed",
              fontSize: "14px",
              top: "308px",
            }}
           

            icon="pi pi-angle-right text-3xl"
            iconPos="center"
            onClick={() => setState({ visibleRight: false })}
          />

          <h3>Proceeding Status</h3>

          <br />

          {/*areWeProceeding*/}
          <div className="mb-1">
            <label htmlFor="areWeProceeding" className="platformColor">
              {t("areWeProceeding")}
            </label>

            <Dropdown
              id="areWeProceeding"
              options={areWeProceeding}
              value={this.state.projectRS.areWeProceeding}
              placeholder= {t("pleaseSelect")}
              onChange={(e) =>
                this.onInputChangeRightSidebar(e, "areWeProceeding")
              }
              optionLabel="name"
              optionValue="code"
              className="w-full"
            />
          </div>
          <br />

          {/*phaseRS*/}
          <div className="mb-1">
            <label htmlFor="phaseRS" className="platformColor" >
              {t("phaseRS")}
            </label>

            <Dropdown
              id="phaseRS"
              options={phaseRS}
              value={this.state.projectRS.phaseRS}
              placeholder= {t("pleaseSelect")}
              onChange={(e) =>
                this.onInputChangeRightSidebar(e, "phaseRS")
              }
              optionLabel="name"
              optionValue="code"
              className="w-full"
            />
          </div>
          <br />


          {/*provideReason*/}
          <div className="mb-1">
            <label htmlFor="provideReason" className="platformColor">
              {t("provideReason")}
            </label>
            <div className="mb-1">
              <Dropdown                id="provideReason"
                //options={provideReason}
                //value={this.state.projectRS.provideReason}
                placeholder= {t("pleaseSelect")}
                onChange={(e) =>
                  this.onInputChangeRightSidebar(e, "provideReason")
                }
                optionLabel="name"
                optionValue="code"
                className="w-full"
              />
            </div>
          </div>

          <br />

          {/*notes*/}
          <div className="mb-1">
            <label htmlFor="notes" className="platformColor">
              {t("notes")}
            </label>

            <InputTextarea
              //readOnly={this.state.nonEditAccess}
              // disabled={this.state.nonEditAccess}
              id="notes"              value={this.state.projectRS.notes}
              placeholder= {t("pleaseEnter")}
              onChange={(e) => this.onInputChangeRightSidebar(e, "notes")}
              rows={3}
              cols={20}
              maxLength={1000}
              className="w-full"
            />
          </div>

          <Button
            disabled={!this.state.saveButtonStatus}            //icon="pi pi-check-circle"
            label="Save"
            className="p-button-sm p-button-mb"
            //onClick={() => this.saveButton()}
           onClick={() => {setState({ visibleRight: false }); this.saveButton() ;}}
          />
        </>


      </Sidebar>
    );
  }
}

export default withTranslation()(SidebarRight);
