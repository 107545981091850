import { ADD_ROUTE, DELETE_ROUTE } from "../actions/types";

const routeReducerDefaultState = {};

const route = (state = routeReducerDefaultState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ADD_ROUTE:
      return { route: payload };
    case DELETE_ROUTE:
      return { route: "" };
    default:
      return state;
  }
};

export default route;
