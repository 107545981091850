import jwt_decode from "jwt-decode";

const TOKEN_KEY = "user";

export const isLogin = () => {
  if (localStorage.getItem(TOKEN_KEY)) {
    const user = JSON.parse(localStorage.getItem(TOKEN_KEY));

    if (user) {
      const decodedJwt = jwt_decode(user.token);

      if (decodedJwt.exp * 1000 < Date.now()) {
        return false;
      }
    } else {
      return false;
    }

    return true;
  }

  return false;
};

export const isAdmin = () => {
  if (localStorage.getItem(TOKEN_KEY)) {
    const user = JSON.parse(localStorage.getItem(TOKEN_KEY));

    if (user) {
      const decodedJwt = jwt_decode(user.token);

      if (decodedJwt.exp * 1000 < Date.now()) {
        return false;
      }

      if (decodedJwt.roles.includes("ROLE_ADMIN")) {
        return true;
      }
      return false;
    } else {
      return false;
    }
  }

  return false;
};

export const isPlatformManager = () => {
  if (localStorage.getItem(TOKEN_KEY)) {
    const user = JSON.parse(localStorage.getItem(TOKEN_KEY));

    if (user) {
      const decodedJwt = jwt_decode(user.token);

      if (decodedJwt.exp * 1000 < Date.now()) {
        return false;
      }

      if (decodedJwt.roles.includes("ROLE_PLATFORM_MANAGER")) {
        return true;
      }
      return false;
    } else {
      return false;
    }
  }

  return false;
};

export const isProjectManager = () => {
  if (localStorage.getItem(TOKEN_KEY)) {
    const user = JSON.parse(localStorage.getItem(TOKEN_KEY));

    if (user) {
      const decodedJwt = jwt_decode(user.token);

      if (decodedJwt.exp * 1000 < Date.now()) {
        return false;
      }

      if (decodedJwt.roles.includes("ROLE_PROJECT_MANAGER")) {
        return true;
      }
      return false;
    } else {
      return false;
    }
  }

  return false;
};

export const isUser = () => {
  if (localStorage.getItem(TOKEN_KEY)) {
    const user = JSON.parse(localStorage.getItem(TOKEN_KEY));

    if (user) {
      const decodedJwt = jwt_decode(user.token);

      if (decodedJwt.exp * 1000 < Date.now()) {
        return false;
      }

      if (decodedJwt.roles.includes("ROLE_USER")) {
        return true;
      }
      return false;
    } else {
      return false;
    }
  }

  return false;
};

export const isViewer = () => {
  if (localStorage.getItem(TOKEN_KEY)) {
    const user = JSON.parse(localStorage.getItem(TOKEN_KEY));

    if (user) {
      const decodedJwt = jwt_decode(user.token);

      if (decodedJwt.exp * 1000 < Date.now()) {
        return false;
      }

      if (decodedJwt.roles.includes("ROLE_VIEWER")) {
        return true;
      }
      return false;
    } else {
      return false;
    }
  }

  return false;
};

export const isLimitedViewer = () => {
  if (localStorage.getItem(TOKEN_KEY)) {
    const user = JSON.parse(localStorage.getItem(TOKEN_KEY));

    if (user) {
      const decodedJwt = jwt_decode(user.token);

      if (decodedJwt.exp * 1000 < Date.now()) {
        return false;
      }

      if (decodedJwt.roles.includes("ROLE_VIEWER_LIMITED")) {
        return true;
      }
      return false;
    } else {
      return false;
    }
  }

  return false;
};
