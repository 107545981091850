import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { BlockUI } from "primereact/blockui";
import WaitDialog from "../common/WaitDialog";
import { t } from "i18next";

import { Link } from "react-router-dom";

import "primereact/resources/themes/lara-light-teal/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

import { isPlatformManager, isUser } from "../common/token-check";

import { connect } from "react-redux";

import { addNewApiKey } from "../actions/sys";
import { deleteApiKey } from "../actions/sys";
import SysService from "../services/sys.service";
import apiKeyType from "../common/ccs_apiKeyType";

import { withTranslation, Trans } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../resources/customToast.css";


class APIKeys extends Component {

  emptyAPIKey = {
    apiKeyType: ""
  };

  constructor(props) {

    super(props);

    this.state = {
      content: "",
      newAPIKey: this.emptyAPIKey,
      submitted: false,
      visible: false,

      rejectConfirmVisible: false,

      apiKeys: [],
      selectedAPIKey: null,
      filters1: null,
      globalFilterValue1: "",
      loading: true,
      successful: false,
      blockedPanel: false,
      addApiKeyDialogVisible: false,


    };

    this.accept = this.accept.bind(this);
    this.reject = this.reject.bind(this);
    this.onGlobalFilterChange1 = this.onGlobalFilterChange1.bind(this);
    this.createNew = this.createNew.bind(this);
    this.hideDialog = this.hideDialog.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.actionTemplate = this.actionTemplate.bind(this);

  }

  handleDeleteAPIKey(data) {

    this.setState({
      selectedAPIKey: data,
      rejectConfirmVisible: true,
    });
  }

  deleteApiKey = () => {

    this.setState({ loading: true });

    this.props
      .dispatch(deleteApiKey(this.state.selectedAPIKey.id))
      .then(() => {
        this.setState({
          loading: false,
          acceptConfirmVisible: false,
        });

        toast.success(<Trans i18nKey={this.props.message} />);
        this.componentDidMount();

      })
      .catch(() => {
        this.setState({
          loading: false,
        });

        toast.error(<Trans i18nKey={this.props.message} />);

      });
  }

  accept() {

    if (

      this.state.newAPIKey.apiKeyType == null ||
      this.state.newAPIKey.apiKeyType.trim() === ""
    ) {
      this.setState({ loading: false });
      toast.warning(<Trans i18nKey="warnMandatory" />);
      return;
    }

    this.setState({
      successful: false,
      loading: true,
      visible: false
    });


    this.props
      .dispatch(addNewApiKey(this.state.newAPIKey))
      .then(() => {
        this.setState({
          successful: true,
          loading: false,
          newAPIKey: this.emptyAPIKey,
          submitted: false,
          visible: false,
          apiKeyDialog: false,
        });

        toast.success(<Trans i18nKey={this.props.message} />);

        this.componentDidMount();
      })
      .catch(() => {
        this.setState({
          successful: false,
          loading: false,
        });

        toast.error(<Trans i18nKey={this.props.message} />);
      });
  }

  reject() { }

  componentDidMount() {

    this.setState({ loading: true });

    if (isPlatformManager()) {
      SysService.getApiKeys().then(
        (response) => {
          console.log(response.data);
          this.setState({
            apiKeys: response.data,
            loading: false,
            blockedPanel: false,
          });
        },
        (error) => {
          this.setState({
            loading: false,
            content:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.timeoutErrorMessage ||
              error.toString(),
            blockedPanel: true,
          });

          toast.error(<Trans i18nKey={this.state.content} />);

          if (
            error.response &&
            (error.response.status === 401 ||
              error.response.status === 403 ||
              error.response.status === 404)
          ) {
            switch (error.response.status) {
              case 401:
                window.location.href = "/401";
                break;
              case 403:
                window.location.href = "/403";
                break;
              case 404:
                window.location.href = "/404";
                break;
              default:
                break;
            }
          }
        }
      );
    }

    this.initFilters1();
  }

  createNew() {
    this.setState({
      newAPIKey: this.emptyAPIKey,
      submitted: false,
      apiKeyDialog: true,
    });
  }

  hideDialog() {
    this.setState({
      submitted: false,
      apiKeyDialog: false,
    });
  }

  onInputChange(e, name) {
    const val = (e.target && e.target.value) || "";
    let newAPIKey = { ...this.state.newAPIKey };
    newAPIKey[`${name}`] = val;
    this.setState({ newAPIKey });
  }


  onGlobalFilterChange1(e) {
    const value = e.target.value;
    let filters1 = { ...this.state.filters1 };
    filters1["global"].value = value;
    this.setState({ filters1, globalFilterValue1: value });
  }

  initFilters1() {
    this.setState({
      filters1: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      globalFilterValue1: "",
    });
  }

  renderHeaderTableTop(t) {
    return (
      <div className="flex justify-content-between">
        <span>{t("apiKeys")}</span>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            className="p-inputtext-sm block mb-2"
            value={this.state.globalFilterValue1}
            onChange={this.onGlobalFilterChange1}
            placeholder={t("search")}
          />
        </span>
      </div>
    );
  }

  renderKeyType(data, props) {
    console.log(data.apiKeyType);

    return (
      <Trans i18nKey={("apiKeyType" + data.apiKeyType)} />
    );
  }


  handleDeleteAPIKeys = (rowData) => {
    const index = this.state.eqs.indexOf(rowData);
    if (index > -1) {
      this.state.eqs.splice(index, 1);
    }

    this.setState({
      newAPIKey: this.emptyAPIKey,
      addApiKeyDialogVisible: false,
      saveButtonStatus: true,
    });
  };


  actionTemplate(data, props) {
    return (
      <div>

        {isPlatformManager() && (
          <>

            <Button
              icon="pi pi-trash"
              href
              tooltip={t("deleteApikey")}
              className="p-button-rounded p-button-text"
              onClick={(e) => {
                this.handleDeleteAPIKey(data, props);
              }}
            />
          </>
        )}
      </div>
    );
  }
  deleteAPIKeysMessage() {
    return (
      <div className="flex justify-content-between">
        <label><Trans i18nKey={("confirmDeleteApiKeyMessage")} />
          <br />
          <b><Trans i18nKey={("confirmDeleteApiKeyMessage2")} /></b></label>
      </div>
    );
  }

  render() {

    const { t } = this.props;
    this.state.t = t;

    const headerTable = this.renderHeaderTableTop(t);

    const apiKeyDialogFooter = (
      <React.Fragment>
        <Button
          label={t("cancel")}
          icon="pi pi-times"
          className="p-button-text platformColor"
          onClick={this.hideDialog}
        />
        <Button
          label={t("save")}
          icon="pi pi-save"
          className="p-button-mb"
          onClick={() => this.setState({ visible: true })}
        />

        <ConfirmDialog
          visible={this.state.visible}
          onHide={() => {
            this.setState({ visible: false });
          }}
          message={t("confirmMessage")}
          header={t("confirm")}
          icon="pi pi-exclamation-triangle"
          accept={this.accept}
          reject={this.reject}
          acceptLabel={t("yes")}
          rejectLabel={t("no")}
        />
      </React.Fragment>
    );


    return (

      <div>
        {!isUser() && (
          <>
            <BlockUI blocked={this.state.blockedPanel} fullscreen>
              {(isPlatformManager()) && (
                <div className="field col-12 mb-0">
                  <Button
                    label={t("addNewApiKey")}
                    icon="pi pi-plus"
                    className="p-button-mb p-button-sm mr-2"
                    onClick={this.createNew}
                  />
                </div>
              )}

              <div className="col-12">
                <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                  <DataTable
                    name="dt"
                    size="small"
                    showGridlines
                    selectionMode="single"
                    value={this.state.apiKeys}
                    dataKey="id"
                    stripedRows
                    responsiveLayout="scroll"
                    emptyMessage={t("noApiKeyFound")}
                    header={headerTable}
                    paginator
                    rows={10}
                    filters={this.state.filters1}
                    filterDisplay="menu"
                    loading={this.state.loading}
                    globalFilterFields={[
                      "apiKey",
                      "apiKeyType"
                    ]}
                  >

                    <Column
                      field="apiKey"
                      header={t("apiKey")}
                      sortable
                    ></Column>

                    <Column
                      body={this.renderKeyType}
                      header={t("apiKeyType")}
                      sortable
                    ></Column>

                    <Column
                      header={t("action")}
                      sortable
                      body={this.actionTemplate}
                    ></Column>

                  </DataTable>
                </div>
              </div>

              <Dialog
                visible={this.state.apiKeyDialog}
                style={{ width: "450px" }}
                header={t("apiKeyDetails")}
                modal
                className="p-fluid"
                footer={apiKeyDialogFooter}
                onHide={this.hideDialog}
              >


                <div className="p-float-label">
                  <Dropdown
                    id="apiKeyType"
                    //value={...this.state.newAPIKey.apiKeyType == "1 ? {"Programmatic"} : {"Other"}}
                    value={this.state.newAPIKey.apiKeyType}
                    options={apiKeyType}
                    onChange={(e) => this.onInputChange(e, "apiKeyType")}
                    optionLabel="name"
                    optionValue="code"
                  />
                  <label htmlFor="apiKeyType" className="platformColor">{t("apiKeyType")} (*)</label>
                </div>



              </Dialog>

              <ConfirmDialog
                visible={this.state.rejectConfirmVisible}
                onHide={() => {
                  this.setState({ rejectConfirmVisible: false });
                }}
                message={this.deleteAPIKeysMessage}
                header={t("confirm")}
                icon="pi pi-exclamation-triangle icon-red"
                acceptClassName='p-button-danger'

                accept={this.deleteApiKey}
                reject={this.reject}
                acceptLabel={t("yes")}
                rejectLabel={t("no")}
              />

              <WaitDialog loading={this.state.loading} lang={t} />

              <ToastContainer
                autoClose={2200}
                position={toast.POSITION.TOP_CENTER}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick={true}
                rtl={false}
                theme="colored"
                pauseOnFocusLoss={true}
                draggable={false}
                pauseOnHover={true}
              />
            </BlockUI>
          </>
        )}
      </div>

    );

  }

}

function mapStateToProps(state) {
  const { message } = state.message;
  return {
    message,
  };
}

export default withTranslation()(connect(mapStateToProps)(APIKeys));