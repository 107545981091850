import React, { Component } from "react";

import "primereact/resources/themes/lara-light-teal/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

import { Card } from "primereact/card";
import { Chip } from "primereact/chip";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import { Steps } from "primereact/steps";
import { InputText } from "primereact/inputtext";
import { ConfirmDialog } from "primereact/confirmdialog";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { TabView, TabPanel } from "primereact/tabview";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { BlockUI } from "primereact/blockui";
import { InputMask } from 'primereact/inputmask';
import { FilterMatchMode } from "primereact/api";
import { connect } from "react-redux";

import { Divider } from "primereact/divider";

import { isUser } from "../common/token-check";
import { isPlatformManager } from "../common/token-check";

import WaitDialog from "../common/WaitDialog";
import WaitDialogDocument from "../common/WaitDialogDocument";
import SidebarComponent from "../common/sidebar";
import SidebarRight from "../common/sidebarRight";
import SysService from "../services/sys.service";

import { withTranslation, Trans } from "react-i18next";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../resources/customToast.css";
import cities from "../common/ccs_cities";
import { rowClass, handleDownloadDocument } from '../common/functions';
import { auto } from "@popperjs/core";


class Configuration extends Component {

  emptyNewDocument = {
    fileDescription: "",
    documentName: "",
    documentURL: "",
    fileName: "",
    documentDate: ""
  };

  constructor(props) {

    super(props);

    this.state = {
      project: "",

      pid: "",
      visibleCustomSidebar: false,
      visibleRight: false,

      nonEditAccess: true,
      activeIndex: 0,
      p12Documents: [],
      p12DocumentIndex: -1,
      p12SelectedDocument: null,

      newDocument: this.emptyNewDocument,
      addDocumentDialogVisible: false,
      addDocumentConfirmDialogVisible: false,

      loading: false,
      documentLoading: false,
      blockedPanel: false,

      s3Response: "",
      tbuFile: null,
      doc: "",

      filters1: null,
      globalFilterValue1: "",

      saveButtonStatus: false,

      mortgageStatusVisible: true

    }

  };

  /*JS_methods*/

  onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let project = { ...this.state.project };
    project[`${name}`] = val;

    this.setState({
      project,
      saveButtonStatus: true
    });
  }

  onInputChangeDoc = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let newDocument = { ...this.state.newDocument };
    newDocument[`${name}`] = val;

    this.setState({ newDocument });
  }

  actionTemplate = (data, props) => {
    return (
      <div>
        <Button
          icon="pi pi-download"
          href
          tooltip="Download"
          className="p-button-rounded p-button-text"
          onClick={(e) => {
            handleDownloadDocument(data, props);
          }}
        />
        {isPlatformManager() && (
          <Button
            icon="pi pi-trash"
            tooltip="Delete"
            className="p-button-rounded p-button-text"
            onClick={(e) => {
              this.handleDeleteDocument(data, props);
            }}
          />
        )}
      </div>
    );
  }

  handleDeleteDocument = (rowData) => {

    if (this.state.activeIndex === 2) {
      const index = this.state.p12Documents.indexOf(rowData);
      if (index > -1) {
        this.state.p12Documents.splice(index, 1);
      }
    } else if (this.state.activeIndex === 3) {
      const index = this.state.p13Documents.indexOf(rowData);
      if (index > -1) {
        this.state.p13Documents.splice(index, 1);
      }
    } else if (this.state.activeIndex === 4) {
      const index = this.state.p14Documents.indexOf(rowData);
      if (index > -1) {
        this.state.p14Documents.splice(index, 1);
      }
    } else if (this.state.activeIndex === 5) {
      const index = this.state.p15Documents.indexOf(rowData);
      if (index > -1) {
        this.state.p15Documents.splice(index, 1);
      }
    }

    this.setState({
      newDocument: this.emptyNewDocument,
      addDocumentDialogVisible: false,
      saveButtonStatus: true
    });
  }

  handleAddDocument = () => {
    this.setState({
      newDocument: this.emptyNewDocument,
      submitted: false,
      addDocumentDialogVisible: true,
    });
  }

  hideAddDocumentDialog = () => {
    this.setState({
      submitted: false,
      addDocumentDialogVisible: false,
    });
  }

  acceptAddDocument = () => {

    const filesX = document.getElementById("fupload").files;
    const fileX = filesX[0];

    if (filesX == null || fileX == null) {
      this.setState({ loading: false });

      toast.warning(("pleaseSelectFile"));

      return;
    }

    if (
      this.state.newDocument.fileDescription == null ||
      this.state.newDocument.fileDescription === ""
    ) {
      this.setState({ loading: false });

      toast.warning(("pleaseEnterTheDescription"));

      return;
    }
    const maxAllowedSize = 5 * 1024 * 1024;

    if (fileX.size > maxAllowedSize) {
      this.setState({ loading: false });

      toast.warning(("maxFileSizeIs5Mb"));

      return;
    }

    this.setState({
      documentLoading: true,
    });

    SysService.getS3URL(
      fileX.name,
      "P1" + this.state.activeIndex,
      this.state.pid
    ).then(
      (response) => {
        this.setState({ s3Response: response.data, tbuFile: fileX, saveButtonStatus: true }, () => {
          this.state.newDocument.uploadURL = this.state.s3Response.uploadURL;
          this.state.newDocument.fileName = this.state.s3Response.fileName;
          this.state.newDocument.documentName = this.state.s3Response.documentName;
          this.state.newDocument.documentDate = this.state.s3Response.documentDate;
          this.state.newDocument.documentURL = this.state.s3Response.documentURL;
        });

        this.put2S3();

        let p12doc = [...this.state.p12Documents];
        let p13doc = [...this.state.p13Documents];
        let p14doc = [...this.state.p14Documents];
        let p15doc = [...this.state.p15Documents];

        if (this.state.activeIndex === 2) {
          p12doc.push(this.state.newDocument);
          this.setState({ p12Documents: p12doc, saveButtonStatus: true });
        } else if (this.state.activeIndex === 3) {
          p13doc.push(this.state.newDocument);
          this.setState({ p13Documents: p13doc, saveButtonStatus: true });
        } else if (this.state.activeIndex === 4) {
          p14doc.push(this.state.newDocument);
          this.setState({ p14Documents: p14doc, saveButtonStatus: true });
        } else if (this.state.activeIndex === 5) {
          p15doc.push(this.state.newDocument);
          this.setState({ p15Documents: p15doc, saveButtonStatus: true });
        }

      },
      (error) => {
        this.setState({
          loading: false,
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString(),
        });

        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;
            case 403:
              window.location.href = "/403";
              break;
            case 404:
              window.location.href = "/404";
              break;
            default:
              break;
          }
        }
      }
    );
  }

  rejectAddDocument = () => { }

  /*componentDidMount*/
  componentDidMount() {

   
  }

  /**/



  stepPrev() {
    let inx = this.state.activeIndex;
    if (inx <= 0) {
    } else {
      if (!this.state.saveButtonStatus) {
        inx = inx - 1;
        this.setState({
          activeIndex: inx,
        });

        window.scrollTo(0, 0);
      } else {
        toast.warning(<Trans i18nKey="pleaseSaveFirst" />);
      }
    }
  }

  stepNext() {
    let inx = this.state.activeIndex;
    if (inx >= this.stepItems.length - 1) {
    } else {

      if (!this.state.saveButtonStatus) {
        inx = inx + 1;
        this.setState({
          activeIndex: inx,
        });

        window.scrollTo(0, 0);
      } else {
        toast.warning(<Trans i18nKey="pleaseSaveFirst" />);
      }

    }
  }

  stepSaveAndNext() {
    let inx = this.state.activeIndex;
    if (inx > this.stepItems.length - 1) {
    } else {

      if (inx === 0) {
        if (
          this.state.project.projectName == null ||
          this.state.project.projectName.trim() === "" ||
          this.state.project.address == null ||
          this.state.project.address.trim() === "" ||
          this.state.project.city == null ||
          this.state.project.city.trim() === ""
        ) {
          this.setState({ loading: false });
          toast.warning(<Trans i18nKey="warnMandatory" />);
          return;
        }
      } else {

      }

      if (isPlatformManager()) {
        this.setState({
          loading: true,
        });

        /////////////////////

        this.state.project.documents = [];

        if (inx === 2) {
          this.state.project.documents = this.state.p12Documents;
        } else if (inx === 3) {
          this.state.project.documents = this.state.p13Documents;
        } else if (inx === 4) {
          this.state.project.documents = this.state.p14Documents;
        }
        else if (inx === 5) {
          this.state.project.documents = this.state.p15Documents;
        }

        this.state.project.projectId = this.state.pid;
        this.state.project.phase = "P1" + inx;

        SysService.updateProject(this.state.project).then(
          (response) => {
            this.setState({
              project: response.data,
              loading: false,
              saveButtonStatus: false
            });

            toast.success(<Trans i18nKey="saved" />);
          },
          (error) => {
            this.setState({
              loading: false,
              content:
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString(),
            });

            toast.error(<Trans i18nKey={error.response} />);

            if (
              error.response &&
              (error.response.status === 401 ||
                error.response.status === 403 ||
                error.response.status === 404)
            ) {
              switch (error.response.status) {
                case 401:
                  window.location.href = "/401";
                  break;
                case 403:
                  window.location.href = "/403";
                  break;
                case 404:
                  window.location.href = "/404";
                  break;
                default:
                  break;
              }
            }
          }
        );
      }

      if (inx < this.stepItems.length - 1) {
        inx = inx + 1;
        this.setState({
          activeIndex: inx,
        });
      }
    }

    window.scrollTo(0, 0);
  }



  /**/
  render() {

    const { t } = this.props;


    return (
      <div>
       hhhhh
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { message } = state.message;
  return {
    message,
  };
}

export default withTranslation()(connect(mapStateToProps)(Configuration));
