import { combineReducers } from "redux";
import auth from "./auth";
import message from "./message";
import route from "./route";

export default combineReducers({
  auth,
  message,
  route
});
