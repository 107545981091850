import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { BlockUI } from "primereact/blockui";
import { Chip } from "primereact/chip";
import WaitDialog from "../common/WaitDialog";

import { Link } from "react-router-dom";

import "primereact/resources/themes/lara-light-teal/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

import { isPlatformManager, isUser } from "../common/token-check";
import { isProjectManager } from "../common/token-check";

import { connect } from "react-redux";
import { addNewProject } from "../actions/sys";
import { deleteProject } from "../actions/sys";

import SysService from "../services/sys.service";
import cities from "../common/ccs_cities";
import { withTranslation, Trans } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../resources/customToast.css";
import { t } from "i18next";

class Projects extends Component {

  emptyNewProject = {
    projectName: "",
    city: "",
    description: ""

  };

  constructor(props) {

    super(props);

    this.state = {
      content: "",
      newProject: this.emptyNewProject,
      submitted: false,
      visible: false,

      rejectConfirmVisible: false,

      projects: [],
      selectedProject: null,
      filters1: null,
      globalFilterValue1: "",
      loading: true,
      successful: false,
      blockedPanel: false,
    };

    this.accept = this.accept.bind(this);
    this.reject = this.reject.bind(this);
    this.onGlobalFilterChange1 = this.onGlobalFilterChange1.bind(this);
    this.createNew = this.createNew.bind(this);
    this.hideDialog = this.hideDialog.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.actionTemplate = this.actionTemplate.bind(this);

  }

  handleDeleteProject(data, props) {
    this.setState({
      selectedProject: data,
      rejectConfirmVisible: true,
    });
  }

  deleteProject = () => {

    this.setState({ loading: true });

    this.props
      .dispatch(deleteProject(this.state.selectedProject.id))
      .then(() => {
        this.setState({
          loading: false,
          acceptConfirmVisible: false,
        });

        toast.success(<Trans i18nKey={this.props.message} />);
        this.componentDidMount();

      })
      .catch(() => {
        this.setState({
          loading: false,
        });

        toast.error(<Trans i18nKey={this.props.message} />);

      });
  }

  accept() {

    if (
      this.state.newProject.projectName == null ||
      this.state.newProject.projectName.trim() === "" ||
      this.state.newProject.city == null ||
      this.state.newProject.city.trim() === ""
    ) {
      this.setState({ loading: false });
      toast.warning(<Trans i18nKey="warnMandatory" />);
      return;
    }

    this.setState({
      successful: false,
      loading: true,
      visible: false
    });


    this.props
      .dispatch(addNewProject(this.state.newProject))
      .then(() => {
        this.setState({
          successful: true,
          loading: false,
          newProject: this.emptyNewProject,
          submitted: false,
          visible: false,
          projectDialog: false,
        });

        toast.success(<Trans i18nKey={this.props.message} />);

        this.componentDidMount();
      })
      .catch(() => {
        this.setState({
          successful: false,
          loading: false,
        });

        toast.error(<Trans i18nKey={this.props.message} />);
      });
  }

  reject() { }

  componentDidMount() {

    this.setState({ loading: true });

    if (isPlatformManager()) {
      SysService.getProjectsByPlatformManager().then(
        (response) => {
          this.setState({
            projects: response.data,
            loading: false,
            blockedPanel: false,
          });
        },
        (error) => {
          this.setState({
            loading: false,
            content:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.timeoutErrorMessage ||
              error.toString(),
            blockedPanel: true,
          });

          toast.error(<Trans i18nKey={this.state.content} />);

          if (
            error.response &&
            (error.response.status === 401 ||
              error.response.status === 403 ||
              error.response.status === 404)
          ) {
            switch (error.response.status) {
              case 401:
                window.location.href = "/401";
                break;
              case 403:
                window.location.href = "/403";
                break;
              case 404:
                window.location.href = "/404";
                break;
              default:
                break;
            }
          }
        }
      );
    } else {

      SysService.getProjects().then(
        (response) => {
          this.setState({
            projects: response.data,
            loading: false,
            blockedPanel: false,
          });
        },
        (error) => {
          this.setState({
            loading: false,
            content:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.timeoutErrorMessage ||
              error.toString(),
            blockedPanel: true,
          });

          toast.error(<Trans i18nKey={this.state.content} />);

          if (
            error.response &&
            (error.response.status === 401 ||
              error.response.status === 403 ||
              error.response.status === 404)
          ) {
            switch (error.response.status) {
              case 401:
                window.location.href = "/401";
                break;
              case 403:
                window.location.href = "/403";
                break;
              case 404:
                window.location.href = "/404";
                break;
              default:
                break;
            }
          }
        }
      );
    }

    this.initFilters1();
  }

  createNew() {
    this.setState({
      newProject: this.emptyNewProject,
      submitted: false,
      projectDialog: true,
    });
  }

  hideDialog() {
    this.setState({
      submitted: false,
      projectDialog: false,
    });
  }

  onInputChange(e, name) {
    const val = (e.target && e.target.value) || "";
    let newProject = { ...this.state.newProject };
    newProject[`${name}`] = val;

    this.setState({ newProject });
  }

  onGlobalFilterChange1(e) {
    const value = e.target.value;
    let filters1 = { ...this.state.filters1 };
    filters1["global"].value = value;

    this.setState({ filters1, globalFilterValue1: value });
  }

  initFilters1() {
    this.setState({
      filters1: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      globalFilterValue1: "",
    });
  }

  renderHeaderTableTop(t) {
    return (
      <div className="flex justify-content-between">
        <span>{t("projects")}</span>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            className="p-inputtext-sm block mb-2"
            value={this.state.globalFilterValue1}
            onChange={this.onGlobalFilterChange1}
            placeholder={t("search")}
          />
        </span>
      </div>
    );
  }

  actionTemplate(data, props) {
    return (
      <div>

        <Link to={{ pathname: "/projectDashboard", state: data }} style={{ textDecoration: "none" }}>
          <Button
            icon="pi pi-chart-bar"
            tooltip={t("dashboard")}
            className="p-button-rounded p-button-text"

            onClick={() => {
              this.setState({ loading: true });
            }}
          />
        </Link>

        <Button
          icon="pi pi-trash"
          tooltip={t("deleteProject")}
          className="p-button-rounded p-button-text"
          onClick={() => {
            this.handleDeleteProject(data, props);
          }}
        />



        <Link to={{ pathname: "/project", state: data }} style={{ textDecoration: "none" }}>
          <Button
            icon="pi pi-chevron-circle-right"
            tooltip={t("projectDetails")}
            className="p-button-rounded p-button-text"

            onClick={() => {
              this.setState({ loading: true });
            }}
          />
        </Link>
      </div>
    );
  }

  actionTemplateName(data, props, t) {
    return (
      <div>
        <Link to={{ pathname: "/projectDashboard", state: data }}
          style={{ color: "black", textDecoration: "underline", fontWeight: "normal" }}
        
        >
          {data.projectName}

        </Link>{" "}
        {/*{data.areWeProceeding === "2" && (
          <Chip
            label={<b>Stopped</b>}
            icon="pi pi-stop-circle"
            className="awpNo mr-2 mb-2 custom-chip-constant"
          />
        )}
        {data.areWeProceeding === "3" && (
          <Chip
            label={<b>Pending</b>}
            icon="pi pi-bell"
            className="awpPending mr-2 mb-2 custom-chip-constant"
          />
        )}*/}

      </div>
    );
  }

  deleteProjectMessage() {
    return (
      <div className="flex justify-content-between">
        <label><Trans i18nKey={("confirmDeleteProjectMessage")} />
          <br />
          <b><Trans i18nKey={("confirmDeleteProjectMessage2")} /></b></label>
      </div>
    );
  }


  render() {

    const { t } = this.props;
    this.state.t = t;

    const headerTable = this.renderHeaderTableTop(t);

    const projectDialogFooter = (
      <React.Fragment>
        <Button
          label={t("cancel")}
          icon="pi pi-times"
          className="p-button-text platformColor"
          onClick={this.hideDialog}
        />
        <Button
          label={t("save")}
          icon="pi pi-save"
          className="p-button-mb"
          onClick={() => this.setState({ visible: true })}
        />

        <ConfirmDialog
          visible={this.state.visible}
          onHide={() => {
            this.setState({ visible: false });
          }}
          message={t("confirmMessage")}
          header={t("confirm")}
          icon="pi pi-exclamation-triangle"
          accept={this.accept}
          reject={this.reject}
          acceptLabel={t("yes")}
          rejectLabel={t("no")}
        />
      </React.Fragment>
    );

    return (
      <div>
        {!isUser() && (
          <>
            <BlockUI blocked={this.state.blockedPanel} fullscreen>
              {(isProjectManager()) && (
                <div className="field col-12 mb-0">
                  <Button
                    label={t("addNewProject")}
                    icon="pi pi-plus"
                    className="p-button-mb p-button-sm mr-2"
                    onClick={this.createNew}
                  />
                </div>
              )}

              <div className="col-12">
                <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                  <DataTable
                    name="dt"
                    size="small"
                    showGridlines
                    value={this.state.projects}
                    dataKey="id"
                    stripedRows
                    responsiveLayout="scroll"
                    emptyMessage={t("noProjectFound")}
                    header={headerTable}
                    paginator
                    rows={10}
                    filters={this.state.filters1}
                    filterDisplay="menu"
                    loading={this.state.loading}
                    globalFilterFields={[
                      "projectName",
                      "buildingName",
                      "city",
                      "pruid",
                    ]}
                  >

                    <Column
                      header={t("projectName")}
                      sortable
                      body={this.actionTemplateName}
                    ></Column>

                    <Column field="city" header={t("city")} sortable></Column>

                    <Column
                      field="pruid"
                      header={t("projectId")}
                      sortable
                    ></Column>
                    <Column
                      header={t("action")}
                      style={{ textAlign: "center", width: "8em" }}
                      body={this.actionTemplate}
                    />
                  </DataTable>
                </div>
              </div>

              <Dialog
                visible={this.state.projectDialog}
                style={{ width: "450px" }}
                header={t("projectDetails")}
                modal
                className="p-fluid"
                footer={projectDialogFooter}
                onHide={this.hideDialog}
              >
                <div className="p-float-label">
                  <InputText
                    id="projectName"
                    value={this.state.newProject.projectName}
                    onChange={(e) => this.onInputChange(e, "projectName")}
                    autoFocus
                    maxLength={100}
                    className="p-inputtext block mb-2"
                  />
                  <label htmlFor="projectName" className="platformColor">{t("projectName")} (*)</label>
                </div>


                <div className="p-float-label">
                  <Dropdown
                    id="city"
                    value={this.state.newProject.city}
                    options={cities}
                    onChange={(e) => this.onInputChange(e, "city")}
                    optionLabel="name"
                    optionValue="code"
                  />
                  <label htmlFor="city" className="platformColor">{t("city")} (*)</label>
                </div>

                <div className="p-float-label">
                  <InputTextarea
                    id="projectDescription"
                    value={this.state.newProject.description}
                    onChange={(e) => this.onInputChange(e, "description")}
                    rows={3}
                    cols={20}
                    maxLength={500}
                    className="p-inputtext block mb-2"
                  />
                  <label htmlFor="projectDescription" className="platformColor">{t("projectDescription")}</label>
                </div>

              </Dialog>

              <ConfirmDialog

                visible={this.state.rejectConfirmVisible}
                onHide={() => {
                  this.setState({ rejectConfirmVisible: false });
                }}
                message={this.deleteProjectMessage}
                header={t("confirm")}
                icon="pi pi-exclamation-triangle icon-red"
                acceptClassName='p-button-danger'
                accept={this.deleteProject}
                reject={this.reject}
                acceptLabel={t("yes")}
                rejectLabel={t("no")}
              />

              <WaitDialog loading={this.state.loading} lang={t} />

              <ToastContainer
                autoClose={2200}
                position={toast.POSITION.TOP_CENTER}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick={true}
                rtl={false}
                theme="colored"
                pauseOnFocusLoss={true}
                draggable={false}
                pauseOnHover={true}
              />
            </BlockUI>
          </>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { message } = state.message;
  return {
    message,
  };
}

export default withTranslation()(connect(mapStateToProps)(Projects));
