import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { BlockUI } from "primereact/blockui";
import { Dropdown } from "primereact/dropdown";

import "primereact/resources/themes/lara-light-teal/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

import { connect } from "react-redux";
import { addNewUser } from "../actions/sys";
import { updateUser } from "../actions/sys";

import { isAdmin } from "../common/token-check";

import SysService from "../services/sys.service";
import jwt_decode from "jwt-decode";
import { withTranslation, Trans } from "react-i18next";
import { t } from "i18next";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../resources/customToast.css";
import { clearMessage } from "../actions/message";

class PlatformUsers extends Component {

  emptyNewUser = {
    username: "",
    firstname: "",
    lastname: "",
    role: ""
  };

  constructor(props) {

    super(props);

    this.state = {
      newUser: this.emptyNewUser,
      addNewUserDialogVisible: false,
      updateUserDialogVisible: false,
      addNewUserConfirmDialogVisible: false,
      updateUserConfirmDialogVisible: false,

      submitted: false,

      content: "",
      users: [],
      selectedRole: null,

      selectedUser: null,
      filters1: null,
      globalFilterValue1: "",
      loading: false,
      successful: false,
      blockedPanel: false,

      roles: null, 
      isPMStatus: false
    };

    const user = JSON.parse(localStorage.getItem("user"));
    const decodedJwt = jwt_decode(user.token);

    this.sessUser = decodedJwt.sub;

    if (decodedJwt.roles == "ROLE_ADMIN") {
      this.state.roles = [
        { name: t("platformManager"), code: "ROLE_PLATFORM_MANAGER" },
        { name: t("admin"), code: "ROLE_ADMIN" },

      ];
    } else if (decodedJwt.roles == "ROLE_PLATFORM_MANAGER") {
      this.state.roles = [
        { name: t("projectManager"), code: "ROLE_PROJECT_MANAGER" },
        { name: t("viewer"), code: "ROLE_VIEWER" },
        { name: t("limitedViewer"), code: "ROLE_LIMITED_VIEWER" },
        { name: t("user"), code: "ROLE_USER" },
      ];

    } else if (decodedJwt.roles == "ROLE_PROJECT_MANAGER") {
      this.state.roles = [
        { name: t("user"), code: "ROLE_USER" },
      ];
    }



    this.onGlobalFilterChange1 = this.onGlobalFilterChange1.bind(this);
    this.getRoleString = this.getRoleString.bind(this);
    this.getStatus = this.getStatus.bind(this);

    this.actionTemplate = this.actionTemplate.bind(this);

    this.handleCreateNewUser = this.handleCreateNewUser.bind(this);
    this.onInputChangeAddNewUserDialog = this.onInputChangeAddNewUserDialog.bind(this);

    this.handleUpdateUser = this.handleUpdateUser.bind(this);

    this.onRoleChange = this.onRoleChange.bind(this);

    this.acceptAddNewUser = this.acceptAddNewUser.bind(this);
    this.rejectAddNewUser = this.rejectAddNewUser.bind(this);
    this.acceptUpdateUser = this.acceptUpdateUser.bind(this);
    this.rejectUpdateUser = this.rejectUpdateUser.bind(this);

    this.hideAddNewUserDialog = this.hideAddNewUserDialog.bind(this);
    this.hideUpdateUserDialog = this.hideUpdateUserDialog.bind(this);

  }

  getRoleString(rowData) {
    let roleArr = rowData.userRole;
    let delimeter = ", ";
    let result = "";

    for (let i = 0; i < roleArr.length; i++) {
      if (roleArr[i] === "ROLE_USER") {
        result = result + t("user");
      } else if (roleArr[i] === "ROLE_ADMIN") {
        result = result + t("admin");
      } else if (roleArr[i] === "ROLE_PLATFORM_MANAGER") {
        result = result + t("platformManager");
      } else if (roleArr[i] ==="ROLE_PROJECT_MANAGER") {
        result = result + t("projectManager");
      } else if (roleArr[i] === "ROLE_VIEWER") {
        result = result + t("viewer");
      }else if (roleArr[i] === "ROLE_LIMITED_VIEWER") {
        result = result + t("limitedViewer");
      }
      
      if (roleArr.length > 1) {
        result = result + delimeter;
      }
    }
    return result;
  }

  getStatus(rowData) {
    return <Trans i18nKey={rowData.status}/>;
  }  

  acceptAddNewUser() {
    this.setState({
      successful: false,
      loading: true,
      visible: false,
    });

    if (
      this.state.newUser.username == null ||
      this.state.newUser.username === "" ||
      this.state.newUser.firstname == null ||
      this.state.newUser.firstname === "" ||
      this.state.newUser.role == null ||
      this.state.newUser.role === "" ||
      this.state.newUser.lastname == null ||
      this.state.newUser.lastname === ""
    ) {
      this.setState({ loading: false, successful: false });
      toast.warning(<Trans i18nKey="warnMandatory"/>);
      return;
    } 

    if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.newUser.username)
    ) {
      this.setState({ loading: false, successful: false });
      toast.warning(<Trans i18nKey="warnEmailFormat"/>);
      return;
    }

    this.props
      .dispatch(
        addNewUser(
          this.state.newUser.username,
          this.state.newUser.firstname,
          this.state.newUser.lastname,
          this.state.newUser.role
        )
      )
      .then(() => {
        this.setState({
          successful: true,
          loading: false,
          addNewUserDialogVisible: false,
          selectedRole: null,
          selectedUser: null,
        });

        toast.success(<Trans i18nKey={this.props.message}/>);

        this.componentDidMount();
      })
      .catch(() => {
        this.setState({
          successful: false,
          loading: false,
        });

        toast.error(<Trans i18nKey={this.props.message}/>);
      });
  }

  rejectAddNewUser() {}

  acceptUpdateUser() {
    this.setState({
      successful: false,
      loading: true,
      visible: false,
    });

    if (
      this.state.newUser.firstname == null ||
      this.state.newUser.firstname === "" ||
      this.state.newUser.lastname == null ||
      this.state.newUser.lastname === "" ||
      this.state.newUser.role == null ||
      this.state.newUser.role === ""
    ) {
      this.setState({ loading: false });
      toast.warning(<Trans i18nKey="warnMandatory"/>);
      return;
    }

    this.props
      .dispatch(
        updateUser(
          this.state.newUser.username,
          this.state.newUser.firstname,
          this.state.newUser.lastname,
          this.state.newUser.role
        )
      )
      .then(() => {
        this.setState({
          successful: true,
          loading: false,
          updateUserDialogVisible: false,
          selectedRole: null,
          selectedUser: null,
        });

        toast.success(<Trans i18nKey={this.props.message}/>);

        this.componentDidMount();
      })
      .catch(() => {
        this.setState({
          successful: false,
          loading: false,
        });

        toast.error(<Trans i18nKey={this.props.message}/>);
      });
  }

  rejectUpdateUser() {}

  componentDidMount() {

    this.setState({ loading: true });
    
    SysService.getAdminsAndPlatformManagers().then(
      (response) => {
        this.setState({
          users: response.data.userResponseList,
          blockedPanel: false,
          loading: false,
        });     
        
      },
      (error) => {
        this.setState({
          loading: false,
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString(),
          blockedPanel: true,
        });

        toast.error(<Trans i18nKey={this.state.content}/>);

        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;
            case 403:
              window.location.href = "/403";
              break;
            case 404:
              window.location.href = "/404";
              break;
            default:
              break;
          }
        }
      }
    );

    this.initFilters1();
  }

  onRoleChange(e) {
    this.setState({ selectedRole: e.value });
  }

  onGlobalFilterChange1(e) {
    const value = e.target.value;
    let filters1 = { ...this.state.filters1 };
    filters1["global"].value = value;

    this.setState({ filters1, globalFilterValue1: value });
  }

  initFilters1() {
    this.setState({
      filters1: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      globalFilterValue1: "",
    });
  }

  renderHeader1(t) {

    return (
      <div className="flex justify-content-between">
        <span>{t("users")}</span>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            className="p-inputtext-sm block mb-2"
            value={this.state.globalFilterValue1}
            onChange={this.onGlobalFilterChange1}
            placeholder={t("search")}
          />
        </span>
      </div>
    );
  }

  onInputChangeAddNewUserDialog(e, name) {
    const val = (e.target && e.target.value) || "";
    let newUser = { ...this.state.newUser };
    newUser[`${name}`] = val;

    if (newUser[`role`] === 'ROLE_PLATFORM_MANAGER') {
      this.setState({ isPMStatus: true });
      
    } else {
      this.setState({ isPMStatus: false });
    }

    this.setState({ newUser });
  }

  handleCreateNewUser() {
    this.setState({
      newUser: this.emptyNewUser,
      submitted: false,
      addNewUserDialogVisible: true,
    });
  }

  handleUpdateUser(rowData, rowProps) {

    let newUser = { ...rowData };
    newUser[`role`] = rowData.userRole[0];

    if (newUser[`role`] === 'ROLE_PLATFORM_MANAGER') {
      this.setState({ isPMStatus: true });
    } else {
      this.setState({ isPMStatus: false });
    }    

    this.setState({
      newUser,
      submitted: false,
      updateUserDialogVisible: true,
    });

  }

  hideAddNewUserDialog() {
    this.setState({
      submitted: false,
      addNewUserDialogVisible: false,
    });
  }

  hideUpdateUserDialog() {
    this.setState({
      submitted: false,
      updateUserDialogVisible: false,
    });
  }

  actionTemplate(data, props) {
    if (data.id === "1") { //hhhladmin
      return(<></>);
    } else {
      return (
        <div>
          <Button
            icon="pi pi-pencil"
            tooltip={t("edit")}
            className="p-button-rounded p-button-text"
            onClick={(e) => {
              this.handleUpdateUser(data, props);
            }}
          />
        </div>
      );
          }   
  }

  render() {
    
    const { t } = this.props;

    const header1 = this.renderHeader1(t);

    const addNewUserDialogFooter = (
      <React.Fragment>
        <Button
          label={t("cancel")}
          icon="pi pi-times"
          className="p-button-text platformColor"
          onClick={this.hideAddNewUserDialog}
        />
        <Button
          label={t("save")}
          icon="pi pi-save"
          className="p-button-mb"
          onClick={() =>
            this.setState({ addNewUserConfirmDialogVisible: true })
          }
        />

        <ConfirmDialog
          visible={this.state.addNewUserConfirmDialogVisible}
          onHide={() => {
            this.setState({ addNewUserConfirmDialogVisible: false });
          }}
          message={t("confirmMessage")}
          header={t("confirm")}
          icon="pi pi-exclamation-triangle"
          accept={this.acceptAddNewUser}
          reject={this.rejectAddNewUser}
          acceptLabel={t("yes")}
          rejectLabel={t("no")}
        />
      </React.Fragment>
    );

    const updateUserDialogFooter = (
      <React.Fragment>
        <Button
          label={t("cancel")}
          icon="pi pi-times"
          className="p-button-text platformColor"
          onClick={this.hideUpdateUserDialog}
        />
        <Button
          label={t("save")}
          icon="pi pi-save"
          className="p-button-mb"
          onClick={() =>
            this.setState({ updateUserConfirmDialogVisible: true })
          }
        />

        <ConfirmDialog
          visible={this.state.updateUserConfirmDialogVisible}
          onHide={() => {
            this.setState({ updateUserConfirmDialogVisible: false });
          }}
          message={t("confirmMessage")}
          header={t("confirm")}
          icon="pi pi-exclamation-triangle"
          accept={this.acceptUpdateUser}
          reject={this.rejectUpdateUser}
          acceptLabel={t("yes")}
          rejectLabel={t("no")}
        />
      </React.Fragment>
    );

    return (
      <div>
        {(isAdmin()) && (
          <>
            <BlockUI blocked={this.state.blockedPanel} fullscreen>
              <div className="field col-12 mb-0">
                <Button
                  label={t("txtPlatformUsers1")}
                  icon="pi pi-plus"
                  className="p-button-mb p-button-sm mr-2"
                  onClick={this.handleCreateNewUser}
                />
              </div>

              <div className="col-12">
                <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                  <DataTable
                    name="dt"
                    size="small"
                    showGridlines
                    selectionMode="single"
                    value={this.state.users}
                    dataKey="id"
                    stripedRows
                    responsiveLayout="scroll"
                    emptyMessage={t("noUsersFound")}
                    header={header1}
                    paginator
                    rows={10}
                    filters={this.state.filters1}
                    filterDisplay="menu"
                    loading={this.state.loading}
                    globalFilterFields={[
                      "username",
                      "firstname",
                      "lastname",
                      "userRole",
                      "status",
                    ]}
                  >
                    <Column
                      field="username"
                      header={t("userName")}
                      sortable
                    ></Column>
                    <Column
                      field="firstname"
                      header={t("firstName")}
                      sortable
                    ></Column>
                    <Column
                      field="lastname"
                      header={t("lastName")}
                      sortable
                    ></Column>                 
                    <Column
                      field="userRole"
                      header={t("role")}
                      sortable
                      body={this.getRoleString}
                    ></Column>
                    <Column field="status" header={t("status")} body={this.getStatus} sortable></Column>
                    <Column
                      header={t("action")}
                      style={{ textAlign: "center", width: "8em" }}
                      body={this.actionTemplate}
                    />
                  </DataTable>
                </div>
              </div>

              <Dialog
                visible={this.state.addNewUserDialogVisible}
                style={{ width: "450px" }}
                header={t("userDetails")}
                modal
                className="p-fluid"
                footer={addNewUserDialogFooter}
                onHide={this.hideAddNewUserDialog}
              >
                <div className="p-float-label">
                  <InputText
                    id="userName1"
                    value={this.state.newUser.username}
                    onChange={(e) =>
                      this.onInputChangeAddNewUserDialog(e, "username")
                    }
                    autoFocus
                    maxLength={50}
                    className="p-inputtext block mb-2"
                  />
                  <label htmlFor="userName1" className="platformColor">
                  {t("email")} ({t("userName")}) (*)
                  </label>
                </div>
                <div className="p-float-label">
                  <InputText
                    id="firstName1"
                    value={this.state.newUser.firstname}
                    onChange={(e) =>
                      this.onInputChangeAddNewUserDialog(e, "firstname")
                    }
                    maxLength={50}
                    className="p-inputtext block mb-2"
                  />
                  <label htmlFor="firstName1" className="platformColor">{t("firstName")} (*)</label>
                </div>
                <div className="p-float-label">
                  <InputText
                    id="lastName1"
                    value={this.state.newUser.lastname}
                    onChange={(e) =>
                      this.onInputChangeAddNewUserDialog(e, "lastname")
                    }
                    maxLength={50}
                    className="p-inputtext block mb-2"
                  />
                  <label htmlFor="lastName1" className="platformColor">{t("lastName")} (*)</label>
                </div>
 
                <div className="p-float-label">
                    <Dropdown
                    id="authD"
                    value={this.state.newUser.role}
                    options={this.state.roles}
                    onChange={(e) => this.onInputChangeAddNewUserDialog(e, "role")}
                    className="p-inputtext-sm"
                    optionLabel="name"
                    optionValue="code"
                  />
                  <label htmlFor="authD" className="platformColor">{t("role")} (*)</label>
                </div>    

              </Dialog>

              <Dialog
                visible={this.state.updateUserDialogVisible}
                style={{ width: "450px" }}
                header={t("userDetails")}
                modal
                className="p-fluid"
                footer={updateUserDialogFooter}
                onHide={this.hideUpdateUserDialog}
              >
                <div className="col-12 mb-3">
                  <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                    <div className="flex justify-content-between mb-3">
                      <div>
                        <span className="block text-700 font-medium mb-3">
                          {this.state.newUser.username}
                        </span>
                        <div className="text-900 font-medium text-xl">
                          {this.state.newUser.firstname}{" "}
                          {this.state.newUser.lastname}
                        </div>
                      </div>
                    </div>
                    <span className="text-green-500 font-medium">
                      Id:{" "}
                    </span>
                    <span className="text-700">{this.state.newUser.id}</span>
                    <br />
                    <span className="text-green-500 font-medium">
                    {t("registration")}:{" "}
                    </span>
                    <span className="text-700">
                      {this.state.newUser.registerDate}
                    </span>
                    <br />
                    <span className="text-green-500 font-medium">
                    {t("lastModification")}:{" "} 
                    </span>
                    <span className="text-700">
                      {this.state.newUser.lastModificationTime}
                    </span>
                  </div>
                </div>

                <div className="p-float-label">
                  <InputText
                    id="firstName1"
                    value={this.state.newUser.firstname}
                    onChange={(e) =>
                      this.onInputChangeAddNewUserDialog(e, "firstname")
                    }
                    maxLength={50}
                    className="p-inputtext-sm block mb-2"
                  />
                  <label htmlFor="firstName1" className="platformColor">{t("firstName")} (*)</label>
                </div>
                <div className="p-float-label">
                  <InputText
                    id="lastName1"
                    value={this.state.newUser.lastname}
                    onChange={(e) =>
                      this.onInputChangeAddNewUserDialog(e, "lastname")
                    }
                    maxLength={50}
                    className="p-inputtext-sm block mb-2"
                  />
                  <label htmlFor="lastName1" className="platformColor">{t("lastName")} (*)</label>
                </div>

                <div className="p-float-label">
                    <Dropdown
                    id="authD"
                    value={this.state.newUser.role}
                    options={this.state.roles}
                    onChange={(e) => this.onInputChangeAddNewUserDialog(e, "role")}
                    className="p-inputtext-sm"
                    optionLabel="name"
                    optionValue="code"
                  />
                  <label htmlFor="authD" className="platformColor">{t("role")} (*)</label>
                </div>    

              </Dialog>

              <ToastContainer
                autoClose={2200}
                position={toast.POSITION.TOP_CENTER}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick={true}
                rtl={false}
                theme="colored"
                pauseOnFocusLoss={true}
                draggable={false}
                pauseOnHover={true}
              />
            </BlockUI>
          </>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { message } = state.message;
  return {
    message,
  };
}

export default withTranslation()(connect(mapStateToProps)(PlatformUsers));
