import React, { Component } from "react";
import "primereact/resources/themes/lara-light-teal/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

import { Card } from "primereact/card";
import { Chip } from "primereact/chip";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import { Steps } from "primereact/steps";
import { InputText } from "primereact/inputtext";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Dropdown } from "primereact/dropdown";
import { TabView, TabPanel } from "primereact/tabview";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { BlockUI } from "primereact/blockui";
import { FilterMatchMode } from "primereact/api";
import { connect } from "react-redux";


import { isUser } from "../common/token-check";
import { isPlatformManager, isProjectManager } from "../common/token-check";

import WaitDialog from "../common/WaitDialog";
import WaitDialogDocument from "../common/WaitDialogDocument";
import SidebarComponent from "../common/sidebar";
import Graphic from "../common/graphic";
import { roundToTwo } from '../common/functions';

import SysService from "../services/sys.service";

import { withTranslation, Trans } from "react-i18next";

import { ToastContainer, toast } from "react-toastify";
import { t } from "i18next";

import "react-toastify/dist/ReactToastify.css";
import "../resources/customToast.css";

import { rowClass, handleDownloadEQDatasheetDocument } from '../common/functions';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import zoomPlugin from "chartjs-plugin-zoom";
import { Chart } from "chart.js";


Chart.register(zoomPlugin);

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Title,
  Legend
);

class SensorData extends Component {

  emptyEQ = {
    eqBuildingName: "",
    eqRoomName: "",
    eqDeviceName: "",
    eqDeviceType: "",
    eqBrandNameModel: "",
    eqDeviceEUI: "",
    eqDatasheetURL: "",
  };

  sendButtonNewDialog = {
    timeInterval: "",
    reportTime: "",
  };

  generateButtonNewDialog = {
    emailAddress: "",
  };

  constructor(props) {
    super(props);

    this.state = {
      project: "",

      pid: "",
      visibleCustomSidebar: false,
      nonEditAccess: true,
      activeIndex: 0,

      p91Documents: [],
      p91DocumentIndex: -1,
      p91SelectedDocument: null,

      eqs: [],
      newEQ: this.emptyEQ,
      addEQDialogVisible: false,
      addEQConfirmDialogVisible: false,

      updateEQDialogVisible: false,
      updateEQConfirmDialogVisible: false,

      eqDataVisible: false,
      selectedEquipment: null,

      loading: false,
      documentLoading: false,
      blockedPanel: false,

      s3Response: "",
      tbuFile: null,
      doc: "",

      saveButtonStatus: false,

      filters1: null,
      globalFilterValue1: "",

      generateDialogVisible: false,
      sendDialogVisible: false,

      reportTime: "",
      timeInterval: "",
      emailAddress: "",

      eqList: [],
      zoneList: [],
    };
  }

  /*JS_methods*/
  onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let project = { ...this.state.project };
    project[`${name}`] = val;

    this.setState({
      project,
      saveButtonStatus: true,
    });
  };

  onInputChangeEQ = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let newEQ = { ...this.state.newEQ };
    newEQ[`${name}`] = val;

    this.setState({ newEQ });
  };

  showEqData = (e, name) => {
    const val = (e.target && e.target.value) || "";

    this.setState({
      loading: true,
      selectedEquipment: val,
    });

    SysService.getSensorData(val.eqDeviceEUI).then(
      (response) => {

        console.log(response.data);


        for (let i = 0; i < response.data.zoneDataResponseList.length; i++) {
          let zone = {
            ...this.emptyZone,
            zoneName: response.data.zoneDataResponseList[i].name,
          };

          let sensorDataList = response.data.zoneDataResponseList[i].sdrList;

          this.setState({
            eqList: [],
          });

          for (let j = 0; j < sensorDataList.length; j++) {
            let eq = {
              ...this.emptyEq,
              eqName: sensorDataList[j].eui,
            };

            const sensorData = JSON.parse(sensorDataList[j].jsonVal);

            if (sensorData.length > 0) {

              eq = {
                ...eq,
                ts: sensorData[sensorData.length - 1].ts,
                co2: sensorData[sensorData.length - 1].co2,
                lig: sensorData[sensorData.length - 1].lig,
                hum: roundToTwo(sensorData[sensorData.length - 1].hum),
                tem: roundToTwo(sensorData[sensorData.length - 1].tem),
                bat: sensorData[sensorData.length - 1].bat,
                gw: sensorData[sensorData.length - 1].gw,
              };

              const tsArr = [];
              const ligArr = [];
              const humArr = [];
              const temArr = [];
              const co2Arr = [];

              for (let k = 0; k < sensorData.length; k++) {
                tsArr.push(sensorData[k].ts);
                ligArr.push(sensorData[k].lig);
                humArr.push(roundToTwo(sensorData[k].hum));
                temArr.push(roundToTwo(sensorData[k].tem));
                co2Arr.push(sensorData[k].co2);
              }

              eq = {
                ...eq,
                tsArr: tsArr,
                ligArr: ligArr,
                humArr: humArr,
                temArr: temArr,
                co2Arr: co2Arr,
              };
            }

            const labels = eq.tsArr;
            const data = {
              labels,
              datasets: [
                {
                  label: t("humidity"),
                  data: eq.humArr,
                  borderColor: "rgba(0, 0, 255, 0.5)",
                  borderCapStyle: "butt",
                  backgroundColor: "rgba(0, 0, 255, 0.5)",
                  yAxisID: "y",
                  pointStyle: 'circle',
                  pointRadius: 0,
                  pointHoverRadius: 0
                },
              ],
            };

            const data2 = {
              labels,
              datasets: [
                {
                  label: t("temperature"),
                  data: eq.temArr,
                  borderColor: "rgb(255, 0, 0)",
                  borderCapStyle: "butt",
                  backgroundColor: "rgba(255, 0, 0, 0.5)",
                  yAxisID: "y",
                  pointStyle: 'circle',
                  pointRadius: 0,
                  pointHoverRadius: 0
                },
              ],
            };

            const data3 = {
              labels,
              datasets: [
                {
                  label: "CO₂",
                  data: eq.co2Arr,
                  borderColor: "rgb(255, 165, 0)",
                  borderCapStyle: "butt",
                  backgroundColor: "rgba(255, 165, 0, 0.5)",
                  yAxisID: "y",
                  pointStyle: 'circle',
                  pointRadius: 0,
                  pointHoverRadius: 0
                },
              ],
            };

            eq = {
              ...eq,
              data: data,
              data2: data2,
              data3: data3,
            };

            this.setState((prevState) => ({
              eqList: [...prevState.eqList, eq],
            }));
          }

          zone = {
            ...zone,
            eqArr: this.state.eqList,
          };

          this.setState((prevState) => ({
            zoneList: [...prevState.zoneList, zone],
          }));
        }

        this.setState({
          loading: false,
          blockedPanel: false,
        });
      },
      (error) => {
        this.setState({
          loading: false,
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString(),
          blockedPanel: true,
        });

        toast.error(<Trans i18nKey={this.state.content} />);
      }
    );

    this.setState({
      eqDataVisible: true,
    });
  };

  actionTemplate = (data, props) => {
    return (
      <div>
        {data.eqDatasheetURL !== "" && (
          <Button
            icon="pi pi-download"
            href
            tooltip="Download"
            className="p-button-rounded p-button-text"
            onClick={(e) => {
              handleDownloadEQDatasheetDocument(data, props);
            }}
          />
        )}

        {isProjectManager() && (
          <>
            <Button
              icon="pi pi-pencil"
              href
              tooltip={t("update")}
              className="p-button-rounded p-button-text"
              onClick={(e) => {
                this.handleUpdateEQ(data, props);
              }}
            />
            <Button
              icon="pi pi-trash"
              tooltip={t("delete")}
              className="p-button-rounded p-button-text"
              onClick={(e) => {
                this.handleDeleteEQ(data, props);
              }}
            />
          </>
        )}
      </div>
    );
  };

  handleDeleteEQ = (rowData) => {
    const index = this.state.eqs.indexOf(rowData);
    if (index > -1) {
      this.state.eqs.splice(index, 1);
    }

    this.setState({
      newEQ: this.emptyEQ,
      addEQDialogVisible: false,
      saveButtonStatus: true,
    });
  };

  handleAddEQ = () => {
    this.setState({
      newEQ: this.emptyEQ,
      submitted: false,
      addEQDialogVisible: true,
    });
  };

  hideAddEQDialog = () => {
    this.setState({
      submitted: false,
      addEQDialogVisible: false,
    });
  };


  handleUpdateEQ = (rowData) => {
    const index = this.state.eqs.indexOf(rowData);

    this.setState({
      newEQ: rowData,
      submitted: false,
      updateEQDialogVisible: true,
      deviceIndex: index,
    });
  };

  hideUpdateEQDialog = () => {
    this.setState({
      submitted: false,
      updateEQDialogVisible: false,
    });
  };

  hideShowEQDialog = () => {
    this.setState({
      eqDataVisible: false,
      zoneList:[]
    });
  };

  handleSendButton = () => {
    this.setState({
      sendButtonNewDialog: this.sendButtonNewDialog,
      submitted: false,
      sendDialogVisible: true,
    });
  };

  handleGenerateButton = () => {
    this.setState({
      generateButtonNewDialog: this.generateButtonNewDialog,
      submitted: false,
      generateDialogVisible: true,
    });
  };

  hideGenerateDialog = () => {
    this.setState({
      submitted: false,
      generateDialogVisible: false,
    });
  };

  hideSendDialog = () => {
    this.setState({
      submitted: false,
      sendDialogVisible: false,
    });
  };

  /*Equipment Add Dialog*/
  acceptAddEQ = () => {

    const filesX = document.getElementById("fupload").files;
    const fileX = filesX[0];

    if (
      this.state.newEQ.siteId == null ||
      this.state.newEQ.siteId === ""
    ) {
      this.setState({ loading: false });

      toast.warning(<Trans i18nKey="pleaseEnterTheSiteName" />);

      return;
    }

    if (
      this.state.newEQ.zoneId == null ||
      this.state.newEQ.zoneId === ""
    ) {
      this.setState({ loading: false });

      toast.warning(<Trans i18nKey="pleaseEnterTheZoneName" />);

      return;
    }

    if (
      this.state.newEQ.eqDeviceName == null ||
      this.state.newEQ.eqDeviceName === ""
    ) {
      this.setState({ loading: false });

      toast.warning(<Trans i18nKey="pleaseEnterTheDeviceName" />);

      return;
    } else if (
      this.state.newEQ.eqDeviceType == null ||
      this.state.newEQ.eqDeviceType === ""
    ) {
      this.setState({ loading: false });
      toast.warning(<Trans i18nKey="pleaseEnterTheDeviceType" />);

      return;
    } else if (
      this.state.newEQ.eqBrandNameModel == null ||
      this.state.newEQ.eqBrandNameModel === ""
    ) {
      this.setState({ loading: false });

      toast.warning(<Trans i18nKey="pleaseEnterTheBrandNameModel" />);

      return;
    } else if (
      this.state.newEQ.eqDeviceEUI == null ||
      this.state.newEQ.eqDeviceEUI === ""
    ) {
      this.setState({ loading: false });

      toast.warning(<Trans i18nKey="pleaseEnterTheDeviceEUI" />);

      return;
    }


    if (fileX !== undefined) {
      const maxAllowedSize = 5 * 1024 * 1024;

      if (fileX.size > maxAllowedSize) {
        this.setState({ loading: false });

        toast.warning(<Trans i18nKey="maxFileSizeIs5Mb" />);

        return;
      }

      this.setState({
        documentLoading: true,
      });

      SysService.getS3URL(
        fileX.name,
        "P9" + this.state.activeIndex,
        this.state.pid
      ).then(
        (response) => {
          this.setState(
            { s3Response: response.data, tbuFile: fileX, saveButtonStatus: true },
            () => {
              this.state.newEQ.eqDatasheetURL = this.state.s3Response.documentURL;
            }
          );

          this.put2S3();
        },
        (error) => {
          this.setState({
            loading: false,
            content:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString(),
          });

          if (
            error.response &&
            (error.response.status === 401 ||
              error.response.status === 403 ||
              error.response.status === 404)
          ) {
            switch (error.response.status) {
              case 401:
                window.location.href = "/401";
                break;
              case 403:
                window.location.href = "/403";
                break;
              case 404:
                window.location.href = "/404";
                break;
              default:
                break;
            }
          }
        }
      );
    }

    this.setState({ saveButtonStatus: true, addEQDialogVisible: false }, () => {
      this.state.eqs.push(this.state.newEQ);
    });

  };

  rejectAddEQ = () => { };


  /*Equipment Update Dialog*/
  acceptUpdateEQ = () => {
    const filesX = document.getElementById("fupload").files;
    const fileX = filesX[0];

    if (
      this.state.newEQ.siteId == null ||
      this.state.newEQ.siteId === ""
    ) {
      this.setState({ loading: false });

      toast.warning(<Trans i18nKey="pleaseEnterTheSiteName" />);

      return;
    }

    if (
      this.state.newEQ.zoneId == null ||
      this.state.newEQ.zoneId === ""
    ) {
      this.setState({ loading: false });

      toast.warning(<Trans i18nKey="pleaseEnterTheZoneName" />);

      return;
    }

    if (
      this.state.newEQ.eqDeviceName == null ||
      this.state.newEQ.eqDeviceName === ""
    ) {
      this.setState({ loading: false });

      toast.warning(<Trans i18nKey="pleaseEnterTheDeviceName" />);

      return;
    } else if (
      this.state.newEQ.eqDeviceType == null ||
      this.state.newEQ.eqDeviceType === ""
    ) {
      this.setState({ loading: false });
      toast.warning(<Trans i18nKey="pleaseEnterTheDeviceType" />);

      return;
    } else if (
      this.state.newEQ.eqBrandNameModel == null ||
      this.state.newEQ.eqBrandNameModel === ""
    ) {
      this.setState({ loading: false });

      toast.warning(<Trans i18nKey="pleaseEnterTheBrandNameModel" />);

      return;
    } else if (
      this.state.newEQ.eqDeviceEUI == null ||
      this.state.newEQ.eqDeviceEUI === ""
    ) {
      this.setState({ loading: false });

      toast.warning(<Trans i18nKey="pleaseEnterTheDeviceEUI" />);

      return;
    }

    if (fileX !== undefined) {
      const maxAllowedSize = 5 * 1024 * 1024;

      if (fileX.size > maxAllowedSize) {
        this.setState({ loading: false });

        toast.warning(<Trans i18nKey="maxFileSizeIs5Mb" />);

        return;
      }

      this.setState({
        documentLoading: true,
      });

      SysService.getS3URL(
        fileX.name,
        "P9" + this.state.activeIndex,
        this.state.pid
      ).then(
        (response) => {
          this.setState(
            { s3Response: response.data, tbuFile: fileX, saveButtonStatus: true },
            () => {
              this.state.newEQ.eqDatasheetURL = this.state.s3Response.documentURL;
            }
          );

          this.put2S3();
        },
        (error) => {
          this.setState({
            loading: false,
            content:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString(),
          });

          if (
            error.response &&
            (error.response.status === 401 ||
              error.response.status === 403 ||
              error.response.status === 404)
          ) {
            switch (error.response.status) {
              case 401:
                window.location.href = "/401";
                break;
              case 403:
                window.location.href = "/403";
                break;
              case 404:
                window.location.href = "/404";
                break;
              default:
                break;
            }
          }
        }
      );

    }

    this.setState({ saveButtonStatus: true, updateEQDialogVisible: false }, () => {
      this.state.eqs[this.state.deviceIndex] = this.state.newEQ;
    });

  };

  rejectUpdateEQ = () => { };

  /*componentDidMount*/
  componentDidMount() {
    this.setState({
      loading: true,
      pid: this.props.location.state.id,
    });

    SysService.getProject(this.props.location.state.id).then(
      (response) => {
        let temp91Documents = [];

        if (response.data.documentList != null) {
          for (let i = 0; i < response.data.documentList.length; i++) {
            if (response.data.documentList[i].phase === "P91") {
              temp91Documents.push(response.data.documentList[i]);
            }
          }
        }

        this.setState({
          project: response.data,
          eqs: response.data.equipmentList,
          p91Documents: temp91Documents,
          sites: response.data.siteList,
          zones: response.data.zoneList,
          loading: false,
          blockedPanel: false,
        });
      },
      (error) => {
        this.setState({
          loading: false,
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString(),
          blockedPanel: true,
        });

        toast.error(this.state.content);

        if (
          error.response &&
          (error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404)
        ) {
          switch (error.response.status) {
            case 401:
              window.location.href = "/401";
              break;
            case 403:
              window.location.href = "/403";
              break;
            case 404:
              window.location.href = "/404";
              break;
            default:
              break;
          }
        }
      }
    );

    if (isProjectManager()) {
      this.setState({
        nonEditAccess: false,
      });
    }

    this.initFilters1();
    window.scrollTo(0, 0);
  }

  /**/
  getS3URL(e) {
    const filesX = document.getElementById("fupload").files;
    const fileX = filesX[0];
    const fileNameX = fileX.name;

    const maxAllowedSize = 5 * 1024 * 1024;

    if (fileX.size > maxAllowedSize) {
      this.setState({ loading: false });

      toast.warning("Max file size 5 Mb");

      return;
    }

    this.setState({
      loading: true,
    });

    if (isProjectManager()) {
      SysService.getS3URL(
        fileNameX,
        this.state.doc.documentType,
        this.state.pid
      ).then(
        (response) => {
          this.setState({
            fileUrl: response.data,
            tbuFile: fileX,
          });
          this.put2S3();
        },
        (error) => {
          this.setState({
            loading: false,
            content:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString(),
          });

          if (
            error.response &&
            (error.response.status === 401 ||
              error.response.status === 403 ||
              error.response.status === 404)
          ) {
            switch (error.response.status) {
              case 401:
                window.location.href = "/401";
                break;
              case 403:
                window.location.href = "/403";
                break;
              case 404:
                window.location.href = "/404";
                break;
              default:
                break;
            }
          }
        }
      );
    }
  }

  put2S3() {
    this.setState({
      documentLoading: true,
    });

    if (isProjectManager()) {
      /////////////////////
      SysService.putFile2S3URL(
        this.state.s3Response.uploadURL,
        this.state.tbuFile
      ).then(
        (response) => {
          this.setState({
            documentLoading: false,
          });
        },
        (error) => {
          this.setState({
            loading: false,
            content:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString(),
          });
          if (
            error.response &&
            (error.response.status === 401 ||
              error.response.status === 403 ||
              error.response.status === 404)
          ) {
            switch (error.response.status) {
              case 401:
                window.location.href = "/401";
                break;
              case 403:
                window.location.href = "/403";
                break;
              case 404:
                window.location.href = "/404";
                break;
              default:
                break;
            }
          }
        }
      );

      this.setState({
        loading: false,
        addEQDialogVisible: false,
        updateEQDialogVisible: false,
      });
    }
  }

  stepPrev() {
    let inx = this.state.activeIndex;
    if (inx <= 0) {
    } else {
      if (!this.state.saveButtonStatus) {
        inx = inx - 1;
        this.setState({
          activeIndex: inx,
        });

        window.scrollTo(0, 0);
      } else {
        toast.warning(<Trans i18nKey="pleaseSaveFirst" />);
      }
    }
  }

  stepNext() {
    let inx = this.state.activeIndex;
    if (inx >= this.stepItems.length - 1) {
    } else {
      if (!this.state.saveButtonStatus) {
        inx = inx + 1;
        this.setState({
          activeIndex: inx,
        });

        window.scrollTo(0, 0);
      } else {
        toast.warning(<Trans i18nKey="pleaseSaveFirst" />);
      }
    }
  }

  stepSaveAndNext() {
    let inx = this.state.activeIndex;
    if (inx > this.stepItems.length - 1) {
    } else {
      if (isProjectManager()) {
        this.setState({
          loading: true,
        });

        /////////////////////

        this.state.project.equipments = this.state.eqs;
        this.state.project.projectId = this.state.pid;
        this.state.project.phase = "P9" + inx;

        SysService.updateProject(this.state.project).then(
          (response) => {

            SysService.getProject(this.props.location.state.id).then(
              (response2) => {

                let temp91Documents = [];

                if (response2.data.documentList != null) {
                  for (let i = 0; i < response2.data.documentList.length; i++) {
                    if (response2.data.documentList[i].phase === "P91") {
                      temp91Documents.push(response2.data.documentList[i]);
                    }
                  }
                }

                this.setState({
                  project: response2.data,
                  sites: response2.data.siteList,
                  zones: response2.data.zoneList,
                  eqs: response2.data.equipmentList,
                  p91Documents: temp91Documents,
                  loading: false,
                  blockedPanel: false,
                  saveButtonStatus: false,
                });
              }
            );

            toast.success(<Trans i18nKey="saved" />);
          },
          (error) => {
            this.setState({
              loading: false,
              content:
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString(),
            });

            toast.error(<Trans i18nKey={error.response} />);

            if (
              error.response &&
              (error.response.status === 401 ||
                error.response.status === 403 ||
                error.response.status === 404)
            ) {
              switch (error.response.status) {
                case 401:
                  window.location.href = "/401";
                  break;
                case 403:
                  window.location.href = "/403";
                  break;
                case 404:
                  window.location.href = "/404";
                  break;
                default:
                  break;
              }
            }
          }
        );
      }

      if (inx < this.stepItems.length - 1) {
        inx = inx + 1;
        this.setState({
          activeIndex: inx,
        });
      }
    }

    window.scrollTo(0, 0);
  }

  renderHeader(param) {
    return (
      <div className="flex justify-content-between">
        <span>{param}</span>
      </div>
    );
  }

  renderHeaderTableTop(t) {
    return (
      <div className="flex justify-content-between">
        <span>{t("sensorList")}</span>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            className="p-inputtext-sm block mb-2"
            value={this.state.globalFilterValue1}
            onChange={this.onGlobalFilterChange1}
            placeholder={t("search")}
          />
        </span>
      </div>
    );
  }

  onGlobalFilterChange1 = (e) => {
    const value = e.target.value;
    let filters1 = { ...this.state.filters1 };
    filters1["global"].value = value;

    this.setState({ filters1, globalFilterValue1: value });
  };

  initFilters1 = () => {
    this.setState({
      filters1: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      globalFilterValue1: "",
    });
  };

  dropdownEqTemplate = (data) => {
    return (
      <>
        {data.zoneName}: {data.eqDeviceName} - {data.eqBrandNameModel} (
        {data.eqDeviceEUI})
      </>
    );
  };

  dropdownZoneTemplate = (data) => {
    return (
      <>
        {data.name}
      </>
    );
  };

  /**/
  render() {

    const { t } = this.props;

    const headerTable = this.renderHeaderTableTop(t);

    {/*Chart options*/ }
    const options = {
      responsive: true,
      stacked: false,
      interaction: {
        mode: 'index',
        intersect: false,
      },
      plugins: {
        legend: {
          position: "top",
          labels: {
            usePointStyle: true,
            pointStyle: "circle",
            padding: 10,
          },
        },
        zoom: {
          pan: {
            enabled: true,
            mode: "x"
          },
          zoom: {
            wheel: {
              enabled: true
            },
            pinch: {
              enabled: false
            },
            mode: "x"
          },
        },
      },

      scales: {
        x: {
          ticks: {
            font: {
              size: 8,
            },
          },
        },
        y: {
          ticks: {
            font: {
              size: 10,
            },
          },
          type: 'linear',
          display: true,
          position: 'left',
          //beginAtZero: true,
          //min: 0,
          //max: 100
        },
      },
    };

    const options2 = {
      responsive: true,
      stacked: false,
      interaction: {
        mode: 'index',
        intersect: false,
      },
      plugins: {
        legend: {
          position: "top",
          labels: {
            usePointStyle: true,
            pointStyle: "circle",
            padding: 10,
          },
        },
        zoom: {
          pan: {
            enabled: true,
            mode: "x"
          },
          zoom: {
            wheel: {
              enabled: true
            },
            pinch: {
              enabled: false
            },
            mode: "x"
          },
        },
      },
      scales: {
        x: {
          ticks: {
            font: {
              size: 8,
            },
          },
        },
        y: {
          ticks: {
            font: {
              size: 10,
            },
          },
          type: "linear",
          display: true,
          position: "left",
          //beginAtZero: true,
        },
      },
    };

    this.stepItems = [
      {
        label: t("sensorRegistration"),
        command: (event) => { },
      },
      {
        label: t("visualizeMeasuredData"),
        command: (event) => { },
      },
    ];

    const addEQDialogFooter = (
      <React.Fragment>
        <Button
          label={t("cancel")}
          icon="pi pi-times"
          className="p-button-text"
          onClick={this.hideAddEQDialog}
        />
        <Button
          label={t("save")}
          icon="pi pi-save"
          className="p-button-mb"
          onClick={() => this.setState({ addEQConfirmDialogVisible: true })}
        />

        <ConfirmDialog
          visible={this.state.addEQConfirmDialogVisible}
          onHide={() => {
            this.setState({ addEQConfirmDialogVisible: false });
          }}
          message={t("confirmMessage")}
          header={t("confirm")}
          icon="pi pi-exclamation-triangle"
          accept={this.acceptAddEQ}
          reject={this.rejectAddEQ}
          acceptLabel={t("yes")}
          rejectLabel={t("no")}
        />
      </React.Fragment>
    );

    const updateEQDialogFooter = (
      <React.Fragment>
        <Button
          label={t("cancel")}
          icon="pi pi-times"
          className="p-button-text"
          onClick={this.hideUpdateEQDialog}
        />
        <Button
          label={t("update")}
          icon="pi pi-save"
          className="p-button-mb"
          onClick={() => this.setState({ updateEQConfirmDialogVisible: true })}
        />

        <ConfirmDialog
          visible={this.state.updateEQConfirmDialogVisible}
          onHide={() => {
            this.setState({ updateEQConfirmDialogVisible: false });
          }}
          message={t("confirmMessage")}
          header={t("confirm")}
          icon="pi pi-exclamation-triangle"
          accept={this.acceptUpdateEQ}
          reject={this.rejectUpdateEQ}
          acceptLabel={t("yes")}
          rejectLabel={t("no")}
        />
      </React.Fragment>
    );

    return (
      <div>
        {!isUser() && (
          <>
            <BlockUI blocked={this.state.blockedPanel} fullscreen>
                <div className="flex align-items-center justify-content-center">
                  <Link
                    to={{ pathname: "/project", state: this.state.project }}
                    style={{ textDecoration: "none" }}
                  >

                    <Chip style={{ fontSize: "15px" }}
                      label={this.state.project.projectName
                        + " (ID: " + this.state.project.pruid + ")"}
                      className="mb-2 custom-chip-home"
                    />

                  </Link>
                </div>

              <br />
              {this.state.project.areWeProceeding === "2" && (
                <>
                  <Chip
                    label={<b>{t("stopped")}</b>}
                    icon="pi pi-stop-circle"
                    style={{
                      fontSize: "18px",
                      position: "absolute",
                      zIndex: "1",
                      right: "10px",
                    }}
                    className="awpNo mr-2 mb-2 custom-chip-constant"
                  />
                  <br />
                  <br />
                </>
              )}
              {this.state.project.areWeProceeding === "3" && (
                <>
                  <Chip
                    label={<b>{t("pending")}</b>}
                    icon="pi pi-bell"
                    style={{
                      fontSize: "18px",
                      position: "absolute",
                      zIndex: "1",
                      right: "10px",
                    }}
                    className="awpPending mr-2 mb-2 custom-chip-constant"
                  />
                  <br />
                  <br />
                </>
              )}
              <br />

              <div className={"col-12 " + rowClass(this.state.project)}>
                <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                  <div className="flex justify-content-between mb-3">
                    <Button
                      // icon="pi pi-arrows-h"
                      label={t("showPhases")}
                      className="p-button-sm p-button-mb"
                      onClick={() =>
                        this.setState({ visibleCustomSidebar: true })
                      }
                    />
                    <div>
                      <Chip
                        label={<b>{t("sensorData")}</b>}
                        icon="pi pi-globe"
                        className="mr-2 mb-2 custom-chip-constant"
                      />
                    </div>
                  </div>
                  <hr />
                  <div className="col-12 mb-0">
                    <Steps
                      model={this.stepItems}
                      activeIndex={this.state.activeIndex}
                      onSelect={(e) => {
                        if (!this.state.saveButtonStatus) {
                          this.setState({ activeIndex: e.index });
                        } else {
                          if (this.state.activeIndex != e.index) {
                            toast.warning(<Trans i18nKey="pleaseSaveFirst" />);
                          }
                        }
                      }}
                      readOnly={false}
                    />
                  </div>

                  <TabView
                    className="projectNav"
                    activeIndex={this.state.activeIndex}
                    onTabChange={(e) => this.setState({ activeIndex: e.index })}
                  >
                    {/*Phase9_Equipment_Registration*/}
                    <TabPanel header={t("sensorRegistration")}>
                      <div
                        className="mb-4"
                        style={{
                          backgroundColor: "#FFFADD",
                          padding: "10px",
                          fontSize: "14px",
                        }}
                      >
                        <div>{t("p91Text")}</div>
                        <div>{t("p91_2Text")}</div>
                      </div>

                      <div className="grid">
                        {/*add Sensor Button*/}

                        <div className="field col-12 mb-0">
                          {isProjectManager() && (
                            <Button
                              label={t("btnAddSensor")}
                              className="p-button p-component p-button-outlined p-button-sm"
                              onClick={this.handleAddEQ}
                            />
                          )}
                        </div>

                        {/*DOCUMENTS TABLE*/}
                        <div className="col-12">
                          <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                            <DataTable
                              name="dt"
                              size="small"
                              showGridlines
                              value={this.state.eqs}
                              dataKey="id"
                              stripedRows
                              responsiveLayout="scroll"
                              emptyMessage={t("noSensorFound")}
                              header={headerTable}
                              paginator
                              rows={10}
                              filters={this.state.filters1}
                              filterDisplay="menu"
                              loading={this.state.loading}
                              globalFilterFields={[
                                "siteName",
                                "zoneName",
                                "eqDeviceName",
                                "eqDeviceType",
                                "eqDeviceEUI",
                                "eqBrandNameModel",
                              ]}
                            >
                              <Column
                                field="eqDeviceName"
                                header={t("deviceName")}
                                sortable
                              ></Column>
                              <Column
                                field="eqDeviceType"
                                header={t("deviceType")}
                                sortable
                              ></Column>
                              <Column
                                field="siteName"
                                header={t("siteName")}
                                sortable
                              ></Column>
                              <Column
                                field="zoneName"
                                header={t("zoneName")}
                                sortable
                              ></Column>
                              <Column
                                field="eqDeviceEUI"
                                header={t("deviceEUI")}
                                sortable
                              ></Column>
                              <Column
                                field="eqBrandNameModel"
                                header={t("brandNameModel")}
                                sortable
                              ></Column>
                              <Column
                                header={t("action")}
                                style={{ textAlign: "center", width: "12em" }}
                                body={this.actionTemplate}
                              />
                            </DataTable>
                          </div>
                        </div>
                      </div>
                    </TabPanel>

                    {/*Phase9_Visualize_Measured_Data_(IoT Devices)*/}
                    <TabPanel header={t("visualizeMeasuredData")}>
                      <div
                        className="mb-4"
                        style={{
                          backgroundColor: "#FFFADD",
                          padding: "10px",
                          fontSize: "14px",
                        }}
                      >
                        {t("p92Text")}
                      </div>

                      <div className="grid">
                        {/*selectEquipment*/}
                        <div className="col-12 md:col-6 lg:col-6">
                          <div className="mb-1">
                            <label
                              htmlFor="selectSensor"
                              className="platformColor"
                            >
                              {t("selectSensor")} (*)
                            </label>
                          </div>
                          <div className="grid">
                            <div className="col-12 md:col-11 lg:col-11">
                              <Dropdown
                                id="selectSensor"
                                value={this.state.selectedEquipment}
                                options={this.state.eqs}
                                onChange={(e) =>
                                  this.showEqData(e, "selectedDevice")
                                }
                                optionLabel={this.dropdownEqTemplate}
                                className="w-full"
                              />
                            </div>
                            <div className="col-12 md:col-1 lg:col-1">
                              <Button
                                disabled={this.state.selectedEquipment == null}
                                icon="pi pi-chart-bar"
                                href
                                tooltip="Show"
                                className="p-button-rounded p-button-text"
                                onClick={(e) => {
                                  this.setState({ eqDataVisible: true });
                                }}
                              />
                            </div>
                          </div>
                        </div>

                      </div>
                    </TabPanel>
                  </TabView>
                </div>
                <br />
                <Button
                  icon="pi pi-chevron-circle-left"
                  disabled={this.state.activeIndex <= 0}
                  label={t("previous")}
                  className="p-button-sm p-button-mb"
                  onClick={() => this.stepPrev()}
                />
                &nbsp;
                <Button
                  icon="pi pi-chevron-circle-right"
                  disabled={this.state.activeIndex >= this.stepItems.length - 1}
                  label={t("next")}
                  className="p-button-sm p-button-mb"
                  onClick={() => this.stepNext()}
                />
                &nbsp;
                {isProjectManager() && (
                  <Button
                    disabled={!this.state.saveButtonStatus}
                    icon="pi pi-check-circle"
                    label={
                      this.state.activeIndex < this.stepItems.length - 1
                        ? t("saveNext")
                        : t("save")
                    }
                    className="p-button-sm p-button-danger"
                    onClick={() => this.stepSaveAndNext()}
                  />
                )}
              </div>
              <br />

              {/*add Sensor Dialog */}
              <Dialog
                visible={this.state.addEQDialogVisible}
                style={{ width: "450px" }}
                header={t("addSensor")}
                className="p-fluid"
                footer={addEQDialogFooter}
                onHide={this.hideAddEQDialog}
              >
                <div className="p-float-label">
                  <Dropdown
                    id="eqBuildingName"
                    disabled={this.state.nonEditAccess}
                    value={this.state.newEQ.siteId}
                    options={this.state.sites}
                    onChange={(e) => this.onInputChangeEQ(e, "siteId")}
                    optionLabel={this.dropdownZoneTemplate}
                    optionValue="id"
                    className="w-full"
                  />
                  <label htmlFor="eqBuildingName">{t("siteName")} (*)</label>
                </div>

                <div className="p-float-label">
                  <Dropdown
                    id="eqBuildingZoneName"
                    disabled={this.state.nonEditAccess}
                    value={this.state.newEQ.zoneId}
                    options={this.state.zones}
                    onChange={(e) => this.onInputChangeEQ(e, "zoneId")}
                    optionLabel={this.dropdownZoneTemplate}
                    optionValue="id"
                    className="w-full"
                  />
                  <label htmlFor="eqBuildingZoneName">
                    {t("zoneName")} (*)
                  </label>
                </div>

                <div className="p-float-label">
                  <InputText
                    id="eqDeviceName"
                    value={this.state.newEQ.eqDeviceName}
                    onChange={(e) => this.onInputChangeEQ(e, "eqDeviceName")}
                    maxLength={50}
                    className="w-full"
                  />
                  <label htmlFor="eqDeviceName">{t("deviceName")} (*)</label>
                </div>

                <div className="p-float-label">
                  <InputText
                    id="eqDeviceType"
                    value={this.state.newEQ.eqDeviceType}
                    onChange={(e) => this.onInputChangeEQ(e, "eqDeviceType")}
                    maxLength={50}
                    className="w-full"
                  />
                  <label htmlFor="eqDeviceType">{t("deviceType")} (*)</label>
                </div>

                <div className="p-float-label">
                  <InputText
                    id="eqBrandNameModel"
                    value={this.state.newEQ.eqBrandNameModel}
                    onChange={(e) =>
                      this.onInputChangeEQ(e, "eqBrandNameModel")
                    }
                    maxLength={100}
                    className="w-full"
                  />
                  <label htmlFor="eqBrandNameModel">
                    {t("brandNameModel")} (*)
                  </label>
                </div>

                <div className="p-float-label">
                  <InputText
                    id="eqDeviceEUI"
                    value={this.state.newEQ.eqDeviceEUI}
                    onChange={(e) => this.onInputChangeEQ(e, "eqDeviceEUI")}
                    maxLength={50}
                    className="w-full"
                  />
                  <label htmlFor="eqDeviceEUI">{t("deviceEUI")} (*)</label>
                </div>

                <div className="p-float-label">
                  <div className="mb-2">
                    <font color="black">{t("datasheet")}: </font>
                  </div>
                  <input id="fupload" type="file" accept=".pdf"></input>
                </div>
              </Dialog>

              {/*Equipments Update Dialog */}
              <Dialog
                visible={this.state.updateEQDialogVisible}
                style={{ width: "450px" }}
                header={t("updateSensor")}
                className="p-fluid"
                footer={updateEQDialogFooter}
                onHide={this.hideUpdateEQDialog}
              >
                <div className="p-float-label">
                  <Dropdown
                    id="eqBuildingName"
                    disabled={this.state.nonEditAccess}
                    value={this.state.newEQ.siteId}
                    options={this.state.sites}
                    onChange={(e) => this.onInputChangeEQ(e, "siteId")}
                    optionLabel={this.dropdownZoneTemplate}
                    optionValue="id"
                    className="w-full"
                  />
                  <label htmlFor="eqBuildingName">{t("siteName")} (*)</label>
                </div>

                <div className="p-float-label">
                  <Dropdown
                    id="eqBuildingZoneName"
                    disabled={this.state.nonEditAccess}
                    value={this.state.newEQ.zoneId}
                    options={this.state.zones}
                    onChange={(e) => this.onInputChangeEQ(e, "zoneId")}
                    optionLabel={this.dropdownZoneTemplate}
                    optionValue="id"
                    className="w-full"
                  />
                  <label htmlFor="eqBuildingZoneName">
                    {t("zoneName")} (*)
                  </label>
                </div>

                <div className="p-float-label">
                  <InputText
                    id="eqDeviceName"
                    value={this.state.newEQ.eqDeviceName}
                    onChange={(e) => this.onInputChangeEQ(e, "eqDeviceName")}
                    maxLength={50}
                    className="w-full"
                  />
                  <label htmlFor="eqDeviceName">{t("deviceName")} (*)</label>
                </div>

                <div className="p-float-label">
                  <InputText
                    id="eqDeviceType"
                    value={this.state.newEQ.eqDeviceType}
                    onChange={(e) => this.onInputChangeEQ(e, "eqDeviceType")}
                    maxLength={50}
                    className="w-full"
                  />
                  <label htmlFor="eqDeviceType">{t("deviceType")} (*)</label>
                </div>

                <div className="p-float-label">
                  <InputText
                    id="eqBrandNameModel"
                    value={this.state.newEQ.eqBrandNameModel}
                    onChange={(e) =>
                      this.onInputChangeEQ(e, "eqBrandNameModel")
                    }
                    maxLength={100}
                    className="w-full"
                  />
                  <label htmlFor="eqBrandNameModel">
                    {t("brandNameModel")} (*)
                  </label>
                </div>

                <div className="p-float-label">
                  <InputText
                    id="eqDeviceEUI"
                    value={this.state.newEQ.eqDeviceEUI}
                    onChange={(e) => this.onInputChangeEQ(e, "eqDeviceEUI")}
                    maxLength={50}
                    className="w-full"
                  />
                  <label htmlFor="eqDeviceEUI">{t("deviceEUI")} (*)</label>
                </div>

                <div className="p-float-label">
                  <div className="mb-2">
                    <font color="black">{t("datasheet")} (*): </font>
                    {this.state.newEQ.eqDatasheetURL !== "" && (
                      <a href={this.state.newEQ.eqDatasheetURL} target="_blank">
                        {t("datasheet")}
                      </a>
                    )}
                  </div>
                  <input id="fupload" type="file" accept=".pdf"></input>
                </div>
              </Dialog>

              {/*Sensor Charts Dialog */}
              <Dialog
                visible={this.state.eqDataVisible}
                style={{ width: "1000px" }}
                modal
                header={
                  this.state?.selectedEquipment?.eqDeviceName +
                  " - " +
                  this.state?.selectedEquipment?.eqBrandNameModel
                }
                className="p-fluid"
                onHide={this.hideShowEQDialog}
              >
                {this.state.selectedEquipment !== null && (
                  <>
                    {this.state.zoneList &&
                      this.state.zoneList.map((z) => (
                        <>
                          <div className="col-12">
                            <div className="grid">
                              <div className="col-12 md:col-12 lg:col-12">
                                <hr />
                                <div style={{ fontSize: "18px", color: "#6e7073" }}><strong>{z.zoneName}</strong></div>
                              </div>

                              {z.eqArr &&
                                z.eqArr.map((eq) => (
                                  <>
                                    <div className="centered col-12 md:col-9 lg:col-9">
                                      <Graphic
                                        eq={eq}
                                        options={options}
                                        options2={options2}
                                      />
                                    </div>
                                  </>
                                ))}
                               
                                
                            </div>
                          </div>
                        </>
                      ))}
                     
                  </>
                )}
              </Dialog>

              <SidebarComponent
                visible={this.state.visibleCustomSidebar}
                project={this.state.project}
                setState={this.setState.bind(this)}
                lang={t}
              />

              <WaitDialog loading={this.state.loading} lang={t} />
              <WaitDialogDocument
                loading={this.state.documentLoading}
                lang={t}
              />

              <ToastContainer
                autoClose={2200}
                position={toast.POSITION.TOP_CENTER}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick={true}
                rtl={false}
                theme="colored"
                pauseOnFocusLoss={true}
                draggable={false}
                pauseOnHover={true}
              />
            </BlockUI>
          </>
        )}
      </div>
    );
  }
}


function mapStateToProps(state) {
  const { message } = state.message;
  return {
    message,
  };
}

export default withTranslation()(connect(mapStateToProps)(SensorData));
