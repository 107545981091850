import "bootstrap/dist/css/bootstrap.min.css";
import "./resources/main.css";

import React, { Component } from "react";
import { BrowserRouter, Router, Switch } from "react-router-dom";

import AuthVerify from "./common/auth-verify";
import EventBus from "./common/EventBus";
import PrivateRoute from "./common/PrivateRoute";
import PublicRoute from "./common/PublicRoute";
import WaitDialog from "./common/WaitDialog";
import StompTest from "./components/stompTest";

import Login from "./components/login";
import ForgotPassword from "./components/forgotPassword";
import ChangePassword from "./components/changePassword";
import SetNewPassword from "./components/setNewPassword";
import VerifyEmail from "./components/verifyEmail";
import ResendMail from "./components/resendMail";
import Index from "./components/index";
import PageNotFound from "./components/404";
import UnauthorizedPage from "./components/401";
import ForbiddenPage from "./components/403";
import Home from "./components/home";
import Profile from "./components/profile";
import PlatformUsers from "./components/platformUsers";
import Users from "./components/users";
import Projects from "./components/projects";
import Project from "./components/project";
import ProjectDashboard from "./components/projectDashboard";
import PI from "./components/pi";
import SensorData from "./components/sensorData";
import AssetManagement from "./components/assetManagement";

import APIKeys from "./components/apiKeys";
import Configuration from "./components/configuration";



import { Menubar } from "primereact/menubar";
import { clearMessage } from "./actions/message";
import { connect } from "react-redux";

import { history } from "./common/history";
import { isAdmin, isPlatformManager, isProjectManager } from "./common/token-check";
import jwt_decode from "jwt-decode";
import { logout } from "./actions/auth";
import { isLogin } from "./common/token-check";

import i18n from "./i18n";
import { withTranslation } from "react-i18next";

class App extends Component {

  constructor(props) {

    super(props);

    this.logOut = this.logOut.bind(this);

    this.state = {
    };

    this.menuItems1 = [];

    history.listen((location) => {
      props.dispatch(clearMessage());
    });
  }

  componentDidMount() {

    const user = this.props.user;

    EventBus.on("logout", () => {
      this.logOut();
    });
  }

  componentWillUnmount() {
    EventBus.remove("logout");
  }

  logOut() {
    this.props.dispatch(logout());
  }

  render() {

    const { t } = this.props;
    const user = this.props.user;

    if (user) {
      const decodedJwt = jwt_decode(user.token);
      user.fullname = decodedJwt.firstName + " " + decodedJwt.lastName;
      user.laName = decodedJwt.laName;
    }    

    this.menuItems1 = [
      {
        label: t("home"),
        visible: (isAdmin()|| isPlatformManager()||isProjectManager()),
        icon: "pi pi-home",
        command: (e) => {
          this.setState({ loading: true });
        },
        url: "/home",
      },
      {
        label: t("platformManagers"),
        visible: (isAdmin()),
        icon: "pi pi-users",
        command: (e) => {
          this.setState({ loading: true });
        },
        url: "/platformUsers",
      },
      {
        label: t("users"),
        visible: isPlatformManager(),
        icon: "pi pi-users",
        command: (e) => {
          this.setState({ loading: true });
        },
        url: "/users",
      },
      {
        label: t("projects"),
        visible: (isPlatformManager() || isProjectManager()),
        icon: "pi pi-folder",
        command: (e) => {
          this.setState({ loading: true });
        },
        url: "/projects",
      },
      {
        visible: (isPlatformManager()),
        label: t("management"),
        icon: "pi pi-key",
        className: "zindex102",
        items: [
          {
            label: t("apiKeys"),
            command: (e) => {
              this.setState({ loading: true });
              window.location.href = "/apiKeys";
            },
          },

          {
            label: t("configuration"),
            command: (e) => {
              this.setState({ loading: true });
              window.location.href = "/configuration";
            },
          }

        ],
      },



      {
        visible: true,
        label: user != null ? user.fullname : "",
        //label: " ",
        icon: "pi pi-user",
        className: "zindex102",
        items: [
          {
            label: t("profile"),
            icon: "pi pi-fw pi-file",
            command: (e) => {
              this.setState({ loading: true });
              window.location.href = "/profile";
            },
          },
          {
            label: t("changePassword"),
            icon: "pi pi-fw pi-key",
            command: (e) => {
              this.setState({ loading: true });
              window.location.href = "/changePassword";
            },
          },
          {
            label: t("logout"),
            icon: "pi pi-fw pi-power-off",
            command: (e) => {
              this.setState({ loading: true });
              this.logOut();
              window.location.href = "/";
            },
          },
        ],
      },
      {
        visible: true,
        label: t("language"),
        icon: "pi pi-language",
        className: "zindex102",
        items: [
          {
            label: "English",
            command: () => {
              i18n.changeLanguage("en");
            },
          },
          
          {
            label: "Türkçe",
            command: () => {
              i18n.changeLanguage("tr");
            },
          }
          
        ],
      }      
    ];

    const start = (
      <>
        <button
          style={{
            backgroundColor: "transparent",
            border: "none",
          }}
          onClick={() =>
            isLogin()
              ? (window.location.href = "/home")
              : (window.location.href = "/")
          }
        >
          <img src="/assets/img/logo.png" alt="Heatics" style={{ marginLeft: "42px", height:"80px" }} />
        </button>
      </>
    );

    const menuItems3 = [
      {
        visible: true,
        label: t("login"),
        icon: "pi pi-user",
        className: "zindex102",
        command: (e) => {
          this.setState({ loading: true });
        },
        url: "/login",
      },
      {
        visible: true,
        label: t("language"),
        icon: "pi pi-language",
        className: "zindex102",
        items: [
          {
            label: "English",
            command: () => {
              i18n.changeLanguage("en");
            },
          },
          
          {
            label: "Türkçe",
            command: () => {
              i18n.changeLanguage("tr");
            },
          }
          
        ],
      }
    ];

    return (
      <Router history={history}>
        <div>
          {isLogin() ? (
            <>
              <div>
                <Menubar
                  className="w-full mb-0 p-menubar-mb"
                  model={this.menuItems1.filter((item) => item.visible)}
                  start={start}

                />
              </div>
            </>
          ) : (
            <>
              <div>
                <Menubar
                  className="w-full mb-0 p-menubar-mb"
                  model={menuItems3}
                  start={start}

                />
              </div>
            </>
          )}

          <div className="grid">

            <div className="col-12 md:col-11 lg:col-11 centered">
              <br />
              <BrowserRouter>
                <Switch>
                <PrivateRoute component={StompTest} path="/stompTest" exact />
                  <PrivateRoute component={Profile} path="/profile" exact />
                  <PrivateRoute component={ChangePassword} path="/changePassword" exact />
                  <PrivateRoute component={Home} path="/home" exact />
                  <PrivateRoute component={PlatformUsers} path="/platformUsers" exact />
                  <PrivateRoute component={Users} path="/users" exact />
                  <PrivateRoute component={Projects} path="/projects" exact />
                  <PrivateRoute component={Project} path="/project" exact />
                  <PrivateRoute component={ProjectDashboard} path="/projectDashboard" exact />
                  <PrivateRoute component={PI} path="/pi" exact />
                  <PrivateRoute component={SensorData} path="/sensorData" exact />
                  <PrivateRoute component={AssetManagement} path="/assetManagement" exact />
                  <PrivateRoute component={APIKeys} path="/apiKeys" exact />
                  <PrivateRoute component={Configuration} path="/configuration" exact />


                  <PrivateRoute
                    component={UnauthorizedPage}
                    path="/401"
                    exact
                  />
                  <PrivateRoute component={ForbiddenPage} path="/403" exact />
                  <PrivateRoute component={PageNotFound} path="/404" exact />
                  <PublicRoute
                    restricted={true}
                    component={Login}
                    path="/login"
                    exact
                  />
                  <PublicRoute
                    restricted={true}
                    component={ForgotPassword}
                    path="/forgotPassword"
                    exact
                  />
                  <PublicRoute
                    restricted={true}
                    component={ResendMail}
                    path="/resendMail"
                    exact
                  />
                  <PublicRoute
                    restricted={true}
                    component={SetNewPassword}
                    path="/setNewPassword"
                    exact
                  />
                  <PublicRoute
                    restricted={false}
                    component={VerifyEmail}
                    path="/verifyEmail"
                    exact
                  />
                  <PublicRoute
                    restricted={false}
                    component={Index}
                    path="/"
                    exact
                  />
                </Switch>
              </BrowserRouter>

              <div className="text-center">
                <br />
                <br />
                <span className="text-700 font-medium line-height-3">
                  {t("footerCopyright")}{" "}
                  <img src="/assets/img/ecowise.png" alt="Ecowise" height={30} className="mb-0" />
                  <br />

                </span>
                <br />
                <br />
              </div>

            </div>

            <WaitDialog loading={this.state.loading} lang={t} />

          </div>

          {<AuthVerify logOut={this.logOut} />}
        </div>
      </Router >
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  return {
    user,
  };
}

export default withTranslation()(connect(mapStateToProps)(App));
